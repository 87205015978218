import { GeneratedImage, GeneratedImageService, PublishedImageStore } from "@metranpage/image-generation";
import { PublishedObjectsDataSource } from "./data-source";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filterUndefined } from "@metranpage/core";
import { User } from "@metranpage/user-data";

@Injectable({
  providedIn: "root",
})
export class ImagesDataSource extends PublishedObjectsDataSource<GeneratedImage> {
  private items$: Observable<GeneratedImage[]> | undefined;

  constructor(
    private readonly generatedImageService: GeneratedImageService,
    private readonly publishedImageStore: PublishedImageStore,
  ) {
    super("images");
  }

  initItems() {
    if (!this.items$) {
      this.items$ = this.publishedImageStore.getPublishedImagesObservable().pipe(filterUndefined());

      this.publishedImageStore.getPublishedImagesPageCountObservable().subscribe((pageCount) => {
        this.pageCount = pageCount;
      });

      this.page = 1;

      this.generatedImageService.loadPublishedImagesPaginated(this.page);
    }
  }

  override loadNextPage() {
    console.log(this.page)
    this.page++;
    if (this.page >= this.pageCount) {
      this.page = this.pageCount;
      return;
    }
    this.generatedImageService.loadPublishedImagesPaginated(this.page);
  }

  override getItems(): Observable<GeneratedImage[]> {
    this.initItems();
    return this.publishedImageStore.getPublishedImagesObservable();
  }

  override getObject(id: number): GeneratedImage | undefined {
    return this.publishedImageStore.getPublishedImages().find((ig) => ig.id === id);
  }

  override getImageUrlForObject(id: number, isThumbnail: boolean): string | undefined {
    const generatedImage = this.publishedImageStore.getPublishedImages().find((ig) => ig.id === id);
    if (!generatedImage) {
      return "";
    }
    return this.generatedImageService.getUrlForImage(generatedImage.imageGenerationId, generatedImage.imageUrl, isThumbnail);
  }

  override getAuthorForObject(id: number): User | undefined {
    const generatedImage = this.publishedImageStore.getPublishedImages().find((ig) => ig.id === id);
    if (!generatedImage) {
      return undefined;
    }
    return generatedImage.author as User;
  }
}
