import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { BankCardData } from "./models/bank-card";
import { Order } from "./models/order";
import { PaymentResult, PaymentUrl, PromocodeDiscountData, PromocodeEnterResult } from "./models/pricing";
import { ActiveSubscription } from "./models/subscription";
import { Tariff } from "./models/tariff";

@Injectable({
  providedIn: "any",
})
export class PricingApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  // async buyCredits(creditsCount: number) {
  //   return this.post<PaymentUrl>(`payments/rk-url`, { creditsCount });
  // }

  async getPaymentUrl(creditsCount: number, tariffId: number) {
    return this.post<PaymentUrl>("payments/alfa-url", { creditsCount, tariffId });
  }

  async getTariffsForCompany() {
    return this.get<Tariff[]>("tariffs/company");
  }

  async getHigherTariff() {
    return this.get<Tariff>("tariffs/higher-tariff");
  }

  async getActiveSubscription() {
    return this.get<ActiveSubscription>("subscription");
  }

  async buyCredits(creditsCount: number) {
    return this.post<PaymentResult>("payments/credits", { creditsCount });
  }

  async buySubscription(tariffId: number) {
    return this.post<PaymentResult>("payments/subscription", { tariffId });
  }

  async addBankCard() {
    return this.post<PaymentResult>("payments/add-card", {});
  }

  async getBankCards() {
    return this.post<BankCardData[]>("card/cards", {});
  }

  async deleteBankCard(cardId: number) {
    return this.post<BankCardData[]>("card/delete-card", { cardId });
  }

  async getOrders() {
    return this.get<Order[]>("orders");
  }

  async activatePromocode(promocode: string) {
    return this.post<PromocodeEnterResult>("promocode/activate", { promocode });
  }

  async loadPromocodeDiscountData() {
    return this.get<PromocodeDiscountData | undefined>("promocode/discount");
  }
}
