import { Injectable } from "@angular/core";
import { Observable, from, map, tap } from "rxjs";
import { FontsService } from "../fonts.service";

@Injectable({
  providedIn: "root",
})
export class CoverFontsService {
  private _assetsFontFaces: FontFace[] = [];

  constructor(private readonly fontsService: FontsService) {
    this.populateAssetsFontFaces();
  }

  getCompleteSet(): Observable<FontFace[]> {
    return from(this.fontsService.getCompanyFonts()).pipe(
      map((f) => f.filter((v) => v.isSelectable)),
      map((fonts) => fonts.map((f) => this.fontsService.toFontFace(f)).concat(...this._assetsFontFaces)),
    );
  }

  getSubset(families: string[]): Observable<FontFace[]> {
    return this.getCompleteSet().pipe(map((v) => v.filter((i) => families.some((j) => j === i.family))));
  }

  loadFontFaces(fontFaces: FontFace[]): Observable<FontFace[]> {
    return from(Promise.allSettled(fontFaces.map((f) => this.loadFont(f)))).pipe(
      map((v) => v.filter((i) => i.status === "fulfilled" && i.value).map((j: any) => j.value)),
    );
  }

  async loadFont(font: FontFace): Promise<FontFace> {
    const fontFace = await font.load();
    (document.fonts as any).add(fontFace);
    return fontFace
  }

  private populateAssetsFontFaces() {
    // Arturito Slab [no bold, no italic]
    this._assetsFontFaces.push(
      new FontFace("Arturito Slab", "url('/assets/fonts/Arturito Rostype/Arturito Slab_v2.woff2')", {
        weight: "normal",
        style: "normal",
      }),
    );

    // Finladica
    /* this._assetsFontFaces.push(
      new FontFace("Finlandica", "url('/assets/fonts/Finlandica/Finlandica-Regular.woff2')", {
        weight: "normal",
        style: "normal",
      }),
      new FontFace("Finlandica", "url('/assets/fonts/Finlandica/Finlandica-Bold.woff2')", {
        weight: "bold",
        style: "normal",
      }),
      new FontFace("Finlandica", "url('/assets/fonts/Finlandica/Finlandica-Italic.woff2')", {
        weight: "normal",
        style: "italic",
      }),
      new FontFace("Finlandica", "url('/assets/fonts/Finlandica/Finlandica-BoldItalic.woff2')", {
        weight: "bold",
        style: "italic",
      }),
    ); */

    // PT Serif
    /* this._assetsFontFaces.push(
      new FontFace("PT Serif", "url('/assets/fonts/PT Serif/PT-Regular.woff2')", {
        weight: "normal",
        style: "normal",
      }),
      new FontFace("PT Serif", "url('/assets/fonts/PT Serif/PT-Bold.woff2')", {
        weight: "bold",
        style: "normal",
      }),
      new FontFace("PT Serif", "url('/assets/fonts/PT Serif/PT-Italic.woff2')", {
        weight: "normal",
        style: "italic",
      }),
      new FontFace("PT Serif", "url('/assets/fonts/PT Serif/PT-BoldItalic.woff2')", {
        weight: "bold",
        style: "italic",
      }),
    ); */

    // Comic CAT [no bold, no italic]
    /* this._assetsFontFaces.push(
      new FontFace("Comic CAT", "url('/assets/fonts/Comic Cat/Comic_CAT.woff2')", {
        weight: "normal",
        style: "normal",
      }),
    ); */

    // Lack [no bold]
    /* this._assetsFontFaces.push(
      new FontFace("Lack", "url('/assets/fonts/Lack/Lack-Regular.woff2')", {
        weight: "normal",
        style: "normal",
      }),
      new FontFace("Lack", "url('/assets/fonts/Lack/Lack-Italic.woff2')", {
        weight: "normal",
        style: "italic",
      }),
    ); */

    // Onest [no italic]
    /* this._assetsFontFaces.push(
      new FontFace("Onest", "url('/assets/fonts/Onest/TTF/Onest-Bold.woff2')", {
        weight: "normal",
        style: "normal",
      }),
      new FontFace("Onest", "url('/assets/fonts/Onest/TTF/Onest-Black.woff2')", {
        weight: "bold",
        style: "normal",
      }),
    ); */

    // Roboto
    this._assetsFontFaces.push(
      new FontFace("Roboto", "url('/assets/fonts/Roboto/Roboto-Regular.woff2')", {
        weight: "normal",
        style: "normal",
      }),
      new FontFace("Roboto", "url('/assets/fonts/Roboto/Roboto-Bold.woff2')", {
        weight: "bold",
        style: "normal",
      }),
      new FontFace("Roboto", "url('/assets/fonts/Roboto/Roboto-Italic.woff2')", {
        weight: "normal",
        style: "italic",
      }),
      new FontFace("Roboto", "url('/assets/fonts/Roboto/Roboto-BoldItalic.woff2')", {
        weight: "bold",
        style: "italic",
      }),
    );

    // Lack Line [no bold]
    /* this._assetsFontFaces.push(
      new FontFace("Lack Line", "url('/assets/fonts/Lack Line/Lack-Line-Regular.woff2')", {
        weight: "normal",
        style: "normal",
      }),
      new FontFace("Lack Line", "url('/assets/fonts/Lack Line/Lack-Line-Italic.woff2')", {
        weight: "normal",
        style: "italic",
      }),
    ); */

    // Montserrat
    this._assetsFontFaces.push(
      /* new FontFace("Montserrat", "url('/assets/fonts/Montserrat/Montserrat-Regular.woff2')", {
        weight: "normal",
        style: "normal",
      }),
      new FontFace("Montserrat", "url('/assets/fonts/Montserrat/Montserrat-Bold.woff2')", {
        weight: "bold",
        style: "normal",
      }),
      new FontFace("Montserrat", "url('/assets/fonts/Montserrat/Montserrat-Italic.woff2')", {
        weight: "normal",
        style: "italic",
      }), */
      new FontFace("Montserrat", "url('/assets/fonts/Montserrat/Montserrat-BoldItalic.woff2')", {
        weight: "bold",
        style: "italic",
      }),
    );

    // Piazzolla SC
    /* this._assetsFontFaces.push(
      new FontFace("Piazzolla SC", "url('/assets/fonts/PiazzollaSC/PiazzollaSC-Regular.woff2')", {
        weight: "normal",
        style: "normal",
      }),
      new FontFace("Piazzolla SC", "url('/assets/fonts/PiazzollaSC/PiazzollaSC-Bold.woff2')", {
        weight: "bold",
        style: "normal",
      }),
      new FontFace("Piazzolla SC", "url('/assets/fonts/PiazzollaSC/PiazzollaSC-Italic.woff2')", {
        weight: "normal",
        style: "italic",
      }),
      new FontFace("Piazzolla SC", "url('/assets/fonts/PiazzollaSC/PiazzollaSC-BoldItalic.woff2')", {
        weight: "bold",
        style: "italic",
      }),
    ); */

    // Source Serif
    /* this._assetsFontFaces.push(
      new FontFace("Source Serif", "url('/assets/fonts/Source Serif/SourceSerif4Subhead-Regular.woff2')", {
        weight: "normal",
        style: "normal",
      }),
      new FontFace("Source Serif", "url('/assets/fonts/Source Serif/SourceSerif4Subhead-Bold.woff2')", {
        weight: "bold",
        style: "normal",
      }),
      new FontFace("Source Serif", "url('/assets/fonts/Source Serif/SourceSerif4Subhead-It.woff2')", {
        weight: "normal",
        style: "italic",
      }),
      new FontFace("Source Serif", "url('/assets/fonts/Source Serif/SourceSerif4Subhead-BoldIt.woff2')", {
        weight: "bold",
        style: "italic",
      }),
    ); */

    // Vollkorn
    /* this._assetsFontFaces.push(
      new FontFace("Vollkorn", "url('/assets/fonts/Vollkorn/Vollkorn-Regular.woff2')", {
        weight: "normal",
        style: "normal",
      }),
      new FontFace("Vollkorn", "url('/assets/fonts/Vollkorn/Vollkorn-Bold.woff2')", {
        weight: "bold",
        style: "normal",
      }),
      new FontFace("Vollkorn", "url('/assets/fonts/Vollkorn/Vollkorn-Italic.woff2')", {
        weight: "normal",
        style: "italic",
      }),
      new FontFace("Vollkorn", "url('/assets/fonts/Vollkorn/Vollkorn-BoldItalic.woff2')", {
        weight: "bold",
        style: "italic",
      }),
    ); */
  }
}
