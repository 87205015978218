<ng-container *ngFor="let b of breadcrumbs | async; let i = index; let isLast = last; let isFirst = first">
  <span *ngIf="!isFirst && b.isVisible" class="bc-arrow-container">
    <svg-icon
      class="bc-arrow"
      src="/assets/icons/bc-arrow.svg"
      [class.arrow-not-active]="!b.isEnabled"
      [class.arrow-not-visible]="!b.isVisible"
    ></svg-icon>
  </span>

  <span *ngIf="!b.isEnabled && b.isVisible" class="breadcrumb not-active">{{ b.title }}</span>
  <a
    *ngIf="b.isEnabled && b.isVisible"
    [ngClass]="getCssClassList(b.url, b.urls)"
    [routerLink]="b.url"
    routerLinkActive="active"
  >
    {{ b.title }}
  </a>
</ng-container>
