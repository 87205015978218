import { Inject, Injectable } from "@angular/core";
import { I18nService } from "@metranpage/i18n";
import { ApiErrorHandlerService, RealtimeService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { ConfigService } from "@metranpage/core-interfaces";
import { PricingService } from "@metranpage/pricing";
import { ActiveSubscription } from "@metranpage/pricing-data";
import {
  UpdateUserActionDto,
  UpdateUserCardDto,
  UpdateUserDto,
  UpdateUserEmailDto,
  User,
  UserApiService,
  UserBalance,
  UserStorage,
  UserStore,
  UserUpdate,
} from "@metranpage/user-data";
import { UserRefresher } from "@metranpage/user-interfaces";
import { DateTime } from "luxon";

export type VerificationEmailResult = "success" | "network-error";
export type UpdateUserResult = "success" | "network-error";

export type TrialData = {
  diffInDays: number;
  trialActivatedDate: string;
  expireDate: DateTime;
  isTrialActive: boolean;
  trialPeriodInDay: number;
};

@Injectable({
  providedIn: "root",
})
export class UserService implements UserRefresher {
  constructor(
    private readonly api: UserApiService,
    private readonly store: UserStore,
    private readonly storage: UserStorage,
    private readonly apiErrorHandler: ApiErrorHandlerService,
    private readonly i18nService: I18nService,
    private readonly notificationService: NotificationsPopUpService,
    @Inject("ConfigService")
    private readonly configService: ConfigService,
    realtimeService: RealtimeService,
    private readonly pricingService: PricingService,
  ) {
    if (storage.getUser()) {
      store.setUser(storage.getUser());
      this.refreshUser();
      realtimeService.login();

      this.showSetRoleNotification();
    }

    realtimeService.getEvents<UserBalance>("user-balance").subscribe((balance: UserBalance | undefined) => {
      store.setBalance(balance);
    });

    realtimeService
      .getEvents<ActiveSubscription>("active-subscription")
      .subscribe((activeSubscription: ActiveSubscription | undefined) => {
        store.setActiveSubscription(activeSubscription);
      });

    realtimeService.getEvents<UserUpdate>("user-update").subscribe({
      next: (user) => {
        this.updateUserLocal(user);
      },
    });
  }

  async sendVerificationEmail(): Promise<VerificationEmailResult> {
    try {
      const userLocation = this.configService.getConfig().company.deployedUrl;
      await this.api.sendVerificationEmail(userLocation);
      return "success";
    } catch (errorResponse: any) {
      this.apiErrorHandler.handleApiError(errorResponse, true);
      return "network-error";
    }
  }

  async verifyEmail(token: string): Promise<VerificationEmailResult> {
    try {
      await this.api.verifyEmail(token);
      return "success";
    } catch (errorResponse: any) {
      this.apiErrorHandler.handleApiError(errorResponse, true);
      return "network-error";
    }
  }

  async refreshUser() {
    try {
      const user = await this.api.getUser();
      this.storage.saveUser(user);
      this.store.setUser(user);
      this.store.setBalance({ credits: user.credits, goldCredits: user.goldCredits });
      this.i18nService.saveLocale(user.language);

      const activeSubscription = await this.pricingService.getActiveSubscription();
      this.store.setActiveSubscription(activeSubscription);
    } catch (e: any) {
      // do not show error, as this method was called from app bootstrap
      console.error(e);
    } finally {
    }
  }

  async updateUser(user: UpdateUserDto): Promise<UpdateUserResult> {
    try {
      const refreshedUser = await this.api.updateUser(user);

      this.updateUserData(refreshedUser);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "network-error";
    }
  }

  async updateUserEmail(user: UpdateUserEmailDto): Promise<UpdateUserResult> {
    try {
      const refreshedUser = await this.api.updateUserEmail(user);

      this.updateUserData(refreshedUser);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "network-error";
    }
  }

  async updateUserCard(user: UpdateUserCardDto): Promise<UpdateUserResult> {
    try {
      const refreshedUser = await this.api.updateUserCard(user);

      this.updateUserData(refreshedUser);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "network-error";
    }
  }

  private async updateUserData(user: User) {
    this.storage.saveUser(user);
    this.store.setUser(user);
    this.store.setBalance({
      credits: user.credits,
      goldCredits: user.goldCredits,
    });
    this.i18nService.saveLocale(user.language);

    const activeSubscription = await this.pricingService.getActiveSubscription();
    this.store.setActiveSubscription(activeSubscription);
  }

  async updateUserLocal(user: Partial<User>) {
    const oldUserData = this.store.getUser();
    const refreshedUser = { ...oldUserData!, ...user };
    this.storage.saveUser(refreshedUser);
    this.store.setUser(refreshedUser);
    this.store.setBalance({
      credits: refreshedUser.credits,
      goldCredits: refreshedUser.goldCredits,
    });
    this.i18nService.saveLocale(user.language!);

    const activeSubscription = await this.pricingService.getActiveSubscription();
    this.store.setActiveSubscription(activeSubscription);
  }

  private showSetRoleNotification() {
    let shouldShowRoleNotification = false;

    if (!this.configService.getConfig().company.flags.isUserRoleNotificationAvailable) {
      return;
    }

    const lastShowDate = this.storage.getLastRoleNotificationDate();
    if (!lastShowDate) {
      shouldShowRoleNotification = true;
    } else {
      const showDate = DateTime.fromSeconds(lastShowDate);
      if (showDate.plus({ days: 7 }) < DateTime.now()) {
        shouldShowRoleNotification = true;
      }
    }

    if (this.store.getUser()?.role && this.store.getUser()!.role > 0) {
      shouldShowRoleNotification = false;
    }

    if (shouldShowRoleNotification) {
      this.notificationService.notify({
        content: $localize`:@@user.profile.please-update-account-with-role:`,
        showOpts: "dont-close",
        type: "info",
      });
      this.storage.setLastRoleNotificationDate(DateTime.now().toSeconds());
    }
  }

  getUserActions() {
    return this.api.getUserActions();
  }

  async updateUserAction(action: UpdateUserActionDto) {
    return await this.api.updateUserAction(action);
  }

  async updateUserProfilePicture(userId: number, file: File): Promise<UpdateUserResult> {
    try {
      const result = await this.api.uploadUserProfilePicture(userId, file);

      // this.storage.saveUser(refreshedUser);
      // this.store.setUser(refreshedUser);
      // this.store.setBalance({
      //   credits: refreshedUser.credits,
      //   goldCredits: refreshedUser.goldCredits,
      // });
      // this.i18nService.saveLocale(user.language!);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "network-error";
    }
  }

  async getUserProfilePicture(user: User) {
    return {
      url: `${this.configService.getConfig().environment.apiRootUrl}/user/${user.id}/profile-picture/${user.avatar}`,
    };
  }

  async deleteUser(): Promise<UpdateUserResult> {
    try {
      const result = await this.api.deleteUser();
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "network-error";
    }
  }

  checkIsTrialPeriodActive(trialActivatedDate: string, trialPeriodInDay: number): TrialData {
    const today = DateTime.now(); //.set({ hour: 0, minute: 0, second: 0 });
    const start = DateTime.fromISO(trialActivatedDate); //.set({ hour: 0, minute: 0, second: 0 });
    const expireDate = start.plus({ days: trialPeriodInDay });
    const diffInDays = today.diff(start, "days").toObject().days ?? 0;

    return {
      diffInDays: diffInDays,
      trialActivatedDate,
      expireDate,
      isTrialActive: diffInDays <= trialPeriodInDay,
      trialPeriodInDay,
    };
  }
}
