import { ChangeDetectorRef, Component, Input, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { slideInOutVertical } from "@metranpage/components";
import { Observable, Subscription, map } from "rxjs";
import { CommunityDataService, PublishedObjectsDataSourceType } from "../community-data.service";

@Component({
  selector: "m-published-objects-grid",
  templateUrl: "./published-objects-grid.view.html",
  styleUrls: ["./published-objects-grid.view.scss"],
  animations: [slideInOutVertical],
})
export class PublishedObjectsGridView {
  @Input()
  objectType!: "images" | "covers";

  protected items$!: Observable<PublishedObjectsDataSourceType[] | undefined>;

  protected isLoading = false;

  sub: Subscription = new Subscription();

  constructor(
    private readonly communityDataService: CommunityDataService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    this.items$ = this.communityDataService.getItems(this.objectType).pipe(
      map((items) => {
        if (items && items.length > 0) {
          return items.filter((i) => i.isPublic);
        }
        return undefined;
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cdr.detectChanges();
  }

  protected async onScrollEvent() {
    this.communityDataService.loadNextPage(this.objectType);
  }

  protected onObjectClick(objectId: number) {
    // this.routerService.projectModalRoute(this.route, [generatedImage.id], false, "preview-image");
    this.router.navigate([this.objectType, objectId]);

    document.querySelector("m-community-grid-and-detail-view")?.scrollTo({ top: 0, behavior: "smooth" });
  }
}
