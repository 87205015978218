import { Routes } from "@angular/router";
import { AuthGuard } from "@metranpage/auth";
import { CommunityDisabledGuard } from "@metranpage/core";
import { HomePage } from "./pages/home/home.page";
import { HomeBreadcrumbsResolver } from "./services/home-breadcrumbs.resolver";
import { CommunityDetailView } from "./views/community/community-detail-view/community-detail.view";
import { CommunityGridAndDetailView } from "./views/community/community-grid-and-detail/community-grid-and-detail.view";

export const homeRoutes: Routes = [
  {
    path: "",
    component: HomePage,
    canActivate: [AuthGuard, CommunityDisabledGuard],
    data: {
      showTopBar: true,
      showMobileWarning: false,
      welcomeModalVisible: true,
      hasOnboarding: true,
      hideRePlain: true,

      breadcrumbs: {
        resolver: HomeBreadcrumbsResolver,
      },
    },
    children: [
      {
        path: "",
        component: CommunityGridAndDetailView,
        children: [
          {
            path: "images/:objectId",
            component: CommunityDetailView,
          },
        ],
      },
      {
        path: ":objectType",
        component: CommunityGridAndDetailView,
        children: [
          {
            path: ":objectId",
            component: CommunityDetailView,
          },
        ],
      },
    ],
  },
];
