import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationsPopUpService } from "@metranpage/core";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "m-login-google-page",
  templateUrl: "./login-google.page.html",
  styleUrls: ["./login-google.page.scss"],
})
export class LoginGooglePage implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly notificationsService: NotificationsPopUpService,
  ) {}

  async ngOnInit(): Promise<void> {
    const result = await this.authService.processGoogleResponse();

    if (result === "success") {
      this.router.navigate(["/books"]);
    } else if (result === "social-login-error") {
      this.router.navigate(["/login"]);
      this.notificationsService.error($localize`:@@user.auth.cant-login-google:`);
    }
  }
}
