import { Component } from "@angular/core";

@Component({
  selector: "m-account-tariff-and-payments-host-page",
  template: `
    <m-ng-island>
      <m-account-tariff-and-payments-nav-view />

      <div class="content">
        <router-outlet></router-outlet>
      </div>    
    </m-ng-island>
  `,
  styles: [
    `
      @import '../../../../../../apps/metranpage/src/styles/mixins';

      :host {
        @include text;

        display: flex;
        width: 100%;
        height: 100%;
        user-select: none;
      }

      m-ng-island {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        gap: 16px;
      }

      .content {
        @include text;
        @include scrollbars-panels;

        display: flex;
        overflow: auto;
        width: 100%;
        justify-content: flex-start;
        overflow-x: hidden;
      }
    `,
  ],
})
export class AccountTariffAndPaymentsHostPage {}
