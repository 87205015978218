import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { BookCover, CompanyFont } from "@metranpage/book-data";
import {
  ImageGeneration,
  ImageGenerationAdvancedStyle,
  ImageGenerationBasicStyle,
  ImageGenerationService,
} from "@metranpage/image-generation";
import {
  CoverConceptualGeneration,
  CoverConceptualGenerationDataDto,
  CoverConceptualGenerationStore,
  TextGenerationService,
} from "@metranpage/text-generation";
import { Subscription } from "rxjs";
import { FontsService } from "../../services/fonts.service";
import {
  FontsWithColorData,
  ImageGenerationData,
} from "../cover-conceptual-assistant-generation-result/cover-conceptual-assistant-generation-result.view";

@Component({
  selector: "m-cover-conceptual-assistant-generation-host",
  templateUrl: "./cover-conceptual-assistant-generation-host.view.html",
  styleUrls: ["./cover-conceptual-assistant-generation-host.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverConceptualAssistantGenerationHostView implements OnInit, OnDestroy {
  @Input()
  cover!: BookCover;

  @Output()
  applyFontsAndColors = new EventEmitter<FontsWithColorData>();
  @Output()
  createImageGeneration = new EventEmitter<ImageGeneration>();
  @Output()
  generateCoverConceptual = new EventEmitter<CoverConceptualGenerationDataDto>();
  @Output()
  closeConceptualAssistant = new EventEmitter<void>();

  protected coverConceptualGeneration?: CoverConceptualGeneration;
  protected stylesBasic: ImageGenerationBasicStyle[] = [];
  protected stylesAdvanced: ImageGenerationAdvancedStyle[] = [];

  protected fonts: CompanyFont[] = [];

  sub: Subscription = new Subscription();

  constructor(
    private readonly coverConceptualGenerationStore: CoverConceptualGenerationStore,
    private readonly textGenerationService: TextGenerationService,
    private readonly imageGenerationService: ImageGenerationService,
    private readonly fontsService: FontsService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    this.sub.add(
      this.coverConceptualGenerationStore
        .getCoverConceptualGenerationObservable()
        .subscribe((coverConceptualGeneration) => {
          this.coverConceptualGeneration = coverConceptualGeneration;
          this.cdr.detectChanges();
        }),
    );

    await this.getImageGenerationStyles();
    await this.getFonts();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async getImageGenerationStyles() {
    this.stylesBasic = await this.imageGenerationService.loadImageGenerationBasicStyles();
    this.stylesAdvanced = await this.imageGenerationService.loadImageGenerationAdvancedStyles();
    this.cdr.detectChanges();
  }

  async getFonts() {
    this.fonts = await this.fontsService.getCompanyFonts();
    this.cdr.detectChanges();
  }

  protected onBackClick() {
    this.coverConceptualGeneration = undefined;
    this.cdr.detectChanges();
  }

  protected onApplyFontsAndColorsClick(data: FontsWithColorData) {
    this.applyFontsAndColors.emit(data);
  }

  protected onGenerateImageClick(data: ImageGenerationData) {
    const settings: ImageGeneration = {
      id: 0,
      width: 0,
      height: 0,
      imagesCount: 4,
      prompt: data.prompt,
      promptProcessed: "",
      negativePrompt: "",
      negativePromptProcessed: "",
      styleId: data.styleId,
      userId: -1,
      detalization: "detailed",
      realistic: "",
      mood: this.imageGenerationService.getMood(data.mood ?? ""),
      techniqueId: -1,
      colorScheme: "",
      model: "",
      mode: "",
      creditsCount: 0,
      status: "created",
      error: "",
      generationMode: data.mode ?? "basic",
      generatedImages: [],
      isAgeEnable: false,
      isColorSchemeEnable: false,
      isNegativePromptEnable: false,
      age: this.imageGenerationService.getAge(data.age ?? ""),
      contrast: 3.5,
    };

    this.createImageGeneration.emit(settings);
  }

  protected generateCoverConceptualClick(data: CoverConceptualGenerationDataDto) {
    this.generateCoverConceptual.emit(data);
  }

  protected closeConceptualAssistantClick() {
    this.closeConceptualAssistant.emit();
  }
}
