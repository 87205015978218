import { Component } from "@angular/core";
import {
  LoadingService,
  Notification,
  NotificationService,
  NotificationStore,
  NotificationsPopUpService,
} from "@metranpage/core";
import { User, UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";

@Component({
  selector: "m-account-main-page",
  template: `<m-account-notifications-view [user]="user" [notifications]="notifications" (onScroll)="onScroll()" />`,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class AccountNotificationsPage {
  protected user?: User;

  protected notifications: Notification[] = [];

  protected timeoutForMarkNotificationsAsRead = 5;

  protected page = 1;
  protected pageCount = 1;

  sub: Subscription = new Subscription();

  constructor(
    private readonly loadingService: LoadingService,
    private readonly notificationsPopUpService: NotificationsPopUpService,
    private readonly notificationService: NotificationService,
    private readonly notificationStore: NotificationStore,
    userStore: UserStore,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );

    this.sub.add(
      this.notificationStore.getNotificationsObservable().subscribe((notifications) => {
        this.notifications = notifications;
      }),
    );
  }

  async ngOnInit() {
    await this.loadNotificationsPaginated();

    await this.notificationService.markNotificationsAsRead();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected async loadNotificationsPaginated() {
    this.loadingService.startLoading({ fullPage: true });
    await this.notificationService.loadNotificationsPaginated(this.page);
    this.loadingService.stopLoading();
  }

  // protected async markNotificationsAsRead() {
  //   setTimeout(async () => {
  //     await this.notificationService.markNotificationsAsRead();
  //   }, this.timeoutForMarkNotificationsAsRead * 1000);
  // }

  protected async onScroll() {
    this.page++;
    await this.notificationService.loadNotificationsPaginated(this.page);
  }
}
