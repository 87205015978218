import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { PricingViewService } from "@metranpage/pricing";
import * as _ from "lodash-es";
import { takeUntil } from "rxjs";
import { Promocode } from "../../models/promocode";
import { Tariff } from "../../models/tariff";
import { AdminPromocodesService } from "../../services/promocodes/promocodes.service";
import { AdminPromocodesStore } from "../../services/promocodes/promocodes.store";
import { AdminTariffsService } from "../../services/tariffs/tariffs.service";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-promocode-page",
  templateUrl: "./promocode.page.html",
  styleUrls: ["./promocode.page.scss"],
})
export class AdminPromocodePage extends AdminBasePage implements OnInit, AfterViewInit {
  promocode: Promocode | undefined;
  tariffs: Tariff[] = [];

  protected form!: FormGroup;

  constructor(
    private readonly adminPromocodesService: AdminPromocodesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly pricingViewService: PricingViewService,
    private readonly cdr: ChangeDetectorRef,
    private readonly adminTariffsService: AdminTariffsService,
    adminPromocodesStore: AdminPromocodesStore,
  ) {
    super();

    this.createForm();

    this.addSub(
      adminPromocodesStore
        .getActivePromocodeObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(async (promocode) => {
          this.promocode = promocode;
          if (promocode) {
            const formData = _.omit(promocode, ["id"]);
            if (formData.createdDate) {
              formData.createdDate = new Date(formData.createdDate).toISOString().split("T")[0];
            }
            if (formData.expireDate) {
              formData.expireDate = new Date(formData.expireDate).toISOString().split("T")[0];
            }
            if (!formData.tariffId) {
              formData.tariffId = undefined;
            }

            await this.loadTariffsForCompany();

            this.form.patchValue(formData);
          }
        }),
    );
  }

  ngOnInit(): void {}

  async ngAfterViewInit() {
    if ("promocodeId" in this.route.snapshot.params) {
      this.adminPromocodesService.loadPromocode(this.route.snapshot.params.promocodeId);
    }
  }

  async onSave() {
    if (!this.promocode) {
      return;
    }

    const formData = this.form.getRawValue();

    if (formData.createdDate) {
      formData.createdDate = new Date(formData.createdDate);
    } else {
      formData.createdDate = undefined;
    }
    if (formData.expireDate) {
      formData.expireDate = new Date(formData.expireDate);
    } else {
      formData.expireDate = undefined;
    }

    formData.token = formData.token.trim();

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminPromocodesService.updatePromocode(this.promocode.id, formData);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.router.navigate(["admin", "promocodes"]);
    } else {
      this.notificationService.error($localize`:@@admin.promocodes.promocode.error.cant-update-promocode:`);
    }
  }

  onCancel() {
    this.router.navigate(["admin", "promocodes"]);
  }

  createForm() {
    this.form = this.adminPromocodesService.createPromocodeForm();
  }

  private async loadTariffsForCompany() {
    this.tariffs = await this.adminTariffsService.loadTariffs();
    this.cdr.detectChanges();
  }

  protected getOptionsForCurrencySelect() {
    return this.pricingViewService.getOptionsForCurrencySelect();
  }

  protected getOptionsForSubtitleSelect() {
    return this.pricingViewService.getOptionsForSubtitleSelect();
  }

  protected getOptionsForTariffsSelect() {
    return this.adminTariffsService.getOptionsForTariffsSelect(this.tariffs);
  }
}
