import { ChangeDetectorRef, Component, DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { tap } from "rxjs";
import { BaseControl } from "../base-control/base-control.component";

@Component({
  template: "",
  standalone: true,
})
export abstract class BaseFormControl<V> extends BaseControl implements ControlValueAccessor {
  protected value: V = this.getInitValue();

  private changeHandlers: ((state: V) => void)[] = [];
  private touchHandlers: ((state: V) => void)[] = [];

  protected readonly ngControl = inject(NgControl, { optional: true, self: true });
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    super();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (!this.ngControl) return;
    this.ngControl.valueChanges
      ?.pipe(
        tap((value) => {
          if (this.hasChanged(value)) return;
          this.value = value;
          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  writeValue(value: V): void {
    if (value === this.value) return;
    this.value = value;
  }

  registerOnChange(fn: (state: V) => void): void {
    this.changeHandlers.push(fn);
  }

  registerOnTouched(fn: (state: V) => void): void {
    this.touchHandlers.push(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.setDisabled(isDisabled);
  }

  protected onChange(value: V) {
    if (this.hasChanged(value)) return;

    this.value = value;
    for (const handler of this.changeHandlers) {
      const casted = this.castValueBeforeOnChange(this.value);
      handler(casted);
    }
    this.onTouched();
  }

  protected onTouched() {
    for (const handler of this.touchHandlers) {
      const casted = this.castValueBeforeOnChange(this.value);
      handler(casted);
    }
  }
  protected hasChanged(value: V): boolean {
    return this.value === value;
  }

  protected abstract getInitValue(): V;

  protected castValueBeforeOnChange(value: V): any {
    return value;
  }
}
