import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BankCardData } from "@metranpage/pricing-data";

@Component({
  selector: "m-account-bank-card",
  templateUrl: "./account-bank-card.view.html",
  styleUrls: ["./account-bank-card.view.scss"],
})
export class AccountBankCardView {
  @Input()
  cardData?: BankCardData;
  @Input()
  isActive = false;
  @Input()
  newCard = false;

  @Output()
  onAddNewCardClick = new EventEmitter<void>();
  @Output()
  onSetCardForPaymentClick = new EventEmitter<number>();
  @Output()
  onShowDeleteCardModal = new EventEmitter<number>();

  getCardTypeIcon(type: string) {
    return `/assets/img/cards/${type.toLowerCase()}.svg`;
  }

  setCardForPayment(cardId: number) {
    this.onSetCardForPaymentClick.emit(cardId);
  }

  protected showDeleteCardModal(cardId: number) {
    this.onShowDeleteCardModal.emit(cardId);
  }
}
