import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SelectValue } from "@metranpage/components";
import { RouterService } from "@metranpage/core";
import { Subscription } from "rxjs";
import { HomeService } from "../../../services/home.service";
import { CommunityDataService } from "../community-data.service";

export type CommunityFilterSelectorType = "images" | "covers";

@Component({
  selector: "m-community-grid-and-detail-view",
  templateUrl: "community-grid-and-detail.view.html",
  styleUrls: ["community-grid-and-detail.view.scss"],
})
export class CommunityGridAndDetailView {
  objectType: CommunityFilterSelectorType = "images";

  protected selectedFilterOptionId: CommunityFilterSelectorType = "images";
  protected filterOptions: SelectValue[] = [
    // TODO change order after release and some covers published
    { id: "images", value: $localize`:@@home.community.filter.published-images:`, hasNewMark: true },
    { id: "covers", value: $localize`:@@home.community.filter.published-covers:`, hasNewMark: true },
  ];

  private sub = new Subscription();

  constructor(
    private readonly communityDataService: CommunityDataService,
    private readonly homeService: HomeService,
    private readonly routerService: RouterService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.sub.add(
      this.route.params.subscribe((params) => {
        if (params.objectType) {
          this.objectType = params.objectType;
          this.selectedFilterOptionId = this.objectType;
        } else {
          this.objectType = "images";
          this.selectedFilterOptionId = "images";
        }
      }),
    );
  }

  protected onCreateCoverClick() {
    this.homeService.showNewProjectModal({ projectType: "cover" });
  }

  protected onGenerateImagesClick() {
    this.router.navigate(["/", "image-generation"]);
  }

  protected onSelectFilterOption(filter: string | number) {
    this.selectedFilterOptionId = filter as CommunityFilterSelectorType;

    this.router.navigate(["/", this.selectedFilterOptionId]);
  }

  protected onScrollEvent() {
    this.communityDataService.loadNextPage(this.objectType);
  }
}
