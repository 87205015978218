import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { I18nService } from "@metranpage/i18n";
import { NotificationLowBalance } from "../../../models/notification";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-low-balance-card-view",
  templateUrl: "account-notification-low-balance-card.view.html",
  styleUrls: ["account-notification-low-balance-card.view.scss"],
})
export class AccountNotificationLowBalanceCardView extends AccountNotificationBaseCardView<NotificationLowBalance> {
  constructor(
    private readonly router: Router,
    private readonly i18nService: I18nService,
  ) {
    super();
  }

  protected getTitle() {
    return `${$localize`:@@user.profile.notifications.card.low-balance.title:`}
      ${this.i18nService.pluralize($localize`:@@common.pluralize.left:`, { value: this.payload.creditsCount })}
      ${this.payload.creditsCount}
      ${this.i18nService.pluralize($localize`:@@pricing.pluralize-credits:`, { credits: this.payload.creditsCount })}
    `;
  }

  protected openTariffsPage() {
    this.router.navigate(["/payments/subscription"]);
  }
}
