import { Component, ElementRef, Input } from "@angular/core";

@Component({
  selector: "m-pricing-menu-item-component",
  template: `
    <div class="content">
      <span>{{ title }}</span>
    </div>
  `,
  styles: [
    `
      @import '../../../../../../apps/metranpage/src/styles/mixins';

      :host {
        @include text;

        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        color: var(--color-font-nav-tab-text);
        cursor: pointer;
        font-family: var(--font);
        font-size: var(--font-size-s);
        font-weight: var(--font-weight);

        &.active {
          border-bottom: 3px solid var(--accent);
          color: var(--accent);
        }

        /* &:hover {
          background-color: var(--color-bg-panel);
        } */
      }
    `,
  ],
})
export class PricingMenuItemComponent {
  @Input()
  icon!: string;
  @Input()
  title!: string;
  @Input()
  colorStyle: "normal" | "danger" = "normal";
  @Input()
  showWarning? = false;

  constructor(private readonly elementRef: ElementRef) {}

  getIconColor() {
    if (this.colorStyle === "danger") {
      return "--color-font-text-danger";
    }
    return "--color-font-main";
  }
}
