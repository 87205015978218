import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@metranpage/auth";

@Component({
  selector: "m-account-tariff-and-payments-nav-view",
  template: `
    <m-account-tariff-and-payments-menu-item-component
      routerLink="/account/tariff-and-payments"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      title="user.profile.tariff-and-payments.nav-tabs.tariff"
      i18n-title="@@user.profile.tariff-and-payments.nav-tabs.tariff"
    />

    <m-account-tariff-and-payments-menu-item-component
      routerLink="/account/tariff-and-payments/payments-info"
      routerLinkActive="active"
      title="user.profile.tariff-and-payments.nav-tabs.payments-info"
      i18n-title="@@user.profile.tariff-and-payments.nav-tabs.payments-info"
    />

    <m-account-tariff-and-payments-menu-item-component
      routerLink="/account/tariff-and-payments/payments-history"
      routerLinkActive="active"
      title="user.profile.tariff-and-payments.nav-tabs.payments-history"
      i18n-title="@@user.profile.tariff-and-payments.nav-tabs.payments-history"
    />
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        height: var(--nav-tabs-pricing-height);
        min-height: var(--nav-tabs-pricing-height);
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid var(--color-border-nav-tabs);
        column-gap: 32px;
        user-select: none;
      }
    `,
  ],
})
export class AccountTariffAndPaymentsNavView {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}
}
