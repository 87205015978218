import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationPayloadCoverModeration } from "../../../models/notification";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-cover-moderation-card-view",
  templateUrl: "account-notification-cover-moderation-card.view.html",
  styleUrls: ["account-notification-cover-moderation-card.view.scss"],
})
export class AccountNotificationCoverModerationCardView extends AccountNotificationBaseCardView<NotificationPayloadCoverModeration> {
  constructor(private readonly router: Router) {
    super();
  }

  openCoverOnHomePage() {
    this.router.navigate(["covers", this.payload.coverId]);
  }

  openCoverEditor() {
    this.router.navigate(["books", this.payload.bookId, "cover"]);
  }
}
