<div class="backdrop" [class.clickable]="false" (click)="close.emit()">
  <div class="close-icon" (click)="close.emit()">
    <m-ng-icon src="close-cross.svg" [size]="16" />
  </div>
</div>

<div class="wrapper">
  <div class="outer-cell" *ngIf="isImagesGenerationAvailable">
    <div class="cell" (click)="showImageGenerator()">
      <m-icon class="image" src="/assets/img/create-image-02-{theme}.png" />
      <div class="container">
        <div class="caption">
          <m-icon size="s" src="/assets/icons/stars-01.svg" color="fake"></m-icon>
          <span i18n="@@cover-editor.sidebar.images.AI.caption">cover-editor.sidebar.images.AI.caption</span>
        </div>
        <div class="text" i18n="@@cover-editor.sidebar.images.AI.description">
          cover-editor.sidebar.images.AI.description
        </div>
      </div>
    </div>
  </div>
  <div class="outer-cell">
    <div class="cell" (click)="fileUpload.openFileSelector()">
      <m-icon class="image" src="/assets/img/upload-image-02-{theme}.png" />
      <div class="container">
        <div class="caption">
          <m-icon size="s" src="/assets/icons/folder-01.svg" color="fake"></m-icon>
          <span i18n="@@cover-editor.sidebar.images.local.caption">cover-editor.sidebar.images.local.caption</span>
        </div>
        <div class="text" i18n="@@cover-editor.sidebar.images.local.description">
          cover-editor.sidebar.images.local.description
        </div>
      </div>
    </div>
  </div>
</div>

<div class="image-generator-host" *ngIf="isImageGeneratorVisible">
  <m-image-generation-view
    [isModal]="true"
    (onClose)="hideImageGenerator()"
    (onSelectedImage)="selectGeneratedImage($event)"
  ></m-image-generation-view>
</div>

<m-file-drop #fileUpload [accept]="imageFileTypes" [multiple]="false" (dropped)="uploadImage($event)"></m-file-drop>
