<m-card class="account-verification-panel">
  <m-logo logo-style="full"></m-logo>

  <div class="account-verification-heading" i18n="@@user.verify-email-send-header">user.verify-email-send-header</div>
  <div class="text">
    <span class="account-verification-description" i18n="@@user.verify-email-send-body">
      user.verify-email-send-body
    </span>

    <span
      class="account-verification-button"
      button-style="link"
      i18n="@@user.verify-email-send-button"
      (click)="onSendVerificationEmailButtonClick()"
      [ngClass]="{'disabled': isSendButtonDisabled()}"
    >
      user.verify-email-send-button
    </span>

    <span *ngIf="timeoutTimeLeft > 0" class="account-verification-timer">
      <span i18n="@@user.verify-email-send-timer1">@@user.verify-email-send-timer1</span>
      {{ timeoutTimeLeft }}
      <span i18n="@@user.verify-email-send-timer2">@@user.verify-email-send-timer2</span>
    </span>
  </div>

  <!-- <m-button
    class="account-verification-button"
    button-style="main-action"
    i18n="@@user.verify-email-send-button"
    (click)="onSendVerificationEmailButtonClick()"
    [is-disabled]="isSendButtonDisabled()"
  >
    user.verify-email-send-button
  </m-button> -->

  <!-- <div *ngIf="isEmailSent" class="email-sent" i18n="@@user.verify-email-send">user.verify-email-send</div> -->

  <svg-icon src="/assets/icons/notification-close.svg" class="close-icon" (click)="onBackClick.emit()"></svg-icon>

  <m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>
</m-card>
