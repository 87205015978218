<m-account-notification-card-host [notification]="notification" [isLast]="isLast">
  <div icon class="icons-wrapper">
    <m-ng-icon class="icon-main" [size]="24" src="notification-image-moderation.svg" />
    <m-ng-icon-2
      *ngIf="!payload.rejectReason"
      class="icon-secondary-success"
      [size]="22"
      src="notification-success.svg"
    />
    <m-ng-icon-2 *ngIf="payload.rejectReason" class="icon-secondary-fail" [size]="20" src="notification-fail.svg" />
  </div>

  <div title class="title-group">
    <div class="title">
      <span *ngIf="!payload.rejectReason" i18n="@@user.profile.notifications.card.image-moderation-success.title">
        user.profile.notifications.card.image-moderation-success.title
      </span>
      <span *ngIf="payload.rejectReason" i18n="@@user.profile.notifications.card.image-moderation-fail.title">
        user.profile.notifications.card.image-moderation-fail.title
      </span>
    </div>

    <div class="card-label" i18n="@@user.profile.notifications.filter.moderation">
      user.profile.notifications.filter.moderation
    </div>
  </div>

  <div description class="description">
    <div *ngIf="!payload.rejectReason" class="row">
      <span i18n="@@user.profile.notifications.card.image-moderation-success.description-1">
        user.profile.notifications.card.image-moderation-success.description-1
      </span>
      <a
        class="action"
        i18n="@@user.profile.notifications.card.image-moderation-success.description-action"
        (click)="openImageOnHomePage()"
      >
        user.profile.notifications.card.image-moderation-success.description-action
      </a>
      <span i18n="@@user.profile.notifications.card.image-moderation-success.description-2">
        user.profile.notifications.card.image-moderation-success.description-2
      </span>
    </div>

    <div *ngIf="payload.rejectReason" class="row">
      <span i18n="@@user.profile.notifications.card.image-moderation-fail.description-1">
        user.profile.notifications.card.image-moderation-fail.description-1
      </span>
      <a
        class="action"
        i18n="@@user.profile.notifications.card.image-moderation-fail.description-action"
        (click)="openImageGeneration()"
      >
        user.profile.notifications.card.image-moderation-fail.description-action
      </a>
      <span
        *ngIf="payload.rejectReason !== 'no-reason'"
        i18n="@@user.profile.notifications.card.image-moderation-fail.description-2"
      >
        user.profile.notifications.card.image-moderation-fail.description-2
      </span>
      <span
        *ngIf="payload.rejectReason === 'no-reason'"
        i18n="@@user.profile.notifications.card.image-moderation-fail.description-without-reason"
      >
        user.profile.notifications.card.image-moderation-fail.description-without-reason
      </span>
    </div>
    <div *ngIf="payload.rejectReason && payload.rejectReason !== 'no-reason'" class="row">
      {{ payload.rejectReason }}
    </div>
  </div>
</m-account-notification-card-host>
