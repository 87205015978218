import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Color } from "@metranpage/book-data";
import { ColorConverterService, HSV } from "@metranpage/components";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { filter, startWith, tap } from "rxjs";
import { CoverUiService } from "../../services/cover/cover-ui.service";

export type PopupPosition = "top" | "bottom";

const contextMenuTimeout = 500;

@UntilDestroy()
@Component({
  selector: "m-cover-color-select",
  templateUrl: "./cover-color-select.component.html",
  styleUrls: ["./cover-color-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverColorSelectComponent implements OnChanges, OnInit {
  @Input() color!: Color;
  @Input() showDelete = false;
  @Input() popupPosition: PopupPosition = "top";
  @Input() useColorPickerExternalHost = true;

  @Output() update = new EventEmitter<Color>();
  @Output() delete = new EventEmitter();

  @ViewChild("colorPicker")
  protected colorPickerRef!: ElementRef;

  @ViewChild("colorContextMenu")
  protected colorContextMenuRef!: ElementRef;

  isColorPickerVisible = false;
  isColorStopContextVisible = false;

  contextTimeoutHandle?: number;

  readonly form = new FormGroup({
    color: new FormControl<HSV | null>(null, [Validators.required]),
  });

  constructor(
    private readonly colorConverter: ColorConverterService,
    private readonly coverUiService: CoverUiService,
    private readonly changeDetector: ChangeDetectorRef,
  ) {
    coverUiService.colorContextMenu$.pipe(untilDestroyed(this)).subscribe((v) => {
      if (v !== this.colorContextMenuRef) {
        this.isColorStopContextVisible = false;
        changeDetector.markForCheck();
      }
    });

    coverUiService.colorPicker$.pipe(untilDestroyed(this)).subscribe((v) => {
      if (v !== this.colorPickerRef) {
        this.isColorPickerVisible = false;
        changeDetector.markForCheck();
      }
    });
  }

  ngOnInit(): void {
    this.form.controls.color.valueChanges
      .pipe(
        untilDestroyed(this),
        startWith(this.form.controls.color.value),
        filter(() => this.form.valid),
        tap((v) => {
          if (!this.form.dirty || !this.form.valid) {
            return;
          }
          const hsv = v;
          if (!hsv) {
            return;
          }
          const rgb = this.colorConverter.hsv2rgb(hsv.h, hsv.s, hsv.v);
          this.color = new Color(rgb.r, rgb.g, rgb.b, hsv.a ?? 1);
          this.update.emit(this.color);
        }),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      this.form.controls.color.setValue(
        this.colorConverter.rgb2hsv(this.color.r, this.color.g, this.color.b, this.color.a),
      );
    }
  }

  toggleColorPickerVisibility() {
    this.isColorPickerVisible = !this.isColorPickerVisible;
    if (this.isColorPickerVisible) {
      this.coverUiService.showColorPicker(this.colorPickerRef);
    }
  }

  showColorStopContext() {
    this.isColorStopContextVisible = true;
    if (this.contextTimeoutHandle) {
      window.clearTimeout(this.contextTimeoutHandle);
    }
    this.coverUiService.showColorContextMenu(this.colorContextMenuRef);
  }

  hideColorStopContext() {
    this.contextTimeoutHandle = window.setTimeout(() => {
      this.isColorStopContextVisible = false;
      this.changeDetector.markForCheck();
    }, contextMenuTimeout);
  }

  onDeleteColor() {
    this.delete.emit();
  }

  onEyeDropper() {
    this.isColorPickerVisible = false;
    this.coverUiService.showEyeDropper((color: Color) => {
      this.form.markAsDirty();
      this.form.controls.color.setValue(this.colorConverter.rgb2hsv(color.r, color.g, color.b, color.a));
    });
  }
}
