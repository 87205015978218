import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { BaseFormControl } from "../base-form-control/base-form-control.component";
import { IconComponent } from "../icon/icon.component";
import { ReactiveFormsModule } from "@angular/forms";

export type InputType = "text" | "email" | "password" | "number" | "date";
export type InputTextAlign = "left" | "center" | "right";

@Component({
  selector: "m-ng-input",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IconComponent],
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
export class InputComponent extends BaseFormControl<string> {
  @Input()
  placeholder = "";
  @Input()
  isRequired = false;
  @Input("text-align")
  textAlign: InputTextAlign = "left";
  @Input()
  name = "";
  @Input()
  type: InputType = "text";
  @HostBinding("class.highlighted")
  @Input("is-highlighted")
  isHighlighted = false;
  @Input()
  size: "32" | "40" | "48" = "48"; // remember to add to scss

  @Output()
  focus = new EventEmitter<void>();
  @Output()
  focusout = new EventEmitter<void>();

  @ViewChild("input", { static: true, read: ElementRef })
  protected inputElementRef?: ElementRef;

  protected override getInitValue(): string {
    return "";
  }

  public override writeValue(value: string): void {
    super.writeValue(value);
    if (this.inputElementRef) {
      this.inputElementRef.nativeElement.value = value;
    }
  }

  protected getInputType(): string {
    return this.type;
  }

  onInputChange(event: Event) {
    if (!event.target) {
      return;
    }
    const value = (event.target as HTMLInputElement).value;
    this.onChange(value);
  }

  /**
   * Return values available here:
   *  https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  protected getAutocomplete(): string {
    if (this.type === "email") {
      return "email";
    }
    return "random-string-as-off-value-not-work";
  }

  override onFocus(): void {
    super.onFocus();
    this.inputElementRef?.nativeElement?.select();
  }
  override onFocusOut(): void {
    super.onFocusOut();
    this.inputElementRef?.nativeElement?.blur();
  }

  @HostBinding("class")
  get hostClasses() {
    return `text-align-${this.textAlign} size-${this.size}`;
  }

  @HostListener("pointerdown")
  onPressed() {
    this.isPressed = true;
    this.cdr.markForCheck();
  }
  @HostListener("pointerup")
  onUnpressed() {
    this.isPressed = false;
    this.cdr.markForCheck();
  }
  @HostListener("mouseout")
  override onUnhover() {
    super.onUnhover();
    this.isPressed = false;
    this.cdr.markForCheck();
  }
}
