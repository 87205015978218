import { fabric } from "fabric";
import { ActiveSelection } from "fabric/fabric-impl";
import { ObjectShape } from "../cover.view";

const colorActive = "#E02379";

export class SelectionManager {
  private _objects: ObjectShape[] = [];
  private _selection?: fabric.ActiveSelection;

  constructor(private readonly stage: fabric.Canvas) {}

  get currentObject(): ObjectShape | undefined {
    if (this._objects.length === 1) {
      return this._objects[0];
    }
    return undefined;
  }

  get selectedObjects(): ObjectShape[] {
    return this._objects;
  }

  get activeSelection(): ActiveSelection | undefined {
    //return this._selection;
    const object = this.stage.getActiveObject();
    if (object instanceof fabric.ActiveSelection) {
      return object;
    }
    return undefined;
  }

  clear() {
    this._objects = [];
    this._selection = undefined;
    this.stage.discardActiveObject().requestRenderAll();
  }

  private createSelection(objects: ObjectShape[]) {
    this.stage.discardActiveObject();
    if (objects.length > 1) {
      this._selection = new fabric.ActiveSelection(
        objects.map((v) => v.shape!),
        {
          canvas: this.stage,
        },
      );
      SelectionManager.applySelectionStyles(this._selection);
      this.stage.setActiveObject(this._selection);
    } else if (objects[0]?.shape) {
      this.stage.setActiveObject(objects[0].shape);
    }

    this.stage.requestRenderAll();
  }

  selectObjects(objects: ObjectShape[], createSelection = false) {
    this._objects = [...objects];
    if (createSelection) {
      this.createSelection(this._objects);
    } else {
      const object = this.stage.getActiveObject();
      if (object instanceof fabric.ActiveSelection) {
        this._selection = object;
        SelectionManager.applySelectionStyles(this._selection);
      }
    }
  }

  static applySelectionStyles(selection: fabric.Object) {
    selection.cornerSize = 8;
    selection.cornerStrokeColor = colorActive;
    selection.cornerColor = "#ffffff";
    selection.transparentCorners = false;
    selection.borderColor = colorActive;
    selection.padding = 0;
  }
}
