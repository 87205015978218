import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

const noCheckApiUrls = [/.*login.*/, /.*register.*/, /.*restore\/request.*/, /.*restore\/reset.*/];
const noCheckUrls = [/.*login.*/, /.*registration.*/, /.*restore-password.*/, /.*reset-password.*/, /.*superadmin\/.*/];

@Injectable()
export class UnauthInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (this.shouldIntercept(request)) {
          if (err.status === 401) {
            if (this.shouldRedirect(this.router.routerState.snapshot.url)) {
              const authService = this.injector.get(AuthService);
              authService.logout();
              this.router.navigate(['login']);
            } else if (this.shouldRedirectSuperadminAuth(this.router.routerState.snapshot.url)) {
              this.router.navigate(['superadmin/auth']);
            }
          }
          console.error(err);
        }
        return throwError(() => err);
      }),
    );
  }

  private shouldIntercept(request: HttpRequest<unknown>): boolean {
    for (const noCheckUrl of noCheckApiUrls) {
      if (noCheckUrl.test(request.url)) {
        return false;
      }
    }

    return true;
  }

  private shouldRedirect(url: string): boolean {
    for (const noCheckUrl of noCheckUrls) {
      if (noCheckUrl.test(url)) {
        return false;
      }
    }

    return true;
  }

  private shouldRedirectSuperadminAuth(url: string): boolean {
    if (url === 'superadmin/auth') {
      return false;
    }
    if (url.indexOf('superadmin') !== -1) {
      return true;
    }
    return false;
  }
}
