<m-ng-island paddingSize="l">
<!-- <m-localized-text text="admin.cloudpayments.description" i18n-text="@@admin.cloudpayments.description"/> -->
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div>
    <span i18n="@@admin.s3.endpoint">admin.s3.endpoint</span>
    <m-ng-input  formControlName="endpoint" />
  </div>
  <div>
    <span i18n="@@admin.s3.region">admin.s3.region</span>
    <m-ng-input  formControlName="region" />
  </div>
  <div>
    <span i18n="@@admin.s3.bucket">admin.s3.bucket</span>
    <m-ng-input  formControlName="bucket" />
  </div>
  <div>
    <span i18n="@@admin.s3.access-key">admin.s3.access-key</span>
    <m-ng-input  formControlName="accessKey" />
  </div>
  <div>
    <span i18n="@@admin.s3.secret-key">admin.s3.secret-key</span>
    <m-ng-input  formControlName="secretKey" />
  </div>
</form>

<div class="row">
  <m-button button-style="primary" (onClick)="onSubmit()" [is-disabled]="!form.valid" i18n="@@admin.common.save">
    admin.common.save
  </m-button>

  <m-button button-style="secondary" (onClick)="onDelete()"  i18n="@@admin.common.delete">
    admin.common.delete
  </m-button>

</div>
</m-ng-island>
