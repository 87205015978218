import { CommonModule } from "@angular/common";
import { Component, HostBinding, Input } from "@angular/core";

type IslandPadding = "0" | "s" | "m" | "l";

@Component({
  selector: "m-ng-island",
  standalone: true,
  imports: [CommonModule],
  template: `
    <ng-content></ng-content>
  `,
  styles: [
    `
      :host {
        display: block;
        background-color: var(--surface-island);
        margin: 0;
        padding: var(--island-padding);
        width: fit-content;
        height: fit-content;
        border-radius: var(--island-radius-16);
        transition: border-top 0.2s;
        box-sizing: border-box;

        &.is-selected {
          border-top: 4px solid var(--accent);
        }
        &.island-padding-size-small {
          padding: 8px;
        }

        &.island-padding-size-medium {
          padding: 16px;
        }

        &.island-padding-size-large {
          padding: 26px;
        }
      }
    `,
  ],
})
export class IslandComponent {
  @Input() isSelected?: boolean;
  @Input() paddingSize?: IslandPadding = "m";

  @HostBinding("style.padding") get valid() {
    return `var(--island-padding-${this.paddingSize})`;
  }
}
