import { Component, HostBinding, Input, SimpleChanges } from "@angular/core";
import { GeneratedImage, GeneratedImageService } from "@metranpage/image-generation";

@Component({
  selector: "m-community-image-view",
  templateUrl: "image.view.html",
  styleUrls: ["image.view.scss"],
})
export class CommunityImageView {
  @Input()
  image!: GeneratedImage;

  protected url = "";

  constructor(private readonly generatedImageService: GeneratedImageService) { }

  @HostBinding("style.aspect-ratio")
  get aspectRatio() {
    return this.image?.width / this.image?.height;
  }

  @HostBinding("style.width")
  get width() {
    return `${this.image?.width * this.scaleFactor}px`;
  }
  @HostBinding("style.height")
  get height() {
    return `${this.image?.height * this.scaleFactor}px`;
  }

  get scaleFactor() {
    const maxAvailableHeight = Math.round(window.innerHeight / 100 * 65); // vh
    if (maxAvailableHeight < this.image?.height) {
      return maxAvailableHeight / this.image?.height;
    }
    return 1
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.image) {
      this.url = this.getUrlForImage(this.image);
    }
  }

  protected getUrlForImage(generatedImage: GeneratedImage) {
    return this.generatedImageService.getUrlForImage(generatedImage.imageGenerationId, generatedImage.imageUrl);
  }
}
