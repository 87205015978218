import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Book } from "@metranpage/book-data";
import { LoadingService, RouterService, filterUndefined } from "@metranpage/core";
import { ConfigService } from "@metranpage/core-interfaces";
import { FormatService, FormatStore } from "@metranpage/format-data";
import { User, UserStore } from "@metranpage/user-data";
import * as _ from "lodash-es";
import { Subscription } from "rxjs";
import { BooksStore } from "../../services/books.store";
import { SidebarSectionOption } from "../new-project-modal-sidebar-section/sidebar-section.view";

@Component({
  selector: "m-new-project-modal",
  templateUrl: "./new-project-modal.view.html",
  styleUrls: ["./new-project-modal.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewProjectModalView implements OnDestroy {
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;

  protected isUserTemplateUploadAvailable = false;
  protected isUploadTemplateModalVisible = false;

  protected isSidebarVisible = true;

  protected bookFormatOptions: SidebarSectionOption[] = [
    // {
    //   value: "a4",
    //   width: 210,
    //   height: 297,
    //   title: $localize`:@@home.new-project-modal.sidebar-section.item.book.format-a4:`,
    //   iconSrc: "book-format.svg",
    // },
    // {
    //   value: "a5",
    //   width: 148,
    //   height: 210,
    //   title: $localize`:@@home.new-project-modal.sidebar-section.item.book.format-a5:`,
    //   iconSrc: "book-format.svg",
    // },
    // {
    //   value: "custom",
    //   width: 148,
    //   height: 148,
    //   title: $localize`:@@home.new-project-modal.sidebar-section.item.book.format-custom:`,
    //   iconSrc: "book-format.svg",
    // },
  ];

  protected corporateMaterialOptions: SidebarSectionOption[] = [
    // {
    //   value: "letter",
    //   title: $localize`:@@home.new-project-modal.sidebar-section.item.corporate-material.letter:`,
    //   iconSrc: "book-format.svg",
    // },
    // {
    //   value: "report",
    //   title: $localize`:@@home.new-project-modal.sidebar-section.item.corporate-material.report:`,
    //   iconSrc: "book-format.svg",
    // },
    // {
    //   value: "contract",
    //   title: $localize`:@@home.new-project-modal.sidebar-section.item.corporate-material.contract:`,
    //   iconSrc: "book-format.svg",
    // },
  ];

  protected scientificPublicationOptions: SidebarSectionOption[] = [];

  protected user!: User;
  protected book!: Book;

  private sub = new Subscription();

  constructor(
    private readonly routerService: RouterService,
    userStore: UserStore,
    bookStore: BooksStore,
    formatStore: FormatStore,
    @Inject("ConfigService")
    configService: ConfigService,
    private readonly cdr: ChangeDetectorRef,
    private readonly formatService: FormatService,
    private readonly loadingService: LoadingService,
  ) {
    this.sub.add(
      userStore
        .getUserObservable()
        .pipe(filterUndefined())
        .subscribe((user) => {
          this.user = user;
        }),
    );

    this.sub.add(
      bookStore
        .getModalBookObservable()
        .pipe(filterUndefined())
        .subscribe((b) => {
          this.isSidebarVisible = true;
          this.book = b;

          if (this.book?.coverId) {
            this.isSidebarVisible = false;
          }

          // cdr.detectChanges();
        }),
    );

    this.sub.add(
      bookStore
        .getModalBookObservable()
        .pipe(filterUndefined())
        .subscribe((b) => {
          this.isSidebarVisible = true;
          this.book = b;

          if (this.book?.coverId) {
            this.isSidebarVisible = false;
          }

          // cdr.detectChanges();
        }),
    );

    this.sub.add(
      formatStore
        .getFormatsObservable()
        .pipe(filterUndefined())
        .subscribe((formats) => {
          this.bookFormatOptions = this.formatService.prepareFormatOptions(formats, "book");
        }),
    );

    this.isUserTemplateUploadAvailable = configService.getConfig().company.flags.isUserTemplateUploadAvailable;
  }

  async ngAfterViewInit() {
    // this.loadingService.startLoading({ fullPage: true });
    // await this.formatService.loadFormats();
    // this.loadingService.stopLoading();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected showUploadTemplateModal() {
    this.isUploadTemplateModalVisible = true;
  }

  protected closeUploadTemplateModal() {
    this.isUploadTemplateModalVisible = false;
  }

  protected async onCloseClick() {
    this.routerService.closeModal();
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }

  @HostListener("window:keydown", ["$event"])
  protected handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.onCloseClick();
    }
  }
}
