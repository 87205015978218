import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ImageObject, ObjectsAlignment } from "@metranpage/book-data";

@Component({
  selector: "m-cover-image-object-settings",
  templateUrl: "./cover-image-object-settings.component.html",
  styleUrls: ["./cover-image-object-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverImageObjectSettingsComponent {
  @Input() currentObject!: ImageObject;

  @Output() align = new EventEmitter<ObjectsAlignment>();

  onAlign(alignment: ObjectsAlignment) {
    this.align.emit(alignment);
  }
}
