import { CommonModule, NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

export type InfoBlockActionData = { controlName: string; value: number | string };
export type InfoBlockTextData = { text: number | string; action?: InfoBlockActionData };

export type InfoBlockData = {
  id?: number;
  textData: InfoBlockTextData[];
};

@Component({
  selector: 'm-info-block',
  template: `
    <div class="label" *ngFor="let label of labels; trackBy: trackByLabel" [ngClass]="getCssClassList()">
      <m-icon src="info.svg" [color]="getIconColor()" size="s"></m-icon>
      <div>
        <span
          *ngFor="let t of label.textData; trackBy: trackByTextData"
          [ngClass]="{ 'action-text': t.action }"
          (click)="actionClick(t.action)"
        >
          {{ t.text }}
        </span>
      </div>
    </div>
  `,
  styles: [
    `
      @import '../mixins';

      :host {
        @include text;

        display: none;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        margin-top: 5px;
        color: var(--color-font-running-title-info);
        font-size: var(--font-size-xxs-plus);
        font-weight: var(--font-weight-em);
        line-height: 130%;
        text-align: left;
        gap: 8px;
        user-select: none;
      }

      .label {
        display: flex;
        align-items: center;
        gap: 8px;

        .action-text {
          text-decoration: underline;
          cursor: pointer;
        }

        &.type-warning {
          color: var(--color-font-running-title-info);
        }

        &.type-error {
          color: var(--color-error-text);
        }
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, NgClass, IconComponent],
})
export class InfoBlockComponent {
  @Input()
  type: 'warning' | 'error' | 'none' = 'none';
  @Input()
  labels: InfoBlockData[] = [];

  @Output()
  onActionClick = new EventEmitter<InfoBlockActionData>();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labels.currentValue.length > 0) {
      this.elementRef.nativeElement.setAttribute('style', 'display: flex');
    } else {
      this.elementRef.nativeElement.setAttribute('style', 'display: none');
    }
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    if (this.type !== 'none') {
      result.push(`type-${this.type}`);
    }

    return result;
  }

  protected getIconColor() {
    let color = '--color-font-main';
    if (this.type === 'warning') {
      color = '--color-font-running-title-info';
    }
    if (this.type === 'error') {
      color = '--color-error-text';
    }
    return color;
  }

  trackByLabel(index: number, label: InfoBlockData) {
    return label.id;
  }

  trackByTextData(index: number, textData: InfoBlockTextData) {
    return index;
  }

  protected actionClick(actionData: InfoBlockActionData | undefined) {
    if (!actionData) {
      return;
    }
    this.onActionClick.emit(actionData);
  }
}
