import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BookRouterService, BookService, BooksStore } from "@metranpage/book";
import { Book } from "@metranpage/book-data";
import { LoadingService, filterUndefined } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { ConfigService, Social } from "@metranpage/core-interfaces";
import { OnboardingService } from "@metranpage/onboarding";
import { PricingService } from "@metranpage/pricing";
import { ActiveSubscription, Tariff } from "@metranpage/pricing-data";
import { User, UserStore } from "@metranpage/user-data";
import { Observable, Subscription, map } from "rxjs";
import { CreateProjectEvent, HomeService } from "../../services/home.service";

@Component({
  selector: "m-home-page",
  templateUrl: "./home.page.html",
  styleUrls: ["./home.page.scss"],
})
export class HomePage implements OnInit {
  protected user?: User;

  projects: Book[] = [];
  projects$: Observable<Book[]>;

  onboardingStarted = false;

  protected activeSubscription?: ActiveSubscription;
  protected higherTariff?: Tariff;

  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;

  sub: Subscription = new Subscription();

  protected isChangeTariffModalVisible = false;

  protected socials: Social[] = [];

  protected isNewProjectModalVisible = true;

  constructor(
    private readonly onboardingService: OnboardingService,
    private readonly pricingService: PricingService,
    private readonly bookRouterService: BookRouterService,
    private readonly bookService: BookService,
    private readonly booksStore: BooksStore,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly router: Router,
    @Inject("ConfigService")
    readonly configService: ConfigService,
    readonly userStore: UserStore,
    readonly homeService: HomeService,
  ) {
    this.sub.add(
      userStore
        .getUserObservable()
        .pipe(filterUndefined())
        .subscribe((user) => {
          this.user = user;
        }),
    );

    this.projects$ = booksStore.getBooksObservable().pipe(map((bl) => bl.filter((b) => b.isVisibleToUser)));
    this.sub.add(
      this.projects$.subscribe((books) => {
        this.projects = books;
      }),
    );

    this.sub.add(
      this.onboardingService.onStartOnboarding$.pipe().subscribe(() => {
        this.startOnboarding(true);
      }),
    );

    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.activeSubscription = activeSubscription;
        this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
        this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;

        this.getHigherTariff();
      }),
    );

    this.socials = configService.getConfig().company.socials || [];

    this.sub.add(
      homeService.createProjectEvent$.pipe(filterUndefined()).subscribe((event) => {
        homeService.resetNewProjectModal();
        this.onAddNewProjectClick(event);
      }),
    );
  }

  async ngOnInit() {
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.bookService.updateBooks();
    this.loadingService.stopLoading();
    if (result === "error") {
      this.notificationService.error($localize`:@@books.error.cant-load-books:`);
    }

    this.startOnboarding();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  startOnboarding(showForced = false) {
    const url = this.router.routerState.snapshot.url.replaceAll("/", "");
    if ((!this.onboardingStarted || showForced) && url === "") {
      this.onboardingStarted = true;
      this.onboardingService.startOnboarding("home-page", showForced);
    }
  }

  protected async onAddNewProjectClick(event: CreateProjectEvent) {
    if (this.projects.length >= (this.activeSubscription?.tariff.activeProjects || 1)) {
      this.isChangeTariffModalVisible = true;
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const createBookResult = await this.bookService.createBook();
    this.loadingService.stopLoading();

    if (createBookResult.status === "success") {
      if (event.projectType === "cover") {
        await this.goToNewBookCover(createBookResult.book!);
      } else if (event.projectType === "book") {
        this.bookRouterService.navigateToBookEditStep(createBookResult.book!, "templates", "new");
      }
    } else {
      this.notificationService.error($localize`:@@books.error.cant-create-book:`);
    }
  }

  private async goToNewBookCover(book: Book) {
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.bookService.setBookSizeAndType(book!, {
      width: book.bookSettings!.width,
      height: book.bookSettings!.height,
      exportPrint: true,
      exportEpub: false,
    });
    this.loadingService.stopLoading();

    if (result === "success") {
      this.bookRouterService.showModal(book, "book-data-before-cover", "new");
    } else {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
    }
  }

  protected onClickChangeTariffModal() {
    this.router.navigate(["payments", "subscription"]);
  }

  protected onCloseChangeTariffModal() {
    this.isChangeTariffModalVisible = false;
  }

  protected async getHigherTariff() {
    this.higherTariff = await this.pricingService.getHigherTariff();
  }

  protected isSettingsAvailable() {
    const book = this.booksStore.getActiveBook();
    return (this.hasPaidTariff || this.hasTrialPeriod) && !!book?.bookSettings?.isEditable;
  }

  protected getInfoModalTitle() {
    return this.bookService.getInfoModalTitle(this.higherTariff);
  }

  protected getInfoModalText() {
    return this.bookService.getInfoModalText(this.activeSubscription, this.higherTariff);
  }
}
