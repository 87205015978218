import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { ThemeService } from "@metranpage/theme";
import { Subject, takeUntil } from "rxjs";
import { ImageSize } from "../../models/image-generation";

export type ImageProportionValue = ImageSize & {
  hp: number;
  wp: number;
};

@Component({
  selector: "m-image-proportion-selector",
  templateUrl: "./image-proportion-selector.view.html",
  styleUrls: ["./image-proportion-selector.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageProportionSelectorView),
      multi: true,
    },
  ],
})
export class ImageProportionSelectorView {
  private onTouched = () => {};
  private onChange = (_: any) => {};

  protected value: ImageSize = { width: 0, height: 0 };
  protected selectedProportion: ImageProportionValue = { width: 0, height: 0, hp: 0, wp: 0 };
  protected initialSize: ImageSize = { width: 0, height: 0 };
  protected orientation: "hor" | "ver" = "ver";

  protected baseSize = 512;

  protected orientationIconSrc = "";

  protected items: ImageProportionValue[] = [
    { width: 0, height: 0, hp: 0, wp: 0 },
    { width: 512, height: 512, hp: 1, wp: 1 },
    { width: 512, height: 680, hp: 4, wp: 3 },
    { width: 512, height: 920, hp: 16, wp: 9 },
  ];

  protected isDisabled = false;

  private destroyed$ = new Subject<void>();

  constructor(
    private readonly themeService: ThemeService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    themeService.changeEvents$.pipe(takeUntil(this.destroyed$)).subscribe((theme) => {
      this.getOrientation();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  writeValue(value: ImageSize): void {
    this.value = value;
    this.initialSize = value;
    this.selectedProportion = { width: 0, height: 0, wp: 0, hp: 0 };

    this.getOrientation();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onSelect(proportion: ImageProportionValue) {
    this.selectedProportion = proportion;

    let width = this.initialSize.width;
    let height = this.initialSize.height;

    if (this.selectedProportion.wp !== 0 && this.selectedProportion.hp !== 0) {
      width = this.selectedProportion.width;
      height = this.selectedProportion.height;
      if (this.selectedProportion.wp > this.selectedProportion.hp) {
        width = this.selectedProportion.height;
        height = this.selectedProportion.width;
      }
    }

    this.value = {
      width,
      height,
    };

    this.onChange(this.value);
  }

  onChangeOrientationClick() {
    const iw = this.initialSize.width;
    const ih = this.initialSize.height;
    this.initialSize = {
      width: ih,
      height: iw,
    };

    const w = this.value.width;
    const h = this.value.height;
    this.value = {
      width: h,
      height: w,
    };

    for (const i of this.items) {
      const wp = i.wp;
      const hp = i.hp;
      i.wp = hp;
      i.hp = wp;
    }

    this.updateOrientation();

    this.onChange(this.value);
  }

  updateOrientation() {
    if (this.orientation === "hor") {
      this.orientation = "ver";
    } else {
      this.orientation = "hor";
    }

    this.updateOrientationIconSrc();
  }

  getOrientation() {
    if (this.value.width > this.value.height) {
      this.orientation = "hor";
    } else {
      this.orientation = "ver";
    }

    this.updateOrientationIconSrc();
  }

  updateOrientationIconSrc() {
    const theme = this.themeService.getTheme();
    this.orientationIconSrc = `format-orientation-${this.orientation}-${theme}.svg`;

    this.cdr.markForCheck();
  }

  getText(value: ImageProportionValue) {
    if (value.wp === 0 || value.hp === 0) {
      return "Auto";
    }
    return `${value.wp}:${value.hp}`;
  }

  isSelected(value: ImageProportionValue) {
    return this.selectedProportion.wp === value.wp && this.selectedProportion.hp === value.hp;
  }

  private roundToNumber(number: number, numberToRound = 10) {
    return number % numberToRound ? number + numberToRound - (number % numberToRound) : number;
  }
}
