import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { slideInOutVertical } from "@metranpage/components";
import { CommunityDataService, PublishedObjectsDataSourceType } from "../community-data.service";
import { User } from "@metranpage/user-data";

@Component({
  selector: "m-published-object-preview",
  templateUrl: "./published-object-preview.view.html",
  styleUrls: ["./published-object-preview.view.scss"],
  animations: [slideInOutVertical],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishedObjectPreview {
  @Input()
  publishedObject!: PublishedObjectsDataSourceType;
  @Input()
  objectType!: "images" | "covers";

  @Output()
  onLike = new EventEmitter();

  protected imageUrl!: string;
  protected author: User | undefined;

  constructor(
    private readonly communityDataService: CommunityDataService,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.imageUrl && changes.publishedObject?.currentValue) {
      const url = this.getUrlForImage();
      if (!url) {
        return
      }
      this.imageUrl = url;

      this.author = this.getAuthor();

      // this is inside if, so, the image wont redraw and trigger animation
      this.cdr.detectChanges();
    }
  }

  protected getUrlForImage() {
    // return this.generatedImageService.getUrlForImage(
    //   this.publishedImage.imageGenerationId,
    //   this.publishedImage.imageUrl,
    //   true,
    // );
    return this.communityDataService.getImageUrlForObject(this.objectType, this.publishedObject.id, true);
  }

  protected getAuthor() {
    return this.communityDataService.getAuthorForObject(this.objectType, this.publishedObject.id);
  }
}
