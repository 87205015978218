<div class="container">
  <m-account-bank-card [newCard]="true" (onAddNewCardClick)="onAddNewCardClick()" />
  <m-account-bank-card
    *ngFor="let cardData of bankCards; trackBy: trackByCardId"
    [cardData]="cardData"
    [isActive]="isCardActive(cardData)"
    (onSetCardForPaymentClick)="onSetCardForPaymentClick($event)"
    (onShowDeleteCardModal)="onShowDeleteCardModal($event)"
  />
  <m-account-bank-card *ngFor="let emptyCardData of emptyBankCards" />
</div>

<m-modal
  *ngIf="isDeleteCardModalVisible"
  position="center"
  [closeButtonVisible]="false"
  (close)="onCloseDeleteCardModal()"
>
  <div class="delete-modal">
    <span i18n="@@user.profile.tariff-and-payments.delete-card-confirmation">
      user.profile.main.delete-card-confirmation
    </span>

    <div class="buttons-modal">
      <m-button
        button-style="secondary"
        size="full-width"
        (onClick)="onCloseDeleteCardModal()"
        i18n="@@dialog.cancel-button"
      >
        dialog.cancel-button
      </m-button>
      <m-button button-style="primary" (onClick)="onDeleteCardClick()" i18n="@@dialog.delete-button">
        dialog.delete-button
      </m-button>
    </div>
  </div>
</m-modal>
