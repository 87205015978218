<div class="wrapper">
  <div class="section main">
    <div class="header-wrapper">
      <div class="caption">
        <m-new-mark>
          <span text-left i18n="@@cover-editor.object.create.header.caption">
            cover-editor.object.create.header.caption
          </span>
        </m-new-mark>
      </div>
      <div class="text" i18n="@@cover-editor.object.create.header.text">cover-editor.object.create.header.text</div>
    </div>
    <div class="buttons-wrapper">
      <div class="row equal-size">
        <div class="button" (click)="createCoverObject(CoverObjectType.Text)">
          <m-ng-icon [size]="32" src="/assets/icons/text-01.svg"></m-ng-icon>
          <div class="text" i18n="@@cover-editor.object.create.button.text">cover-editor.object.create.button.text</div>
        </div>
        <div class="button" (click)="showShapesOverlay(true)">
          <m-ng-icon [size]="32" src="/assets/icons/shape-01.svg"></m-ng-icon>
          <div class="text" i18n="@@cover-editor.object.create.button.object">
            cover-editor.object.create.button.object
          </div>
        </div>
      </div>
      <div class="row big" *ngIf="isImagesGenerationAvailable">
        <div class="button big" (click)="showImageGenerator()">
          <m-ng-icon [size]="48" src="/assets/icons/image-05.svg"></m-ng-icon>
          <m-localized-text
            class="text"
            i18n-text="@@cover-editor.object.create.button.image-ai"
            text="cover-editor.object.create.button.image-ai"
          ></m-localized-text>
        </div>
      </div>
      <div class="row">
        <div class="button" (click)="fileUpload.openFileSelector()">
          <m-ng-icon [size]="32" src="/assets/icons/image-06.svg"></m-ng-icon>
          <m-localized-text
            class="text"
            i18n-text="@@cover-editor.object.create.button.image-upload"
            text="cover-editor.object.create.button.image-upload"
          ></m-localized-text>
        </div>
      </div>
    </div>
  </div>
  <div class="section bottom">
    <m-button
      text-align="space-between"
      icon-position="right"
      icon="chevron-right-01.svg"
      button-style="secondary"
      size="full-width"
      (onClick)="showTemplatesHandle()"
    >
      <m-new-mark>
        <span text-left i18n="@@cover-editor.object.create.button.templates">
          cover-editor.object.create.button.templates
        </span>
      </m-new-mark>
    </m-button>
  </div>

  <m-cover-conceptual-assistant-generation (showConceptualAssistantMenu)="onShowConceptualAssistantMenu()" />

  <div class="shape-overlay" *ngIf="isShapeOverlayVisible">
    <div class="header-wrapper">
      <div class="caption" i18n="@@cover-editor.object.create.object.header.caption">
        cover-editor.object.create.object.header.caption
      </div>
      <m-ng-icon class="close-icon" src="cross-02.svg" [size]="20" (click)="showShapesOverlay(false)" />
    </div>
    <div class="content-wrapper">
      <ng-scrollbar class="scroll-container">
        <ng-container *ngIf="!predefinedSvgObjectsCategory">
          <div class="section" *ngFor="let category of predefinedSvgObjects">
            <div class="caption-wrapper">
              <div class="caption">
                <m-new-mark>
                  <span text-left>
                    {{ getCategoryCaption(category.category) }}
                  </span>
                </m-new-mark>
              </div>
              <div class="expand-collapse-wrapper" (click)="predefinedSvgObjectsCategory = category">
                <div class="text" i18n="@@cover-editor.object.create.object.all">
                  cover-editor.object.create.object.all
                </div>
                <div class="icon">
                  <m-ng-icon [size]="20" src="/assets/icons/chevron-right-02.svg"></m-ng-icon>
                </div>
              </div>
            </div>

            <div class="shapes-wrapper one-line">
              <ng-container *ngIf="category.category === SvgObjectCategory.BasicShapes">
                <div class="icon-wrapper">
                  <m-ng-icon
                    [size]="77"
                    src="/assets/icons/cover/svg-shapes/rectangle-i-01.svg"
                    (click)="isShapeOverlayVisible = false; createCoverObject(CoverObjectType.Rectangle)"
                  ></m-ng-icon>
                </div>
                <div class="icon-wrapper">
                  <m-ng-icon
                    [size]="77"
                    src="/assets/icons/cover/svg-shapes/ellipse-i-01.svg"
                    (click)="isShapeOverlayVisible = false; createCoverObject(CoverObjectType.Ellipse)"
                  ></m-ng-icon>
                </div>
              </ng-container>
              <div class="icon-wrapper" *ngFor="let svg of category.urls">
                <m-ng-icon
                  [size]="77"
                  [src]="svg.iconUrl"
                  (click)="isShapeOverlayVisible = false; createCoverObject(CoverObjectType.SVG, svg.objectUrl)"
                ></m-ng-icon>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="predefinedSvgObjectsCategory">
          <div class="section">
            <div class="caption-wrapper">
              <div class="expand-collapse-wrapper" (click)="predefinedSvgObjectsCategory = undefined">
                <div class="icon">
                  <m-ng-icon [size]="20" src="/assets/icons/chevron-left-02.svg"></m-ng-icon>
                </div>
                <div class="text" i18n="@@cover-editor.object.create.object.back">
                  cover-editor.object.create.object.back
                </div>
              </div>
              <div class="caption">
                {{ getCategoryCaption(predefinedSvgObjectsCategory.category) }}
              </div>
            </div>

            <div class="shapes-wrapper">
              <ng-container *ngIf="predefinedSvgObjectsCategory.category === SvgObjectCategory.BasicShapes">
                <div class="icon-wrapper">
                  <m-ng-icon
                    [size]="77"
                    src="/assets/icons/cover/svg-shapes/rectangle-i-01.svg"
                    (click)="isShapeOverlayVisible = false; createCoverObject(CoverObjectType.Rectangle)"
                  ></m-ng-icon>
                </div>
                <div class="icon-wrapper">
                  <m-ng-icon
                    [size]="77"
                    src="/assets/icons/cover/svg-shapes/ellipse-i-01.svg"
                    (click)="isShapeOverlayVisible = false; createCoverObject(CoverObjectType.Ellipse)"
                  ></m-ng-icon>
                </div>
              </ng-container>
              <div class="icon-wrapper" *ngFor="let svg of predefinedSvgObjectsCategory.urls">
                <m-ng-icon
                  [size]="77"
                  [src]="svg.iconUrl"
                  (click)="isShapeOverlayVisible = false; createCoverObject(CoverObjectType.SVG, svg.objectUrl)"
                ></m-ng-icon>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-scrollbar>
    </div>
  </div>

  <div class="image-generator-host" *ngIf="isImageGeneratorVisible">
    <m-image-generation-view
      [isModal]="true"
      (onClose)="hideImageGenerator()"
      (onSelectedImage)="selectGeneratedImage($event)"
    ></m-image-generation-view>
  </div>
</div>

<m-file-drop #fileUpload [accept]="imageFileTypes" [multiple]="false" (dropped)="uploadImage($event)"></m-file-drop>
