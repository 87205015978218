import { ElementRef, Injectable } from "@angular/core";
import { Color } from "@metranpage/book-data";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CoverUiService {
  private eyedropper = new Subject<(color: Color) => void>();
  eyedropper$ = this.eyedropper.asObservable();

  private colorContextMenu = new Subject<ElementRef>();
  colorContextMenu$ = this.colorContextMenu.asObservable();

  private colorPicker = new Subject<ElementRef>();
  colorPicker$ = this.colorPicker.asObservable();

  showEyeDropper(callback: (color: Color) => void) {
    this.eyedropper.next(callback);
  }

  showColorContextMenu(element: ElementRef) {
    this.colorContextMenu.next(element);
  }

  showColorPicker(element: ElementRef) {
    this.colorPicker.next(element);
  }
}
