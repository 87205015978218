<m-modal-backdrop [closeButtonVisible]="true" [closeOnBackDropClick]="true" (close)="onCloseClick()">
  <div class="container" @fade-scale>
    <div class="plate header-wrapper">
      <div class="cell left-wrapper">
        <img src="/assets/img/coins-01.png" />
        <div>
          <div class="header" i18n="@@books.preview.payments-modal.not-enough-tokens">
            books.preview.payments-modal.not-enough-tokens
          </div>
          <div class="text">
            <span *ngIf="!isImageGeneration" i18n="@@books.preview.payments-modal.header-01">
              books.preview.payments-modal.header-01
            </span>
            <span *ngIf="isImageGeneration">
              {{ getImageGenerationText() }}
            </span>
            <span class="bold">&nbsp;{{ bookPriceText }}</span>
            <span>.</span>
            <br />
            <span i18n="@@books.preview.payments-modal.your-balance">books.preview.payments-modal.your-balance</span>
            <span class="bold">&nbsp;{{ userCreditsText }}</span>
            <span>.</span>
          </div>
        </div>
      </div>
      <div class="cell right-wrapper">
        <m-localized-text class="text" [text]="upgradeRecommendations"></m-localized-text>
      </div>
      <!-- <div class="button-close" (click)="close.emit()">
      <svg-icon class="icon-close" src="/assets/icons/cross.svg"></svg-icon>
    </div> -->
    </div>

    <m-tariff-grid
      [tariffs]="tariffs"
      cardAppearance="compact"
      [activeSubscription]="activeSubscription"
      (buySubscription)="buySubscription.emit($event)"
    ></m-tariff-grid>

    <div *ngIf="hasGoldCredits()" class="plate footer-wrapper">
      <div class="cell left-wrapper">
        <img src="/assets/img/gold-credit-01.png" />
        <div>
          <div class="header" i18n="@@books.preview.payments-modal.use-gold-token-01">
            books.preview.payments-modal.use-gold-token-01
          </div>
          <div class="text">
            <span i18n="@@books.preview.payments-modal.what-is-gold-credits-text">
              books.preview.payments-modal.what-is-gold-credits-text
            </span>
          </div>
        </div>
      </div>
      <div class="cell right-wrapper">
        <m-button
          class="button"
          button-style="primary"
          color-theme="gold"
          (click)="payWithGoldCredits.emit()"
          i18n="@@books.preview.payments-modal.use-gold-token-01"
        >
          books.preview.payments-modal.use-gold-token-01
        </m-button>
      </div>
    </div>
  </div>
</m-modal-backdrop>
