import { PublishedObjectsDataSource } from "./data-source";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "@metranpage/user-data";
import { BookCover } from "@metranpage/book-data";
import { CoverApi } from "@metranpage/book";

@Injectable({
  providedIn: "root",
})
export class BookCoversDataSource extends PublishedObjectsDataSource<BookCover> {
  private items$: BehaviorSubject<BookCover[]>;
  private items: BookCover[] = [];

  private isInitialized = false;

  constructor(private readonly bookCoversApi: CoverApi) {
    super("covers");
    this.items$ = new BehaviorSubject<BookCover[]>([]);
  }

  async initItems() {
    if (!this.isInitialized) {
      this.items$ = new BehaviorSubject<BookCover[]>([]);

      this.page = 1;

      const coversData = await this.bookCoversApi.getPublishedCoversPaginated(this.page);
      this.pageCount = coversData.pageCount;

      this.items = coversData.items;
      this.items$.next(this.items);
      this.isInitialized = true;
    }
  }

  override async loadNextPage() {
    this.page++;
    if (this.page >= this.pageCount) {
      return;
    }
    const coversData = await this.bookCoversApi.getPublishedCoversPaginated(this.page);
    this.pageCount = coversData.pageCount;

    this.items = this.items.concat(coversData.items); // TODO there can be item duplicates
    this.items$.next(this.items);
  }

  override getItems(): Observable<BookCover[]> {
    this.initItems();
    return this.items$;
  }

  override getObject(id: number): BookCover | undefined {
    return this.items.find((ig) => ig.id === id);
  }

  override getImageUrlForObject(id: number, isThumbnail: boolean): string | undefined {
    if (isThumbnail) {
      return this.bookCoversApi.getUrlForCoverThumbnailByCoverId(id);
    }
    return this.bookCoversApi.getUrlForCoverByCoverId(id);
  }

  override getAuthorForObject(id: number): User | undefined {
    const cover = this.items.find((ig) => ig.id === id);
    if (!cover) {
      return undefined;
    }
    return cover.author as User;
  }
}
