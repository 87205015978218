import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  Book,
  CoverObject,
  CoverObjectType,
  ImageObject,
  ShapeObject,
  SvgObject,
  TextObject,
} from "@metranpage/book-data";
import { LoadingService } from "@metranpage/core";
import { User } from "@metranpage/user-data";
import { Observable, filter, map, startWith, tap } from "rxjs";
import { CoverService } from "../../services/cover/cover.service";

@Component({
  selector: "m-cover-object-list",
  templateUrl: "./cover-object-list.component.html",
  styleUrls: ["./cover-object-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverObjectListComponent implements OnChanges, OnInit {
  @Input() objects!: CoverObject[];
  @Input() currentObject?: CoverObject;
  @Input() selectedObjects: CoverObject[] = [];
  @Input() book!: Book;
  @Input() user!: User;

  @Output() current: EventEmitter<CoverObject[]> = new EventEmitter<CoverObject[]>();
  @Output() reorder: EventEmitter<CoverObject[]> = new EventEmitter<CoverObject[]>();
  @Output() rename: EventEmitter<CoverObject> = new EventEmitter<CoverObject>();
  @Output() update: EventEmitter<CoverObject> = new EventEmitter<CoverObject>();
  @Output() delete = new EventEmitter<CoverObject>();
  @Output() clone = new EventEmitter<CoverObject>();

  @ViewChild("fileUpload") fileUploadElement!: ElementRef;

  CoverObjectType = CoverObjectType;

  reversedObjects!: CoverObject[];

  values$!: Observable<Partial<CoverObject>>;

  isShiftPressed = false;

  readonly form = new FormGroup({
    opacity: new FormControl<number | undefined>(undefined, [
      Validators.pattern("^[0-9.]*$"),
      Validators.min(0),
      Validators.max(100),
    ]),
  });

  constructor(
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _coverService: CoverService,
    private readonly _loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.values$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      filter(() => this.form.valid),
      map((v) => <Partial<CoverObject>>v),
      tap((v) => {
        if (!this.form.dirty || !this.form.valid || !this.currentObject) {
          return;
        }
        Object.assign(this.currentObject, v);
        this.update.emit(this.currentObject);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.objects) {
      this.reversedObjects = this.objects.slice().reverse();
    }

    if (changes.currentObject) {
      this.form.reset();
      if (this.currentObject) {
        const patchValue = {
          opacity: this.currentObject.opacity ?? 100,
        };
        this.form.patchValue(patchValue);
      }
    }
  }

  getObjectTypeIconPath(object: CoverObject): string {
    if (object instanceof TextObject) {
      return "/assets/icons/text-01.svg";
    }
    if (object instanceof ImageObject) {
      return "/assets/icons/image-04.svg";
    }
    /* if (object instanceof SvgObject) {
      return object.imageUrl!;
    } */
    if (object instanceof ShapeObject) {
      return "/assets/icons/shape-01.svg";
    }
    return "/assets/icons/text-01.svg";
  }

  get visibilityIcon(): string {
    if (this.currentObject!.isVisible) {
      return "/assets/icons/eye.svg";
    }
    return "/assets/icons/eye-close.svg";
  }

  isSelected(object: CoverObject): boolean {
    return this.selectedObjects.some((v) => v === object);
  }

  onObjectClick(object: CoverObject, event: MouseEvent) {
    let objects = [...this.selectedObjects];
    if (!event.shiftKey) {
      this.current.emit([object]);
      return;
    }
    if (objects.some((v) => v === object)) {
      objects = objects.filter((v) => v !== object);
    } else {
      objects.push(object);
    }
    this.current.emit(objects);
  }

  reorderCoverObjects(event: CdkDragDrop<CoverObject[]>) {
    moveItemInArray(this.reversedObjects, event.previousIndex, event.currentIndex);
    moveItemInArray(
      this.objects,
      this.objects.length - event.previousIndex - 1,
      this.objects.length - event.currentIndex - 1,
    );
    this.reorder.emit(this.objects);
  }

  validateObjectName(name: string | undefined | null): boolean {
    const value = name?.trim();
    if (value?.length) {
      return true;
    }
    return false;
  }

  onNameChange(object: CoverObject, event: string) {
    if (this.validateObjectName(object.name)) {
      this.rename.emit(object);
    }
  }

  onNameBlur(object: CoverObject) {
    if (!this.validateObjectName(object.name)) {
      object.name = object.getDefaultName();
      this.rename.emit(object);
    }
  }

  onNameFocus(object: CoverObject, event: FocusEvent) {
    if (object !== this.currentObject && !this.isShiftPressed) {
      this.current.emit([object]);
    }
  }

  get lockIcon(): string {
    return `/assets/icons/${this.currentObject?.isLocked ? "locked-01.svg" : "unlocked-01.svg"}`;
  }

  deleteObject(object: CoverObject) {
    this.delete.emit(object);
  }

  toggleLockObject(object: CoverObject) {
    this.currentObject!.isLocked = !(this.currentObject?.isLocked ?? false);
    this.update.emit(object);
  }

  toggleObjectVisibility(object: CoverObject) {
    this.currentObject!.isVisible = !(this.currentObject?.isVisible ?? true);
    this.update.emit(object);
  }

  cloneObject(object: CoverObject) {
    this.clone.emit(object);
  }

  @HostListener("keydown", ["$event"])
  onKeydown(event: KeyboardEvent) {
    if (event.key === "Shift") {
      this.isShiftPressed = true;
    }
  }

  @HostListener("keyup", ["$event"])
  onKeyup(event: KeyboardEvent) {
    if (event.key === "Shift") {
      this.isShiftPressed = false;
    }
  }
}
