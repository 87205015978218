<div class="container">
  <div class="row">
    <span class="column" i18n="@@user.profile.tariff-and-payments.payment-history.date">
      user.profile.tariff-and-payments.payment-history.date
    </span>
    <span class="column column-description" i18n="@@user.profile.tariff-and-payments.payment-history.description">
      user.profile.tariff-and-payments.payment-history.description
    </span>
    <span class="column column-details" i18n="@@user.profile.tariff-and-payments.payment-history.details">
      user.profile.tariff-and-payments.payment-history.details
    </span>
    <span class="column column-price" i18n="@@user.profile.tariff-and-payments.payment-history.amount">
      user.profile.tariff-and-payments.payment-history.amount
    </span>
    <span class="column" i18n="@@user.profile.tariff-and-payments.payment-history.status">
      user.profile.tariff-and-payments.payment-history.status
    </span>
  </div>
  <div class="divider"></div>
  <div class="table">
    <div *ngFor="let order of orders; trackByOrderId" class="row">
      <span class="column">{{ orderDate(order.createdAt) }}</span>
      <span class="column column-description">{{ order.description }}</span>
      <span class="column column-details">{{ orderDetails(order) }}</span>
      <span class="column column-price">{{ priceFormat(order) }}</span>
      <span
        class="column"
        [class.error]="order.status == 'failed'"
        [class.created]="order.status == 'created'"
        [class.success]="order.status == 'success' || order.status == 'completed'"
      >
        {{ orderStatus(order.status) }}
      </span>
    </div>
  </div>
</div>
