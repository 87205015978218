import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { slideInOutVertical } from "@metranpage/components";
import { NotificationsPopUpService } from "@metranpage/core";
import { HomeService } from "../../services/home.service";

export type PromoButtonAction = "create-book" | "image-generation" | "create-cover" | "upgrade-image" | "get-tokens";
export type PromoButton = {
  id: number;
  iconScr: string;
  title: string;
  action: PromoButtonAction;
  hasSoonMark?: boolean;
};

@Component({
  selector: "m-promo-menu-buttons",
  templateUrl: "./promo-menu-buttons.view.html",
  styleUrls: ["./promo-menu-buttons.view.scss"],
  animations: [slideInOutVertical],
})
export class PromoMenuButtonsView {
  constructor(
    private readonly homeService: HomeService,
    private readonly router: Router,
    private readonly notificationService: NotificationsPopUpService,
  ) {}

  protected buttons: PromoButton[] = [
    {
      id: 1,
      iconScr: "/assets/img/promo-image-1.png",
      title: $localize`:@@home.promo-menu-buttons.button.create-book:`,
      action: "create-book",
    },
    {
      id: 2,
      iconScr: "/assets/img/promo-image-2.png",
      title: $localize`:@@home.promo-menu-buttons.button.create-image-generation:`,
      action: "image-generation",
    },
    {
      id: 3,
      iconScr: "/assets/img/promo-image-3.png",
      title: $localize`:@@home.promo-menu-buttons.button.create-cover:`,
      action: "create-cover",
    },
    {
      id: 4,
      iconScr: "/assets/img/promo-image-4.png",
      title: $localize`:@@home.promo-menu-buttons.button.upgrade-image:`,
      action: "upgrade-image",
      hasSoonMark: true,
    },
    {
      id: 5,
      iconScr: "/assets/img/promo-image-5.png",
      title: $localize`:@@home.promo-menu-buttons.button.get-tokens:`,
      action: "get-tokens",
      hasSoonMark: true,
    },
  ];

  trackByButtonId(index: number, button: PromoButton) {
    return button.id;
  }

  protected onClick(action: PromoButtonAction) {
    if (action === "create-book") {
      this.homeService.showNewProjectModal({ projectType: "book" });
      return;
    }
    if (action === "image-generation") {
      this.router.navigate(["image-generation"]);
      return;
    }
    if (action === "create-cover") {
      this.homeService.showNewProjectModal({ projectType: "cover" });
      return;
    }
    if (action === "upgrade-image") {
      this.notificationService.notify({
        content: $localize`:@@soon-text-notification:`,
        type: "success",
        showOptsTimeout: 2,
      });
      return;
    }
    if (action === "get-tokens") {
      this.notificationService.notify({
        content: $localize`:@@soon-text-notification:`,
        type: "success",
        showOptsTimeout: 2,
      });
      return;
    }
  }
}
