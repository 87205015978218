import { User } from "@metranpage/user-data";
import { Expose, Type } from "class-transformer";
import { Book } from "./book";
import { ModerationCoverRequest } from "./moderation-cover-request";

export type TextAlignment = "left" | "right" | "center";
export type GradientType = "linear" | "radial";
export type GradientDirection = "horizontal" | "vertical";
export type ObjectCreationSource = "user" | "template";
export type ObjectRole = "title" | "author" | "subtitle";

export enum CoverObjectType {
  Text = "text",
  Image = "image",
  Rectangle = "rectangle",
  Ellipse = "ellipse",
  SVG = "svg",
}

enum FillType {
  Solid = "solid",
  Gradient = "gradient",
}

export class Color {
  @Expose() r: number;
  @Expose() g: number;
  @Expose() b: number;
  @Expose() a: number;

  constructor(r: number, g: number, b: number, a: number) {
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
  }

  static toCss(color: Color): string {
    return `rgba(${color.r},${color.g},${color.b},${color.a})`;
  }

  toCss(): string {
    return Color.toCss(this);
  }
}

export class ColorStop {
  @Expose() @Type(() => Color) color: Color = new Color(0, 0, 0, 1);
}

export class Gradient {
  @Expose() type: GradientType = "linear";
  @Expose() direction: GradientDirection = "horizontal";
  @Expose() @Type(() => ColorStop) colorStops: ColorStop[] = [];
}

export abstract class Fill {
  @Expose() __type!: FillType;
}

export class SolidFill extends Fill {
  @Expose() @Type(() => Color) color!: Color;

  constructor() {
    super();
    this.__type = FillType.Solid;
    this.color = new Color(0, 0, 0, 1);
  }
}

export class GradientFill extends Fill {
  @Expose() @Type(() => Gradient) gradient!: Gradient;

  constructor() {
    super();
    this.__type = FillType.Gradient;
  }
}

export abstract class CoverObject {
  @Expose() protected __type?: CoverObjectType;
  @Expose() id?: string;
  @Expose() name?: string;

  @Expose() x?: number;
  @Expose() y?: number;

  @Expose() width?: number;
  @Expose() height?: number;

  @Expose() opacity?: number;

  @Expose() zIndex = 0;

  @Expose() rotationAngle?: number;
  @Expose() scaleX?: number;
  @Expose() scaleY?: number;
  @Expose() skewX?: number;
  @Expose() skewY?: number;

  @Expose() isLocked?: boolean;
  @Expose() isVisible = true;

  @Expose() creationSource: ObjectCreationSource = "user";
  @Expose() isNameModifiedByUser = false;

  abstract getDefaultName(): string;

  get role(): { role: ObjectRole; isMappedById: boolean } | undefined {
    if (this.id?.toLocaleLowerCase() === "title") {
      return { role: "title", isMappedById: true };
    }
    if (this.name?.toLocaleLowerCase() === "title") {
      return { role: "title", isMappedById: false };
    }
    if (this.id?.toLocaleLowerCase() === "author") {
      return { role: "author", isMappedById: true };
    }
    if (this.name?.toLocaleLowerCase() === "author") {
      return { role: "author", isMappedById: false };
    }
    if (this.id?.toLocaleLowerCase() === "subtitle") {
      return { role: "subtitle", isMappedById: true };
    }
    if (this.name?.toLocaleLowerCase() === "subtitle") {
      return { role: "subtitle", isMappedById: false };
    }
    return undefined;
  }
}

export class TextObject extends CoverObject {
  @Expose() text?: string;

  @Expose() fontFamily?: string;
  @Expose() fontSize?: number;
  @Expose() lineHeight?: number;
  @Expose() letterSpacing?: number;
  @Expose() textAlign?: TextAlignment;

  @Expose() bold?: boolean;
  @Expose() italic?: boolean;
  @Expose() underline?: boolean;
  @Expose() linethrough?: boolean;

  @Type(() => Fill, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: SolidFill, name: FillType.Solid },
        { value: GradientFill, name: FillType.Gradient },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  fill!: Fill;

  @Type(() => Fill, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: SolidFill, name: FillType.Solid },
        { value: GradientFill, name: FillType.Gradient },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  strokeFill?: Fill;

  @Expose() strokeWidth?: number;

  constructor() {
    super();
    this.__type = CoverObjectType.Text;
  }

  override getDefaultName(): string {
    return "Text";
  }
}

export class ImageObject extends CoverObject {
  @Expose() imageUrl?: string;

  constructor() {
    super();
    this.__type = CoverObjectType.Image;
  }

  override getDefaultName(): string {
    return "Image";
  }
}

export abstract class ShapeObject extends CoverObject {
  @Type(() => Fill, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: SolidFill, name: FillType.Solid },
        { value: GradientFill, name: FillType.Gradient },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  fill!: Fill;

  @Type(() => Fill, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: SolidFill, name: FillType.Solid },
        { value: GradientFill, name: FillType.Gradient },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  strokeFill?: Fill;

  @Expose() strokeWidth?: number;
}

export class RectangleObject extends ShapeObject {
  @Expose() cornerRadius?: number;
  constructor() {
    super();
    this.__type = CoverObjectType.Rectangle;
  }

  override getDefaultName(): string {
    return "Rectangle";
  }
}

export class EllipseObject extends ShapeObject {
  constructor() {
    super();
    this.__type = CoverObjectType.Ellipse;
  }

  override getDefaultName(): string {
    return "Ellipse";
  }
}

export class SvgObject extends ShapeObject {
  @Expose() imageUrl?: string;
  constructor() {
    super();
    this.__type = CoverObjectType.SVG;
  }

  override getDefaultName(): string {
    return "Shape";
  }
}

//////////////////////////////////
// BOOK COVER
//////////////////////////////////

export class BookCover {
  @Expose() id!: number;

  @Expose() width!: number;
  @Expose() height!: number;

  @Expose() @Type(() => Color) backgroundColor?: Color;

  @Expose() isPublic!: boolean;
  @Expose() moderationCoverRequest?: ModerationCoverRequest;

  @Type(() => CoverObject, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: TextObject, name: CoverObjectType.Text },
        { value: ImageObject, name: CoverObjectType.Image },
        { value: RectangleObject, name: CoverObjectType.Rectangle },
        { value: EllipseObject, name: CoverObjectType.Ellipse },
        { value: SvgObject, name: CoverObjectType.SVG },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  objects?: CoverObject[];

  @Expose() book?: Book;
  @Expose() author?: Partial<User>;
}

export type UploadImageResponseDto = {
  name: string;
};

export type UploadGeneratedImageRequestDto = {
  bookId: number;
  generationId: number;
  src: string;
};

export type CoverImageType = "cover-preview" | "cover-fullsize" | "object";

export type ApplyTemplateRequestDto = {
  bookId: number;
  templateId: number;
};

export type ObjectsAlignment = "left" | "right" | "center" | "top" | "bottom" | "middle" | "center-middle";

export enum SvgObjectCategory {
  BasicShapes = 1,
  AgeConstraints = 2,
  Arrows = 3,
  Splashes = 4,
}

export type SvgObjectUrl = {
  iconUrl: string;
  objectUrl: string;
};

export type PredefinedSvgObjects = {
  category: SvgObjectCategory;
  urls: SvgObjectUrl[];
};
