import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Book, BookDataStep } from "@metranpage/book-data";
import { CompanyPrintViewStyle, CompanyStore } from "@metranpage/company";
import { IS_COVER_EDITOR_ENABLED, IS_EDITION_NOTICE_AVAILABLE } from "@metranpage/core";
import { Subscription, timer } from "rxjs";
import { CoverService } from "../../services/cover/cover.service";

@Component({
  selector: "m-book-card",
  templateUrl: "./book-card.view.html",
  styleUrls: ["./book-card.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookCardView implements OnChanges {
  @Input()
  book!: Book;

  @Output()
  onEditClick = new EventEmitter<BookDataStep>();
  @Output()
  onDeleteClick = new EventEmitter<void>();

  protected isBookEditMenuVisible = false;
  private bookEditMenuTimer: Subscription | undefined = undefined;

  coverImageUrl = "";

  displayStyle: CompanyPrintViewStyle = "calculator";

  private sub: Subscription = new Subscription();

  constructor(
    @Inject(IS_EDITION_NOTICE_AVAILABLE) protected readonly isEditionNoticeAvailable: boolean,
    @Inject(IS_COVER_EDITOR_ENABLED) protected readonly isCoverEditorEnabled: boolean,
    private readonly cdr: ChangeDetectorRef,
    private readonly coverService: CoverService,
    private readonly companyStore: CompanyStore,
  ) {
    this.sub.add(
      this.companyStore.getCompanyObservable().subscribe((company) => {
        this.displayStyle = company?.printSettings?.printViewStyle ?? "calculator";
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.book) {
      this.book = changes.book.currentValue;
      this.coverImageUrl = this.coverService.getCoverPreviewImageUrl(this.book.id);
      this.cdr.markForCheck();
    }
  }

  get aspectRatio() {
    return `${this.book.bookSettings?.width}/${this.book.bookSettings?.height}`;
  }

  get isBookDownloadsAvailable() {
    return this.book.bookResults?.isPrintFinalsReady || this.book.bookResults?.isEpubFinalsReady;
  }

  get isPrintDownloadReady() {
    return this.book.exportPrint && this.book.bookResults?.isPrintFinalsReady;
  }

  get isEpubDownloadReady() {
    return this.book.exportEpub && this.book.bookResults?.isEpubFinalsReady;
  }

  get isCoverReady() {
    return !!this.book.coverId;
  }

  formatBookTitle(title: string, limit = 30) {
    if (!title) {
      return;
    }

    title = title.trim();
    if (title.length <= limit) {
      return title;
    }
    title = title.slice(0, limit);
    const lastSpace = title.lastIndexOf(" ");
    if (lastSpace > 0) {
      title = title.slice(0, lastSpace);
    }
    return `${title} …`;
  }

  onShowEditButton() {
    if (this.bookEditMenuTimer) {
      this.bookEditMenuTimer.unsubscribe();
    }

    this.isBookEditMenuVisible = true;
  }

  onCloseEditButton() {
    this.bookEditMenuTimer = timer(1000).subscribe(() => {
      this.isBookEditMenuVisible = false;
      this.cdr.detectChanges();
    });
  }

  navigateToEditMenu() {
    this.onEditClick.emit();
  }

  getAuthor() {
    return `
      ${this.book.authorLastName ?? ""}
      ${this.book.authorFirstName ?? ""}
      ${this.book.authorMiddleName ?? ""}
    `.trim();
  }
}
