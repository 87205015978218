import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CoverObject, Fill, ObjectsAlignment, SolidFill, TextAlignment, TextObject } from "@metranpage/book-data";
import { ColorConverterService, SelectValue } from "@metranpage/components";
import * as _ from "lodash-es";
import { Observable, filter, map, startWith, tap } from "rxjs";
import { CoverUiService } from "../../services/cover/cover-ui.service";

@Component({
  selector: "m-cover-text-object-settings",
  templateUrl: "./cover-text-object-settings.component.html",
  styleUrls: ["./cover-text-object-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverTextObjectSettingsComponent implements OnChanges, OnInit {
  @Input() currentObject!: TextObject;
  @Input() fontFaces?: FontFace[];

  @Output() update = new EventEmitter<CoverObject>();
  @Output() previewFontFamily = new EventEmitter<string>();
  @Output() resetFontFamily = new EventEmitter();
  @Output() align = new EventEmitter<ObjectsAlignment>();

  values$!: Observable<Partial<TextObject>>;
  fontOptions: SelectValue[] = [];

  readonly form = new FormGroup({
    fontSize: new FormControl<number | undefined>(undefined, [Validators.pattern("^[0-9.]*$")]),
    lineHeight: new FormControl<number | undefined>(undefined, [Validators.pattern("^[0-9.]*$")]),
    letterSpacing: new FormControl<number | undefined>(undefined, [Validators.pattern("^-?[0-9.]*$")]),
    fontFamily: new FormControl<string | undefined>(undefined),
  });

  constructor(
    private readonly colorConverter: ColorConverterService,
    private readonly coverUiService: CoverUiService,
  ) {}

  ngOnInit(): void {
    this.values$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      filter(() => this.form.valid),
      map((v) => <Partial<TextObject>>v),
      tap((v) => {
        if (!this.form.dirty || !this.form.valid) {
          return;
        }
        Object.assign(this.currentObject, v);
        this.update.emit(this.currentObject);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fontFaces) {
      //this.fontOptions = this.fontFaces?.map((f) => <SelectValue>{ id: f.family, value: f.family }) ?? [];
      this.fontOptions = _.uniqBy(
        this.fontFaces?.map((f) => <SelectValue>{ id: f.family, value: f.family }) ?? [],
        (v) => {
          return v.id;
        },
      );
    }

    this.form.reset();
    const patchValue = {
      fontSize: this.currentObject.fontSize,
      lineHeight: this.currentObject.lineHeight,
      letterSpacing: this.currentObject.letterSpacing,
      fontFamily: this.currentObject.fontFamily,
    };
    this.form.patchValue(patchValue);
  }

  setTextAlign(alignment: TextAlignment) {
    this.currentObject.textAlign = alignment;
    this.update.emit(this.currentObject);
  }

  updateFill(fill: Fill) {
    this.currentObject.fill = fill;
    this.update.emit(this.currentObject);
  }

  get fillType(): string {
    if (this.currentObject.fill instanceof SolidFill) {
      return $localize`:@@cover-editor.object.settings.color:`;
    }
    return $localize`:@@cover-editor.object.settings.gradient:`;
  }

  toggleTextBold() {
    this.currentObject.bold = !this.currentObject.bold;
    this.update.emit(this.currentObject);
  }

  toggleTextItalic() {
    this.currentObject.italic = !this.currentObject.italic;
    this.update.emit(this.currentObject);
  }

  toggleTextUnderline() {
    this.currentObject.underline = !this.currentObject.underline;
    this.update.emit(this.currentObject);
  }

  onUpdate(object: CoverObject) {
    this.update.emit(object);
  }

  onPreviewFontFamily(fontFamily: string) {
    this.previewFontFamily.emit(fontFamily);
  }

  onResetFontFamily() {
    this.resetFontFamily.emit();
  }

  onAlign(alignment: ObjectsAlignment) {
    this.align.emit(alignment);
  }
}
