import { fabric } from "fabric";

/*const svgRotateIcon = encodeURIComponent(`
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8.17188" cy="8" r="8" fill="white"/>
<path d="M8.05751 6.48207C8.05751 6.53735 8.07811 6.59065 8.1153 6.63156C8.19785 6.72237 8.3384 6.72906 8.42921 6.6465L10.8116 4.48069C10.8203 4.47279 10.8286 4.46447 10.8365 4.45578C10.9741 4.30442 10.963 4.07018 10.8116 3.93259L8.42921 1.76678C8.38831 1.72959 8.33501 1.70898 8.27973 1.70898C8.157 1.70898 8.05751 1.80848 8.05751 1.93121L8.05751 3.65108C5.60291 3.65108 3.61306 5.64093 3.61306 8.09553C3.61306 10.5501 5.60291 12.54 8.05751 12.54C10.5121 12.54 12.502 10.5501 12.502 8.09553C12.502 7.7887 12.2532 7.53997 11.9464 7.53997C11.6396 7.53997 11.3908 7.7887 11.3908 8.09553C11.3908 9.93648 9.89846 11.4289 8.05751 11.4289C6.21656 11.4289 4.72417 9.93648 4.72417 8.09553C4.72417 6.25458 6.21656 4.7622 8.05751 4.7622L8.05751 6.48207Z" fill="#E02379"/>
</svg>
`);*/

const svgRotateIcon = encodeURIComponent(`
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_4637_14083)">
<circle cx="8.17188" cy="8" r="8" fill="white"/>
<path d="M8.05751 6.48207C8.05751 6.53736 8.07811 6.59065 8.1153 6.63156C8.19785 6.72237 8.3384 6.72906 8.42921 6.6465L10.8116 4.48069C10.8203 4.47279 10.8286 4.46447 10.8365 4.45578C10.9741 4.30442 10.963 4.07018 10.8116 3.93259L8.42921 1.76678C8.38831 1.72959 8.33501 1.70898 8.27973 1.70898C8.157 1.70898 8.05751 1.80848 8.05751 1.93121L8.05751 3.65108C5.60291 3.65108 3.61306 5.64093 3.61306 8.09553C3.61306 10.5501 5.60291 12.54 8.05751 12.54C10.5121 12.54 12.502 10.5501 12.502 8.09553C12.502 7.7887 12.2532 7.53997 11.9464 7.53997C11.6396 7.53997 11.3908 7.7887 11.3908 8.09553C11.3908 9.93648 9.89846 11.4289 8.05751 11.4289C6.21656 11.4289 4.72417 9.93648 4.72417 8.09553C4.72417 6.25458 6.21656 4.7622 8.05751 4.7622L8.05751 6.48207Z" fill="#E02379"/>
</g>
<defs>
<clipPath id="clip0_4637_14083">
<rect width="16" height="16" fill="white" transform="translate(0.171875)"/>
</clipPath>
</defs>
</svg>
`);

const imgCursor = encodeURIComponent(`
  <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24'>
    <defs>
      <filter id='a' width='266.7%' height='156.2%' x='-75%' y='-21.9%' filterUnits='objectBoundingBox'>
        <feOffset dy='1' in='SourceAlpha' result='shadowOffsetOuter1'/>
        <feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='1'/>
        <feColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/>
        <feMerge>
          <feMergeNode in='shadowMatrixOuter1'/>
          <feMergeNode in='SourceGraphic'/>
        </feMerge>
      </filter>
      <path id='b' d='M1.67 12.67a7.7 7.7 0 0 0 0-9.34L0 5V0h5L3.24 1.76a9.9 9.9 0 0 1 0 12.48L5 16H0v-5l1.67 1.67z'/>
    </defs>
    <g fill='none' fill-rule='evenodd'><path d='M0 24V0h24v24z'/>
      <g fill-rule='nonzero' filter='url(#a)' transform='rotate(-90 9.25 5.25)'>
        <use fill='#000' fill-rule='evenodd' xlink:href='#b'/>
        <path stroke='#FFF' d='M1.6 11.9a7.21 7.21 0 0 0 0-7.8L-.5 6.2V-.5h6.7L3.9 1.8a10.4 10.4 0 0 1 0 12.4l2.3 2.3H-.5V9.8l2.1 2.1z'/>
      </g>
    </g>
  </svg>`);

export class FabricCustomizer {
  private imgIcon?: HTMLImageElement = undefined;

  setRotationIcon() {
    if (this.imgIcon) {
      document.removeChild(this.imgIcon);
    }
    this.imgIcon = document.createElement("img");
    this.imgIcon.src = `data:image/svg+xml;utf8,${svgRotateIcon}`;

    const mtr = new fabric.Control({
      x: 0,
      y: -0.5,
      offsetX: 0,
      offsetY: 0,
      cursorStyle: `url("data:image/svg+xml;charset=utf-8,${imgCursor}") 12 12, crosshair`,
      actionHandler: fabric.Object.prototype.controls.mtr.actionHandler,
      actionName: "rotate",
      render: this.renderIcon.bind(this),
      withConnection: false,
    });

    fabric.Object.prototype.controls.mtr = mtr;
    fabric.Textbox.prototype.controls.mtr = mtr;
  }

  private renderIcon(
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object,
  ) {
    const size = { width: 16, height: 16 };
    ctx.save();
    ctx.translate(left, top);
    if (fabricObject.angle) {
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    }
    ctx.drawImage(this.imgIcon as HTMLImageElement, -size.width / 2, -size.height / 2, size.width, size.height);
    ctx.restore();
  }
}
