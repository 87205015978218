import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { IconComponent } from "../icon/icon.component";

@Component({
  selector: "m-sidebar-tabs-new",
  templateUrl: "./sidebar-tabs.view.html",
  styleUrls: ["./sidebar-tabs.view.scss"],
  standalone: true,
  imports: [CommonModule, IconComponent],
})
export class SidebarTabs implements OnChanges {
  @Input()
  selectedTab = 1;
  // @Input()
  // tab1Icon!: string;
  @Input()
  tab1Title!: string;

  // @Input()
  // tab2Icon!: string;
  @Input()
  tab2Title!: string;

  @Input()
  hideTabs = false;

  @Output()
  onTabClick = new EventEmitter<number>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTab) {
      this.goToTab(changes.selectedTab.currentValue);
    }
  }

  goToTab(tabIndex: number) {
    this.selectedTab = tabIndex;

    this.onTabClick.emit(this.selectedTab);
    this.cdr.markForCheck();
  }
}
