import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export type CreateProjectEvent = {
  projectType: "book" | "cover"
};

@Injectable({
  providedIn: "root",
})
export class HomeService {
  createProjectEvent$ = new BehaviorSubject<CreateProjectEvent | undefined>(undefined);

  showNewProjectModal(opts: CreateProjectEvent) {
    this.createProjectEvent$.next(opts);
  }

  resetNewProjectModal() {
    this.createProjectEvent$.next(undefined);
  }
}
