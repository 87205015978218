import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { SelectValue } from "@metranpage/components";
import { ThemeService } from "@metranpage/theme";
import { Subject, takeUntil } from "rxjs";
import { AdvancedGenerationMode, ImageSize } from "../../models/image-generation";

export type ImageProportionValue = ImageSize & {
  hp: number;
  wp: number;
};

@Component({
  selector: "m-image-advanced-generation-mode",
  templateUrl: "./image-advanced-generation-mode.view.html",
  styleUrls: ["./image-advanced-generation-mode.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageAdvancedGenerationModeView),
      multi: true,
    },
  ],
})
export class ImageAdvancedGenerationModeView {
  private onTouched = () => {};
  private onChange = (_: any) => {};

  protected value: AdvancedGenerationMode = "fast";

  protected items: SelectValue[] = [
    { id: "fast", value: $localize`:@@image-generation.image-advanced-generation-mode.fast:` },
    { id: "quality", value: $localize`:@@image-generation.image-advanced-generation-mode.quality:` },
  ];

  protected isDisabled = false;

  private destroyed$ = new Subject<void>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  writeValue(value: AdvancedGenerationMode): void {
    this.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onSelect(value: string | number) {
    this.value = value as AdvancedGenerationMode;
    this.onChange(this.value);
  }
}
