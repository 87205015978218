<div class="container">
  <div *ngIf="book?.exportEpub && !book?.exportPrint" class="epub-info">
    <img class="epub-info-img" [src]="getThemeEpub()" />

    <m-localized-text
      class="epub-info-text"
      text="@@books.preview.preview-info-text"
      i18n-text="@@books.preview.preview-info-text"
    ></m-localized-text>
  </div>

  <div class="side-panel-left" *ngIf="book?.exportPrint">
    <m-side-panel sidebar-position="left" sidebar-type="fixed">
      <ng-container>
        <div class="side-panel-left-context">
          <m-preview-spreads
            [imageDataURLs]="previewsThumbnails$ | async"
            [selected-left-page]="selectedLeftPage"
            (onSelectLeftPage)="onSelectLeftPage($event)"
          ></m-preview-spreads>
        </div>
      </ng-container>
    </m-side-panel>
  </div>

  <div class="preview" *ngIf="book?.exportPrint">
    <m-preview-spread
      [imageDataURLs]="previews$ | async"
      [selected-left-page]="selectedLeftPage"
      (onSelectLeftPage)="onSelectLeftPage($event)"
    ></m-preview-spread>
  </div>

  <div class="side-panel-right">
    <m-side-panel *ngIf="showLinksSidebar" sidebar-position="right" sidebar-type="fixed">
      <ng-container>
        <div class="side-panel-right-context">
          <span class="text-block" i18n="@@books.preview.process-info">books.preview.process-info</span>
          <span class="text-block" i18n="@@books.preview.process-info-2">books.preview.process-info-2</span>

          <div class="separator-block"></div>

          <div class="edit-settings-block">
            <span i18n="@@books.preview.upload-file">books.preview.upload-file</span>
            <m-button
              button-style="tertiary"
              icon="pencil.svg"
              icon-position="only"
              (onClick)="onEditClick('upload')"
            ></m-button>
          </div>

          <!--<div class="edit-settings-block">
            <span i18n="@@books.preview.project-info">books.preview.project-info</span>
            <svg-icon class="icon-pencil" src="/assets/icons/pencil.svg" (click)="onEditClick('project-info')"></svg-icon>
          </div> -->

          <div class="edit-settings-block">
            <span i18n="@@books.preview.templates">books.preview.templates</span>
            <m-button
              button-style="tertiary"
              icon="pencil.svg"
              icon-position="only"
              (onClick)="onEditClick('template')"
            ></m-button>
          </div>

          <div class="edit-settings-block">
            <span i18n="@@books.preview.markup">books.preview.markup</span>
            <m-button
              button-style="tertiary"
              icon="pencil.svg"
              icon-position="only"
              (onClick)="onEditClick('markup')"
            ></m-button>
          </div>

          <div *ngIf="book?.exportPrint" class="edit-settings-block">
            <span i18n="@@books.preview.margins">books.preview.margins</span>
            <m-button
              button-style="tertiary"
              icon="pencil.svg"
              icon-position="only"
              (onClick)="onEditClick('margins')"
            ></m-button>
          </div>

          <div *ngIf="book?.exportPrint" class="edit-settings-block">
            <span i18n="@@books.preview.running-titles">books.preview.running-titles</span>
            <m-button
              button-style="tertiary"
              icon="pencil.svg"
              icon-position="only"
              (onClick)="onEditClick('running-titles')"
            ></m-button>
          </div>
        </div>

        <div class="buttons">
          <m-button
            *ngIf="book?.exportEpub && book?.bookResults?.isEpubPreviewsReady"
            class="button-download"
            button-style="secondary"
            (onClick)="onDownloadPreviewEpubClick()"
            [is-disabled]="!book?.bookResults?.isEpubPreviewsReady"
            i18n="@@books.preview.preview-epub"
          >
            books.preview.preview-epub
          </m-button>
          <m-button
            class="button-produce"
            button-style="primary"
            (onClick)="onProduceClick()"
            i18n="@@books.preview.layout-book"
          >
            books.preview.layout-book
          </m-button>
        </div>
      </ng-container>
    </m-side-panel>
    <m-preview-sidebar-layout-steps
      *ngIf="showLayoutStepsSidebar"
      [book]="book"
      [isPreviewsReady]="isLayoutFinished('preview')"
      [isFinalsReady]="isLayoutFinished('final')"
      [layoutStep]="layoutStep"
      (onNewBookClick)="onNewBookClick()"
      (onDownloadProjectClick)="onDownloadProjectClick()"
      (onDownloadEpubClick)="onDownloadPreviewEpubClick()"
    />
  </div>
</div>

<m-payments-modal
  *ngIf="isPaymentsModalVisible"
  [payment-data]="paymentData"
  [is-purchases-available]="isPurchasesAvailable"
  (payGoldCredits)="onPayGoldCredits($event)"
  (payCredits)="onPayCredits($event)"
  (buyCredits)="onCreditsBuy($event)"
  (close)="closePricingModal()"
/>

<m-low-balance-modal
  *ngIf="isLowBalanceModalVisible && (tariffsForUpgrade$ | async) as tariffs"
  @fadeInOut
  [tariffs]="tariffs"
  [balanceData]="balanceData"
  [activeSubscription]="activeSubscription"
  (close)="closePricingModal()"
  (payWithGoldCredits)="onPayGoldCredits($event)"
  (buySubscription)="onBuySubscription($event)"
/>

<m-info-modal
  *ngIf="isChangeTariffModalVisible"
  [title]="getInfoModalTitle()"
  [text]="getInfoModalText()"
  actionText="books.info-modal.projects-count-limit.action-text"
  i18n-actionText="@@books.info-modal.projects-count-limit.action-text"
  [backgroundImage]="'/assets/img/info-modal-projects-count-limit.png'"
  (actionClick)="onClickChangeTariffModal()"
  (close)="onCloseChangeTariffModal()"
/>

<m-info-modal
  *ngIf="isFreemiumModalVisible"
  title="books.info-modal.free-layout.text"
  i18n-title="@@books.info-modal.free-layout.title"
  text="books.info-modal.free-layout.text"
  i18n-text="@@books.info-modal.free-layout.text"
  actionText="books.info-modal.free-layout.action-text"
  i18n-actionText="@@books.info-modal.free-layout.action-text"
  [backgroundImage]="getThemeSpecificImageSource()"
  (actionClick)="onLayoutFreeBook()"
  (close)="closeFreemiumModal()"
/>

<m-loading-spinner *ngIf="(loadingState$ | async)?.isActive  ?? false" class="layout-process-loading">
  <div class="comment">{{(loadingState$ | async)?.description ?? ''}}</div>
</m-loading-spinner>

<m-modal-project-router-host />
