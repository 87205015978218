<div class="wrapper">
  <m-pricing-tariff-card
    *ngFor="let tariff of tariffs"
    [appearance]="cardAppearance"
    [tariff]="tariff"
    [activeSubscription]="activeSubscription"
    [promocodeDiscountData]="promocodeDiscountData"
    (onBuySubscriptionClick)="buySubscription.emit($event)"
  />
</div>
