import { Component, Input } from "@angular/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "m-admin-menu-item-component",
  template: `
    <svg-icon class="icon" [src]="icon" alt=""></svg-icon>
    <span>{{ title }}</span>
  `,
  styles: [
    `
      @import '../../../../../../apps/metranpage/src/styles/mixins';

      :host {
        display: flex;
        flex-direction: row;
        align-items: center;

        width: 100%;
        height: 48px;

        padding: 8px;
        border-radius: 10px;

        cursor: pointer;
        gap: 8px;

        &.active {
          color: var(--accent);
          background-color: var(--surface-on-island);

          svg-icon {
            @include svg-color(var(--accent));
          }
        }

        &:hover {
          background-color: var(--color-bg-input);
        }
      }

      svg-icon {
        @include svg-color(var(--color-font-main));

        display: block;
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
    `,
  ],
  standalone: true,
  imports: [AngularSvgIconModule],
})
export class AdminMenuItemComponent {
  @Input()
  icon!: string;
  @Input()
  title!: string;
}
