import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '../../models/company';
import { AdminCompanyStore } from '../../services/company/company.store';
import { AdminBasePage } from '../admin/admin.page';

@Component({
  selector: 'm-admin-company-page',
  templateUrl: './company.page.html',
  styleUrls: ['./company.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCompanyPage extends AdminBasePage {
  company$!: Observable<Company | undefined>;

  isEditModalVisible = false;

  constructor(adminCompanyStore: AdminCompanyStore) {
    super();

    this.company$ = adminCompanyStore.getCompanyObservable();
  }

  onEditSave(company: Company) {
    this.isEditModalVisible = false;
  }

  onEditClick() {
    this.isEditModalVisible = true;
  }

  onEditCancel() {
    this.isEditModalVisible = false;
  }

  onEditError(error: unknown) {}
}
