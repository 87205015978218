import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CoverObject, ImageObject, ObjectsAlignment, ShapeObject, TextObject } from "@metranpage/book-data";
import { I18nService } from "@metranpage/i18n";

@Component({
  selector: "m-cover-object-settings",
  templateUrl: "./cover-object-settings.component.html",
  styleUrls: ["./cover-object-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverObjectSettingsComponent implements OnChanges {
  @Input() currentObject?: CoverObject | CoverObject[];
  @Input() fontFaces?: FontFace[];
  @Input() isGradientApplicable = true;

  @Output() update = new EventEmitter<CoverObject>();
  @Output() close = new EventEmitter();
  @Output() align = new EventEmitter<ObjectsAlignment>();
  @Output() previewFontFamily = new EventEmitter<string>();
  @Output() resetFontFamily = new EventEmitter();

  caption?: string;

  constructor(private readonly i18nService: I18nService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentObject instanceof TextObject) {
      this.caption = $localize`:@@cover-editor.object.settings.text.header.caption:`;
    } else if (this.currentObject instanceof ImageObject) {
      this.caption = $localize`:@@cover-editor.object.settings.image.header.caption:`;
    } else if (this.currentObject instanceof ShapeObject) {
      this.caption = $localize`:@@cover-editor.object.settings.shape.header.caption:`;
    } else if (Array.isArray(this.currentObject)) {
      const selected = this.i18nService.pluralize(
        $localize`:@@cover-editor.object.settings.groups.header.caption.selected:`,
        {
          objects: this.currentObject.length,
        },
      );
      const layers = this.i18nService.pluralize(
        $localize`:@@cover-editor.object.settings.groups.header.caption.layers:`,
        {
          objects: this.currentObject.length,
        },
      );
      this.caption = `${selected} ${this.currentObject.length} ${layers}`;
    }
  }

  get shapeObject(): ShapeObject | undefined {
    return this.currentObject instanceof ShapeObject ? this.currentObject : undefined;
  }

  get textObject(): TextObject | undefined {
    return this.currentObject instanceof TextObject ? this.currentObject : undefined;
  }

  get imageObject(): ImageObject | undefined {
    return this.currentObject instanceof ImageObject ? this.currentObject : undefined;
  }

  get group(): ImageObject[] | undefined {
    return Array.isArray(this.currentObject) ? this.currentObject : undefined;
  }

  onClose() {
    this.close.emit();
  }

  onUpdate(object: CoverObject) {
    this.update.emit(object);
  }

  onAlign(alignment: ObjectsAlignment) {
    this.align.emit(alignment);
  }

  onPreviewFontFamily(fontFamily: string) {
    this.previewFontFamily.emit(fontFamily);
  }

  onResetFontFamily() {
    this.resetFontFamily.emit();
  }
}
