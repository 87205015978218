<m-account-notification-card-host [notification]="notification" [isLast]="isLast">
  <div icon class="icons-wrapper">
    <m-ng-icon class="icon-main" [size]="24" src="notification-cover-moderation.svg" />

    <m-ng-icon-2
      *ngIf="!payload.rejectReason"
      class="icon-secondary-success"
      [size]="22"
      src="notification-success.svg"
    />
    <m-ng-icon-2 *ngIf="payload.rejectReason" class="icon-secondary-fail" [size]="20" src="notification-fail.svg" />
  </div>

  <div title class="title-group">
    <div class="title">
      <span *ngIf="!payload.rejectReason" i18n="@@user.profile.notifications.card.cover-moderation-success.title">
        user.profile.notifications.card.cover-moderation-success.title
      </span>
      <span *ngIf="payload.rejectReason" i18n="@@user.profile.notifications.card.cover-moderation-fail.title">
        user.profile.notifications.card.cover-moderation-fail.title
      </span>
    </div>

    <div class="card-label" i18n="@@user.profile.notifications.filter.moderation">
      user.profile.notifications.filter.moderation
    </div>
  </div>

  <div description class="description">
    <div *ngIf="!payload.rejectReason" class="row">
      <span i18n="@@user.profile.notifications.card.cover-moderation-success.description-1">
        user.profile.notifications.card.cover-moderation-success.description-1
      </span>
      <a
        class="action"
        i18n="@@user.profile.notifications.card.cover-moderation-success.description-action"
        (click)="openCoverOnHomePage()"
      >
        user.profile.notifications.card.cover-moderation-success.description-action
      </a>
      <span i18n="@@user.profile.notifications.card.cover-moderation-success.description-2">
        user.profile.notifications.card.cover-moderation-success.description-2
      </span>
    </div>

    <div *ngIf="payload.rejectReason" class="row">
      <span i18n="@@user.profile.notifications.card.cover-moderation-fail.description-1">
        user.profile.notifications.card.cover-moderation-fail.description-1
      </span>
      <a
        class="action"
        i18n="@@user.profile.notifications.card.cover-moderation-fail.description-action"
        (click)="openCoverEditor()"
      >
        user.profile.notifications.card.cover-moderation-fail.description-action
      </a>
      <span i18n="@@user.profile.notifications.card.cover-moderation-fail.description-2">
        user.profile.notifications.card.cover-moderation-fail.description-2
      </span>
    </div>
    <div *ngIf="payload.rejectReason" class="row">{{ payload.rejectReason }}</div>
  </div>
</m-account-notification-card-host>
