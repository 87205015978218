import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { CoverConceptualGeneration, CoverConceptualGenerationStore } from "@metranpage/text-generation";
import { Subscription } from "rxjs";

@Component({
  selector: "m-cover-conceptual-assistant-generation",
  templateUrl: "./cover-conceptual-assistant-generation.view.html",
  styleUrls: ["./cover-conceptual-assistant-generation.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverConceptualAssistantGenerationView implements OnInit {
  @Output()
  showConceptualAssistantMenu = new EventEmitter<void>();

  protected coverConceptualGeneration?: CoverConceptualGeneration;

  sub: Subscription = new Subscription();

  constructor(
    private readonly coverConceptualGenerationStore: CoverConceptualGenerationStore,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.coverConceptualGenerationStore
        .getCoverConceptualGenerationObservable()
        .subscribe((coverConceptualGeneration) => {
          this.coverConceptualGeneration = coverConceptualGeneration;
          this.cdr.detectChanges();
        }),
    );
  }

  onShowConceptualAssistantMenuClick() {
    this.showConceptualAssistantMenu.emit();
  }
}
