<div class="book-card">

   <div class="cover" *ngIf="isCoverEditorEnabled" [routerLink]="['/books', book.id, 'cover']">
    <img *ngIf="book.coverId" [src]="coverImageUrl" [style.--aspect-ratio]="aspectRatio" />
    <m-ng-icon *ngIf="!book.coverId" src="/assets/icons/add-01.svg" [size]="64" />
  </div>
  <div class="cover stub" *ngIf="!isCoverEditorEnabled">
    <img *ngIf="book.coverId" [src]="coverImageUrl" [style.--aspect-ratio]="aspectRatio" />
    <m-ng-icon *ngIf="!book.coverId" src="/assets/icons/book-cover-stub.svg" [size]="150" />
  </div>


  <div class="info">
    <span class="title">{{ formatBookTitle(book.title)}}</span>

    <p class="small">{{getAuthor()}}</p>

    <div class="small additional">
      <div *ngIf="book.bookSettings">
        <span i18n="@@books.format.title">books.format.title</span>
        {{book.bookSettings.width}}x{{book.bookSettings.height}}
      </div>
    </div>
  </div>

  <div *ngIf="book.actionKey; else printsettings" class="progress">
    <div class="gears">
      <m-icon src="gear-animated.svg" class="gear-small" size="m" color="--color-font-main"></m-icon>
      <m-icon src="gear-animated.svg" class="gear-large" size="l" color="--color-font-main"></m-icon>
    </div>
    <div class="text">
      <h2 *ngIf="book.actionKey === 'preview'" i18n="@@books.book.action-preview-title">
        books.book.action-preview-title
      </h2>
      <h2 *ngIf="book.actionKey === 'final'" i18n="@@books.book.action-final-title">books.book.action-final-title</h2>

      <p *ngIf="book.actionKey === 'preview'" i18n="@@books.book.action-preview-description" class="description small">
        books.book.action-preview-description
      </p>
      <p *ngIf="book.actionKey === 'final'" i18n="@@books.book.action-final-description" class="description small">
        books.book.action-final-description
      </p>
    </div>
  </div>

  <ng-template #printsettings>
    <div *ngIf="displayStyle !== 'hidden'" class="print-settings">
      <m-book-print-settings [book]="book"></m-book-print-settings>
    </div>
  </ng-template>

  <div *ngIf="!book.actionKey" class="buttons">
    <m-book-card-action
      i18n-text="@@books.book.download-button"
      text="books.book.download-button"
      icon="/assets/icons/book-download.svg"
      [isDisabled]="!isBookDownloadsAvailable"
      class="download-menu-host"
    >
      <div class="download-menu" *ngIf="isBookDownloadsAvailable">
        <m-book-card-download
          *ngIf="isCoverReady"
          i18n-text="@@books.book.download-cover"
          text="books.book.download-cover"
          [url]="coverImageUrl"
          [book]="book"
          [isCover]="true"
        ></m-book-card-download>
        <div class="divider" *ngIf="isPrintDownloadReady"></div>
        <m-book-card-download
          *ngIf="isPrintDownloadReady"
          i18n-text="@@books.book.final-indd-link-title"
          text="books.book.final-indd-link-title"
          [url]="book.bookResults!.inddPackageUrl!"
          [book]="book"
        ></m-book-card-download>
        <div class="divider" *ngIf="isPrintDownloadReady"></div>
        <m-book-card-download
          *ngIf="isPrintDownloadReady"
          i18n-text="@@books.book.final-pdf-link-title"
          text="books.book.final-pdf-link-title"
          [url]="book.bookResults!.finalPdfUrl!"
          [book]="book"
          downloadSuffix="preview"
        ></m-book-card-download>
        <div class="divider" *ngIf="isPrintDownloadReady"></div>
        <m-book-card-download
          *ngIf="isPrintDownloadReady"
          i18n-text="@@books.book.final-pdf-for-print-link-title"
          text="books.book.final-pdf-for-print-link-title"
          [url]="book.bookResults!.finalPdfCMYKUrl!"
          [book]="book"
          downloadSuffix="print"
        ></m-book-card-download>
        <div class="divider" *ngIf="isEpubDownloadReady"></div>
        <m-book-card-download
          *ngIf="isEpubDownloadReady"
          i18n-text="@@books.book.final-epub-link-title"
          text="books.book.final-epub-link-title"
          [isEpub]="true"
          [url]="book.bookResults!.epubUrl!"
          [book]="book"
        ></m-book-card-download>
        <div class="divider" *ngIf="isPrintDownloadReady"></div>
        <m-book-card-download
          *ngIf="isPrintDownloadReady"
          i18n-text="@@books.book.final-all-title"
          text="books.book.final-all-title"
          [url]="book.bookResults!.finalPackageUrl!"
          [book]="book"
        ></m-book-card-download>
      </div>
    </m-book-card-action>
    <!-- <m-book-card-action
      i18n-text="@@books.book.copy-button"
      text="books.book.copy-button"
      icon="/assets/icons/book-copy.svg"
    ></m-book-card-action> -->

    <m-book-card-action
      i18n-text="@@books.book.edit-button"
      text="books.book.edit-button"
      icon="/assets/icons/book-edit.svg"
      class="edit-menu-host"
    >
      <div class="edit-menu">
        <m-book-card-edit *ngIf="isCoverEditorEnabled" i18n-text="@@books.book.edit-cover" text="books.book.edit-cover" step="cover" [book]="book" />
        <div class="divider" *ngIf="isCoverEditorEnabled"></div>
        <m-book-card-edit
          i18n-text="@@books.book.edit-book-data"
          text="books.book.edit-book-data"
          step="book-data"
          [book]="book"
        />
        <div class="divider"></div>
        <m-book-card-edit
          *ngIf="book.bookResults!.isOriginalFileUploaded"
          i18n-text="@@books.book.edit-book"
          text="books.book.edit-book"
          [book]="book"
        />
        <m-book-card-edit
          *ngIf="!book.bookResults!.isOriginalFileUploaded"
          i18n-text="@@books.book.upload-book"
          text="books.book.upload-book"
          [book]="book"
        />
      </div>
    </m-book-card-action>

    <m-book-card-action
      i18n-text="@@books.book.delete-button"
      text="books.book.delete-button"
      icon="/assets/icons/book-delete.svg"
      [isDelete]="true"
      (click)="onDeleteClick.emit()"
    ></m-book-card-action>
  </div>
</div>
