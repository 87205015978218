import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { GeneratedImageUpdateData } from "@metranpage/image-generation";
import {
  ModerationImageRequest,
  ModerationImageRequestPublishedFrom,
  ModerationImageRequestsData,
} from "../../models/moderation-image-request";

@Injectable({
  providedIn: "root",
})
export class AdminModerationImageRequestApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async loadModerationImageRequestsPaginated(page: number, publishedFrom: ModerationImageRequestPublishedFrom) {
    return this.get<ModerationImageRequestsData>("admin/moderation/images/paginated", {
      page,
      publishedFrom,
    });
  }

  async acceptModerationImageRequest(id: number) {
    return this.post<ModerationImageRequest>(`admin/moderation/images/${id}/accept`, {});
  }

  async declineModerationImageRequest(id: number, data: GeneratedImageUpdateData) {
    return this.post<ModerationImageRequest>(`admin/moderation/images/${id}/decline`, data);
  }
}
