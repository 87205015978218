<m-ng-island paddingSize="l">
<div class="new">
  <m-button button-style="primary" (onClick)="onAddTariff()" i18n="@@admin.tariffs.add-tariff">
    admin.tariffs.add-tariff
  </m-button>
</div>

<form [formGroup]="searchForm">
  <table>
    <thead>
      <th>ID</th>
      <th>
        Title
        <m-ng-input formControlName="title" size="32"/>
      </th>
      <th>Subtitle</th>
      <th>Price</th>
      <th>Currency</th>
      <th>Period</th>
      <!-- <th>Credits Per Month</th> -->
      <!-- <th>Gold Credits Per Month</th> -->
      <!-- <th>Active Projects</th> -->
      <!-- <th>Gift Period</th> -->
      <!-- <th>Trial Period, Day</th> -->
      <th>For Legal Entity</th>
      <!-- <th>File Limit, Mb</th> -->
      <th>Status</th>
      <th></th>
    </thead>

    <tbody>
      <tr *ngFor="let tariff of tariffs; trackBy: trackByTariffId">
        <td>{{tariff.id}}</td>
        <td>{{tariff.title}}</td>
        <td>{{getSubtitleText(tariff.subtitle)}}</td>
        <td>{{tariff.price}}</td>
        <td>{{tariff.currency}}</td>
        <td>{{tariff.period}}</td>
        <!-- <td>{{tariff.creditsPerMonth}}</td> -->
        <!-- <td>{{tariff.goldCreditsPerMonth}}</td> -->
        <!-- <td>{{tariff.activeProjects}}</td> -->
        <!-- <td>{{tariff.giftPeriod}}</td> -->
        <!-- <td>{{tariff.trialPeriodInDay}}</td> -->
        <td>{{tariff.forLegalEntity}}</td>
        <!-- <td>{{tariff.fileLimitMb}}</td> -->
        <td><div class="status" [ngClass]="{archived: tariff.isArchived}"></div></td>
        <td>
          <div class="controls">
            <m-icon
              class="icon"
              src="gear.svg"
              size="s"
              color="--color-font-main"
              (click)="showTariffDetails(tariff)"
            />
            <m-icon
              class="icon"
              size="s"
              src="copy.svg"
              color="--color-font-main"
              (click)="onCopyTariffClick(tariff.id)"
            />
            <m-icon
              class="icon"
              src="block-delete.svg"
              size="s"
              color="--color-error"
              (click)="showDeleteTariffModal(tariff)"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</form>

<m-admin-pagination
  [pageCount]="pageCount"
  [currentPage]="page"
  (onPageChanged)="onPageChanged($event)"
></m-admin-pagination>

<m-admin-add-tariff-modal
  *ngIf="isAddTariffModalVisible"
  (onTariffAdded)="onTariffAdded($event)"
  (onCancelAddition)="onCloseAddTariffModal()"
></m-admin-add-tariff-modal>

<m-modal *ngIf="isDeleteTariffModalVisible" position="center" (close)="onCloseDeleteTariffModal()">
  <div class="delete-modal">
    <span>
      <span i18n="@@admin.tariffs.tariff.delete-tariff-confirmation">
        admin.tariffs.tariff.delete-tariff-confirmation
      </span>

      <span *ngIf="tariffForDelete" class="name">
        «
        <span class="name-accent">{{tariffForDelete.title}}</span>
        »
      </span>
      ?
    </span>

    <div class="buttons">
      <m-button
        button-style="secondary"
        size="full-width"
        (onClick)="onCloseDeleteTariffModal()"
        i18n="@@admin.common.cancel"
      >
        admin.common.cancel
      </m-button>
      <m-button button-style="primary" (onClick)="onDeleteTariff()" i18n="@@admin.common.delete">
        admin.common.delete
      </m-button>
    </div>
  </div>
</m-modal>
</m-ng-island>
