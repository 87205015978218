import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CoverObject, ObjectsAlignment } from "@metranpage/book-data";

@Component({
  selector: "m-cover-group-object-settings",
  templateUrl: "./cover-group-object-settings.component.html",
  styleUrls: ["./cover-group-object-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverGroupObjectSettingsComponent {
  @Input() objects: CoverObject[] = [];

  @Output() align = new EventEmitter<ObjectsAlignment>();

  onAlign(alignment: ObjectsAlignment) {
    this.align.emit(alignment);
  }
}
