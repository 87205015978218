<m-ng-island paddingSize="l">
<form *ngIf="!!font; else nofont" [formGroup]="form">
  <div class="controls">
    <div class="control">
      <span class="label" i18n="@@admin.fonts.font.name">admin.fonts.font.name</span>
      <span>{{font.name}}</span>
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.fonts.font.family-name">admin.fonts.font.family-name</span>
      <span>{{font.familyName}}</span>
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.fonts.font.subfamily-name">admin.fonts.font.subfamily-name</span>
      <span>{{font.subfamilyName}}</span>
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.fonts.font.file">admin.fonts.font.file</span>

      <m-file-drop
        *ngIf="!fontFile && !originalUploadFilename"
        #filedrop
        (dropped)="dropped($event)"
        style="--custom-height: 150px"
        accept=".otf, .ttf"
      >
        <div class="drop-placeholder">
          <div>
            <p class="description-text">
              <ng-container i18n="@@books.upload.drop-text1">books.upload.drop_text1</ng-container>
            </p>
            <p class="description-text">
              &nbsp;
              <span
                class="mark"
                (click)="openFileSelector()"
                [style.color]="'#e02379'"
                i18n="@@books.upload.drop-text2-upload"
              >
                books.upload.drop_text2_upload
              </span>
            </p>
          </div>
        </div>
      </m-file-drop>

      <div *ngIf="originalUploadFilename" class="font-file">
        <b>{{originalUploadFilename}}</b>

        <m-icon src="book-delete.svg" size="m" color="--color-error" class="delete" (click)="deleteFontFile()"></m-icon>
      </div>
    </div>

    <div class="control group">
      <span class="label" i18n="@@admin.fonts.font.license">admin.fonts.font.license</span>

      <div class="row">
        <span>Ru:</span>
        <m-ng-input formControlName="licenseRu" />
      </div>

      <div class="row">
        <span>En:</span>
        <m-ng-input formControlName="licenseEn" />
      </div>
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.fonts.font.is-available-for-headers">
        admin.fonts.font.is-available-for-headers
      </span>
      <m-switch formControlName="isAvailableForHeaders" input-style="on-panel" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.fonts.font.is-available-for-main-text">
        admin.fonts.font.is-available-for-main-text
      </span>
      <m-switch formControlName="isAvailableForMainText" input-style="on-panel" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.fonts.font.is-selectable">admin.fonts.font.is-selectable</span>
      <m-switch formControlName="isSelectable" input-style="on-panel" />
    </div>
  </div>

  <div class="bottom-bar">
    <div class="buttons">
      <m-button button-style="secondary" (onClick)="onCancel()" i18n="@@admin.common.cancel">
        admin.common.cancel
      </m-button>
      <m-button
        button-style="primary"
        [is-disabled]="!form.valid || !originalUploadFilename"
        (onClick)="onSave()"
        i18n="@@admin.common.save"
      >
        admin.common.save
      </m-button>
    </div>
  </div>
</form>

<ng-template #nofont>No font loaded</ng-template>
</m-ng-island>
