import { Component, EventEmitter, Input, Output } from "@angular/core";
import { fadeInOutOnEnterLeave } from "@metranpage/components";

export type RestorePassData = {
  email: string;
};

@Component({
  selector: "m-account-verification-view",
  templateUrl: "./account-verification.view.html",
  styleUrls: ["./account-verification.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class AccountVerificationView {
  @Input()
  isLoading = false;
  @Input()
  isEmailSent = false;
  @Input()
  timeoutTimeLeft = 0;

  @Output()
  onSendVerificationEmailClick = new EventEmitter<void>();
  @Output()
  onBackClick = new EventEmitter<void>();

  onSendVerificationEmailButtonClick() {
    if (this.timeoutTimeLeft > 0) {
      return;
    }
    this.onSendVerificationEmailClick.emit();
  }

  isSendButtonDisabled() {
    return this.timeoutTimeLeft > 0;
  }
}
