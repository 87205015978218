import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from "@angular/core";
import { Book, CoverObjectType, UploadGeneratedImageRequestDto } from "@metranpage/book-data";
import { IS_IMAGES_GENERATION_AVAILABLE, LoadingService } from "@metranpage/core";
import { GeneratedImage } from "@metranpage/image-generation";
import { NgxFileDropEntry } from "ngx-file-drop";
import { CoverService } from "../../services/cover/cover.service";
import { CreateCoverObject } from "../cover-object-create/cover-object-create.component";

@Component({
  selector: "m-cover-image-selection-modal",
  templateUrl: "./cover-image-selection-modal.component.html",
  styleUrls: ["./cover-image-selection-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverImageSelectionModalComponent {
  @Input() book!: Book;

  @Output() close = new EventEmitter();
  @Output() create: EventEmitter<CreateCoverObject> = new EventEmitter<CreateCoverObject>();

  @ViewChild("fileUpload") fileUploadElement!: ElementRef;

  isImageGeneratorVisible = false;
  imageFileTypes = ".jpg,.jpeg,.png";

  constructor(
    private readonly coverService: CoverService,
    private readonly loadingService: LoadingService,
    @Inject(IS_IMAGES_GENERATION_AVAILABLE)
    protected readonly isImagesGenerationAvailable: boolean,
  ) {}

  async uploadImage(event: NgxFileDropEntry[]) {
    if (!event.length) {
      return;
    }
    const fileEntry = event[0].fileEntry as FileSystemFileEntry;
    fileEntry.file(async (file: File) => {
      this.loadingService.startLoading({ fullPage: true });
      const result = await this.coverService.uploadImage(this.book.id, file, "object");
      this.loadingService.stopLoading();
      this.create.emit({ type: CoverObjectType.Image, imageName: result.name });
    });
  }

  async selectGeneratedImage(image: GeneratedImage) {
    this.loadingService.startLoading({ fullPage: true });
    this.isImageGeneratorVisible = false;
    const result = await this.coverService.uploadGeneratedObjectImage(<UploadGeneratedImageRequestDto>{
      bookId: this.book.id,
      generationId: image.imageGenerationId,
      src: image.imageUrl,
    });
    this.loadingService.stopLoading();
    this.create.emit({ type: CoverObjectType.Image, imageName: result.name });
  }

  showImageGenerator() {
    this.isImageGeneratorVisible = true;
  }

  hideImageGenerator() {
    this.isImageGeneratorVisible = false;
  }
}
