import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BookCover } from "@metranpage/book-data";
import { CoverConceptualGeneration, CoverConceptualGenerationDataDto } from "@metranpage/text-generation";

@Component({
  selector: "m-cover-conceptual-assistant-generation-menu",
  templateUrl: "./cover-conceptual-assistant-generation-menu.view.html",
  styleUrls: ["./cover-conceptual-assistant-generation-menu.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverConceptualAssistantGenerationMenuView {
  @Input()
  cover!: BookCover;

  @Output()
  generateCoverConceptual = new EventEmitter<CoverConceptualGenerationDataDto>();
  @Output()
  closeConceptualAssistant = new EventEmitter<void>();

  protected coverConceptualGeneration?: CoverConceptualGeneration;

  protected form = new FormGroup({
    promptAnnotation: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required, Validators.pattern(/[\S]/g), Validators.minLength(10)],
    }),
    promptTargetAudience: new FormControl("", { nonNullable: true, validators: [] }),
    promptGenre: new FormControl("", { nonNullable: true, validators: [] }),
  });

  async onGenerateCoverConceptualClick() {
    const formData = this.form.getRawValue();
    const data: CoverConceptualGenerationDataDto = {
      promptAnnotation: formData.promptAnnotation.replaceAll("<br>", " "),
      promptGenre: formData.promptGenre.replaceAll("<br>", " "),
      promptTargetAudience: formData.promptTargetAudience.replaceAll("<br>", " "),
      coverId: this.cover.id,
    };
    this.generateCoverConceptual.emit(data);
  }

  onCloseConceptualAssistantClick() {
    this.closeConceptualAssistant.emit();
  }
}
