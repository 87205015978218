import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ApiErrorHandlerService } from "@metranpage/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { PromocodeCreateData, PromocodeUpdateData } from "../../models/promocode";
import { AdminPromocodesApi, PromocodesFilter } from "./promocodes.api";
import { AdminPromocodesStore } from "./promocodes.store";

function promocodeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (
      (((!value.creditsCount && !value.goldCreditsCount && !value.tariffId) ||
        (value.tariffId && !value.subscriptionPeriod && !value.discontForSubscription)) &&
        !value.discontForSubscription) ||
      (value.discontForSubscription && (!value.expireDate || new Date(value.expireDate) < new Date()))
    ) {
      return {
        promocodeValidation: true,
      };
    }
    return null;
  };
}

@Injectable({
  providedIn: "root",
})
export class AdminPromocodesService {
  constructor(
    private readonly adminPromocodesApi: AdminPromocodesApi,
    private readonly adminPromocodesStore: AdminPromocodesStore,
    private readonly apiErrorHandler: ApiErrorHandlerService,
  ) {}

  async loadPromocode(promocodeId: number) {
    const promocodeData = await this.adminPromocodesApi.getPromocode(promocodeId);
    this.adminPromocodesStore.setActivePromocode(promocodeData);
  }

  async loadPromocodesPaginated(page: number, filters: PromocodesFilter = {}) {
    const promocodesData = await this.adminPromocodesApi.getPromocodesPaginated(page, filters);
    this.adminPromocodesStore.setPromocodes(promocodesData.items);
    this.adminPromocodesStore.setPromocodesPageCount(promocodesData.pageCount);
  }

  async updatePromocode(promocodeId: number, promocode: PromocodeUpdateData) {
    try {
      await this.adminPromocodesApi.updatePromocode(promocodeId, promocode);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "error";
    }
  }

  async createPromocode(data: PromocodeCreateData): Promise<{ status: GeneralResultStatus; id?: number }> {
    try {
      const newPromocode = await this.adminPromocodesApi.createPromocode(data);
      return {
        status: "success",
        id: newPromocode.id,
      };
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return {
        status: "error",
        id: undefined,
      };
    }
  }

  async deletePromocode(promocodeId: number): Promise<{ status: GeneralResultStatus }> {
    try {
      const result = await this.adminPromocodesApi.deletePromocode(promocodeId);
      return {
        status: "success",
      };
    } catch (error: any) {
      return {
        status: "error",
      };
    }
  }

  createPromocodeForm() {
    return new FormGroup(
      {
        token: new FormControl("", {
          nonNullable: true,
          validators: [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)],
        }),
        // status: new FormControl('', { nonNullable: true, validators: [] }),
        agent: new FormControl("", { nonNullable: true, validators: [] }),
        createdDate: new FormControl("", { nonNullable: true, validators: [] }),
        expireDate: new FormControl("", { nonNullable: true, validators: [] }),
        singleUse: new FormControl(false, { nonNullable: true, validators: [Validators.required] }),
        description: new FormControl("", { nonNullable: true, validators: [] }),
        creditsCount: new FormControl(0, { nonNullable: true, validators: [Validators.max(200)] }),
        goldCreditsCount: new FormControl(0, { nonNullable: true, validators: [Validators.max(10)] }),
        tariffId: new FormControl(0, { nonNullable: true, validators: [] }),
        subscriptionPeriod: new FormControl(0, { nonNullable: true, validators: [Validators.max(12)] }),
        discontForSubscription: new FormControl(0, { nonNullable: true, validators: [Validators.max(100)] }),
      },
      [promocodeValidator()],
    );
  }
}
