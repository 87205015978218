<div class="header">
  <m-template-filter-selector
    [activeFilter]="activeFilter"
    (onTemplateEditableTypeSelect)="onTemplateEditableTypeSelect($event)"
  />

  <form *ngIf="hasCustomBookFormat" [formGroup]="formFormat" class="format-selectors">
    <m-ng-input-number formControlName="width" size="32" text-align="center" />
    <m-ng-icon src="cross-2.svg" [size]="8" />
    <m-ng-input-number formControlName="height" size="32" text-align="center" />

    <div class="menu-format-change-orientation">
      <m-ng-icon
        class="button-proportion-change-orientation"
        [src]="orientationIconSrc"
        [size]="24"
        [noRecolor]="true"
        (click)="onChangeOrientationClick()"
      />
    </div>
  </form>
</div>

<div class="divider-hor"></div>

<div class="grid">
  <m-template-preview-card-new
    *ngIf="isCoverAvailable()"
    i18n-title="@@books.template.create.cover"
    title="books.template.create.cover"
    i18n-description="@@books.template.create.cover.description"
    description="books.template.create.cover.description"
    [isDetailsAvailable]="false"
    imgSrc="/assets/images/cover-create-card-{theme}.svg"
    (onSelect)="onCoverSelectClick()"
  />

  <m-template-preview-card-new
    *ngIf="isDefaultTemplateAvailable()"
    i18n-title="@@books.template.select-template.empty-template-title"
    title="books.template.select-template.empty-template-title"
    i18n-description="@@books.template.select-template.empty-template-description"
    description="books.template.select-template.empty-template-description"
    [isDetailsAvailable]="false"
    (onSelect)="onSelectTemplateClick()"
  />

  <m-template-preview-card-new
    *ngFor="let template of templates | async; trackBy: trackTemplate"
    [templateId]="template.id"
    [previews]="template.previews"
    [title]="template.title"
    [description]="template.descriptionShort!"
    [isDetailsAvailable]="true"
    [isSelectDisable]="isSelectDisable(template)"
    (onSelect)="onSelectTemplateClick(template)"
    (onPreview)="previewTemplate(template.id)"
  />
</div>

<m-modal *ngIf="selectedTemplateId" position="center" (close)="onCloseSelectNonEditableTemplateModal()">
  <div class="info-modal">
    <p
      *ngIf="isSelectedNonEditableTemplate"
      class="text"
      i18n="@@books.template.select-template.warning-on-select-non-editable-template"
    >
      books.template.select-template.warning-on-select-non-editable-template
    </p>

    <p
      *ngIf="hasCustomStylesInSelectedTemplate"
      class="text warning"
      i18n="@@books.template.select-template.warning-reset-styles"
    >
      books.template.select-template.warning-reset-styles
    </p>

    <div class="buttons">
      <m-button
        button-style="tertiary"
        (onClick)="onCloseSelectNonEditableTemplateModal()"
        i18n="@@dialog.cancel-button"
      >
        dialog.cancel-button
      </m-button>

      <m-button
        class="next-button"
        button-style="primary"
        (onClick)="selectTemplate(selectedTemplateId)"
        i18n="@@books.template.select-template.select-button"
      >
        books.template.select-template.select-button
      </m-button>
    </div>
  </div>
</m-modal>
