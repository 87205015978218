import { Component, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@metranpage/auth";
import { CompanyStore } from "@metranpage/company";
import { IS_PURCHASES_AVAILABLE, filterUndefined } from "@metranpage/core";
import { User, UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";

@Component({
  selector: "m-account-nav-view",
  template: `
    <m-ng-island paddingSize="0">
      <div class="container">
        <m-account-menu-item-component
          *ngIf="user?.isAdmin"
          routerLink="/account/developer-settings"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          title="user.profile.nav.developer-settings"
          i18n-title="@@user.profile.nav.developer-settings"
          icon="/assets/icons/account-nav-dev.svg"
        />

        <m-account-menu-item-component
          routerLink="/account/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          title="user.profile.nav.main"
          i18n-title="@@user.profile.nav.main"
          icon="/assets/icons/account-nav-user.svg"
        />

        <m-account-menu-item-component
          routerLink="/account/notifications/"
          routerLinkActive="active"
          title="user.profile.nav.notifications"
          i18n-title="@@user.profile.nav.notifications"
          icon="/assets/icons/account-nav-notifications.svg"
        />

        <m-account-menu-item-component
          *ngIf="isPurchasesAvailable"
          routerLink="/account/tariff-and-payments"
          routerLinkActive="active"
          title="user.profilee.nav.tariff-and-payments"
          i18n-title="@@user.profile.nav.tariff-and-payments"
          icon="/assets/icons/account-nav-payments.svg"
        />

        <m-account-menu-item-component
          *ngIf="user?.userDataSource === 'email'"
          routerLink="/account/password"
          routerLinkActive="active"
          title="user.profile.nav.password"
          i18n-title="@@user.profile.nav.password"
          icon="/assets/icons/account-nav-password.svg"
        />

        <m-account-menu-item-component
          *ngIf="user?.userDataSource === 'email'"
          routerLink="/account/contacts"
          routerLinkActive="active"
          title="user.profile.nav.contacts"
          i18n-title="@@user.profile.nav.contacts"
          icon="/assets/icons/account-nav-contacts.svg"
          [showWarning]="!user?.isVerified"
        />

        <m-account-menu-item-component
          *ngIf="isLogoutButtonAvailable"
          title="user.profile.nav.logout"
          i18n-title="@@user.profile.nav.logout"
          icon="/assets/icons/account-nav-logout.svg"
          colorStyle="danger"
          (click)="onLogoutClick()"
        />
      </div>
    </m-ng-island>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 280px;
        flex-shrink: 0;
      }

      m-ng-island {
        width: 100%;
        height: 100%;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        gap: 8px;
        overflow: hidden auto;
        padding: 16px 0;
      }
    `,
  ],
})
export class AccountNavView {
  user?: User;

  protected isLogoutButtonAvailable = true;

  sub: Subscription = new Subscription();

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    userStore: UserStore,
    companyStore: CompanyStore,
    @Inject(IS_PURCHASES_AVAILABLE) protected readonly isPurchasesAvailable: boolean,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );

    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.isLogoutButtonAvailable = company.isLogoutButtonAvailable;
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onLogoutClick() {
    this.authService.logout();
    this.router.navigate(["/login"]);
  }
}
