<m-ng-island paddingSize="0">
  <div class="menu">
    <m-button
      class="hidden-button"
      button-style="tertiary"
      icon="account.svg"
      icon-position="left"
      text-align="left"
      size="full-width"
      routerLink="/account"
    >
      {{ user.firstName }}
    </m-button>

    <m-button
      button-style="tertiary"
      icon="my-projects.svg"
      icon-position="left"
      text-align="left"
      size="full-width"
      routerLink="/books"
      i18n="@@home.menu.my-projects-button"
    >
      home.menu.my-projects-button
    </m-button>

    <m-button
      button-style="tertiary"
      icon="image-generation.svg"
      icon-position="left"
      text-align="left"
      size="full-width"
      routerLink="/image-generation"
      i18n="@@home.menu.image-generation-button"
    >
      home.menu.image-generation-button
    </m-button>

    <m-button
      *ngIf="knowledgeBaseUrl$ | async as knowledgeBaseUrl"
      class="hidden-button"
      button-style="tertiary"
      icon="knowledge-base.svg"
      icon-position="left"
      text-align="left"
      size="full-width"
      (onClick)="openKnowledgeBase(knowledgeBaseUrl)"
      i18n="@@home.menu.knowledge-base-button"
    >
      home.menu.knowledge-base-button
    </m-button>

    <m-button
      button-style="tertiary"
      icon="image-editor.svg"
      icon-position="left"
      text-align="left"
      size="full-width"
      (onClick)="showSoonNotification()"
    >
      <m-soon-mark>
        <span text-left i18n="@@home.menu.image-editor-button">home.menu.image-editor-button</span>
      </m-soon-mark>
    </m-button>

    <m-button
      button-style="tertiary"
      icon="image-enhancement.svg"
      icon-position="left"
      text-align="left"
      size="full-width"
      (onClick)="showSoonNotification()"
    >
      <m-soon-mark>
        <span text-left i18n="@@home.menu.image-enhancement-button">home.menu.image-enhancement-button</span>
      </m-soon-mark>
    </m-button>

    <m-button
      button-style="tertiary"
      icon="background-removal.svg"
      icon-position="left"
      text-align="left"
      size="full-width"
      (onClick)="showSoonNotification()"
    >
      <m-soon-mark>
        <span text-left i18n="@@home.menu.background-removal-button">home.menu.background-removal-button</span>
      </m-soon-mark>
    </m-button>

    <m-button
      button-style="tertiary"
      icon="contests.svg"
      icon-position="left"
      text-align="left"
      size="full-width"
      (onClick)="showSoonNotification()"
    >
      <m-soon-mark>
        <span text-left i18n="@@home.menu.community-contests-button">home.menu.community-contests-button</span>
      </m-soon-mark>
    </m-button>

    <m-button
      button-style="tertiary"
      icon="platform-covers.svg"
      icon-position="left"
      text-align="left"
      size="full-width"
      (onClick)="showSoonNotification()"
    >
      <m-soon-mark>
        <span text-left i18n="@@home.menu.platform-covers-button">home.menu.platform-covers-button</span>
      </m-soon-mark>
    </m-button>
  </div>
</m-ng-island>
