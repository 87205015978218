import { Inject, Injectable } from "@angular/core";
import { I18nService } from "@metranpage/i18n";
import * as _ from "lodash-es";
import { FormatApi } from "./format.api";
import { FormatStore } from "./format.store";
import { Format, FormatData, FormatDto, FormatType } from "./models/format";

@Injectable({
  providedIn: "root",
})
export class FormatService {
  formats: Format[] = [];

  constructor(
    private readonly formatStore: FormatStore,
    private readonly formatApi: FormatApi,
    private readonly i18nService: I18nService,
  ) {
    this.watchFormats();
  }

  async loadFormats() {
    const formatsData = await this.formatApi.loadFormats();
    const formats = formatsData.formats.map((f) => this.updateFormatLocalization(f));
    this.formatStore.setFormats(formats);
  }

  getFormats() {
    return this.formats;
  }

  updateFormatLocalization(data: FormatDto) {
    const locale = this.i18nService.getLocale();
    let localeData = data?.localization?.find((l) => l.lang === locale);
    if (!localeData) {
      localeData =
        data?.localization?.length > 0
          ? data.localization[0]
          : {
              id: 0,
              title: "",
              lang: "",
              formatId: -1,
            };
    }

    const formatData = _.omit(data, ["localization"]);
    const formatLocalizedData = _.omit(localeData, ["id", "lang", "formatId"]);
    const format: Format = {
      ...formatData,
      ...formatLocalizedData,
    };
    return format;
  }

  prepareFormatOptions(formats: Format[], type: FormatType) {
    const iconSrc = "book-format.svg";
    let formatOptions = formats
      .filter((f) => f.type === type)
      .map((f) => {
        return {
          name: f.name,
          width: f.width,
          height: f.height,
          title: f.title,
          order: f.order,
          iconSrc,
        };
      });
    formatOptions = _.orderBy(formatOptions, [(f) => f.order], ["asc"]);
    return formatOptions;
  }

  private watchFormats() {
    this.formatStore.getFormatsObservable().subscribe((formats) => {
      this.formats = formats;
    });
  }

  getFormatData(width: number | undefined, height: number | undefined): FormatData {
    if (!width || !height) {
      const format = this.formats[0];
      return {
        format: format.name,
        width: format.width,
        height: format.height,
      };
    }

    const format = this.formats.find((f) => f.width === width && f.height === height);
    if (!format) {
      return {
        format: "custom",
        width: width,
        height: height,
      };
    }
    return {
      format: format.name,
      width: width,
      height: height,
    };
  }

  findFormatBySize(width: number, height: number) {
    return this.formats.find((f) => f.width === width && f.height === height);
  }

  getFormatNameBySize(width: number, height: number) {
    return this.formats.find((f) => f.width === width && f.height === height)?.name;
  }

  hasCustomFormat(type: FormatType): boolean {
    return !!this.formats.find((f) => f.type === type && f.name === "custom");
  }

  getBookSizeRange(type: FormatType) {
    const formats = this.formats.filter((f) => f.type === type);
    const widthFrom =
      _.minBy(formats, (f) => {
        return f.width;
      })?.width ?? 0;
    const widthTo =
      _.maxBy(formats, (f) => {
        return f.width;
      })?.width ?? 0;
    const heightFrom =
      _.minBy(formats, (f) => {
        return f.height;
      })?.height ?? 0;
    const heightTo =
      _.maxBy(formats, (f) => {
        return f.height;
      })?.height ?? 0;
    return {
      widthFrom,
      widthTo,
      heightFrom,
      heightTo,
    };
  }
}
