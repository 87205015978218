import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { LoadingService } from "@metranpage/core";
import { Company, CompanyUpdateData } from "../../models/company";
import { AdminCompanyService } from "../../services/company/company.service";
import { AdminUnisenderSettingsService } from "../../services/company/unisender-settings.service";

@Component({
  selector: "m-admin-edit-company",
  templateUrl: "./edit-company.view.html",
  styleUrls: ["./edit-company.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCompanyView {
  @Input() company!: Company;
  @Output() save = new EventEmitter<Company>();
  @Output() error = new EventEmitter<unknown>();
  @Output() cancel = new EventEmitter<void>();

  readonly form: FormGroup;

  constructor(
    private readonly adminCompanyService: AdminCompanyService,
    private readonly loadingService: LoadingService,
    private readonly adminUnisenderSettingsService: AdminUnisenderSettingsService,
  ) {
    this.form = adminUnisenderSettingsService.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.company) {
      this.onCompany();
    }
  }

  reset() {
    this.form.reset();
  }

  onCompany() {
    this.reset();
    this.form.patchValue(this.company);
  }

  onCancel() {
    this.cancel.emit();
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminCompanyService.updateCompany(this.form.value as CompanyUpdateData);
    this.loadingService.stopLoading();

    if (result.status === "success") {
      this.save.emit(result.company);
    } else {
      this.error.emit(result.error);
    }
  }

  getUnisenderListKey() {
    return this.adminUnisenderSettingsService.getUnisenderListsKey();
  }

  getLanguages() {
    return this.company.unisenderTemplateLanguages;
  }
}
