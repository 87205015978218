<div *ngIf="!hideTabs" class="tabs">
  <div class="tab" [class.active]="selectedTab == 1" (click)="goToTab(1)">
    <!-- <m-ng-icon
      [src]="tab1Icon"
      [color]="selectedTab == 1 ? '--color-font-main': '--color-font-not-active'"
      [size]="20"
    ></m-ng-icon> -->
    <span>{{tab1Title}}</span>
  </div>
  <div class="tab" [class.active]="selectedTab == 2" (click)="goToTab(2)">
    <!-- <m-ng-icon
      [src]="tab2Icon"
      [color]="selectedTab == 2 ? '--color-font-main': '--color-font-not-active'"
      [size]="20"
    ></m-ng-icon> -->
    <span>{{tab2Title}}</span>
  </div>
</div>

<div class="tab-content">
  <ng-content select="#tab1" *ngIf="selectedTab == 1"></ng-content>
  <ng-content select="#tab2" *ngIf="selectedTab == 2"></ng-content>
</div>
