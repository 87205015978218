<m-skeleton-image *ngIf="imageUrl" [url]="imageUrl" backgroudColor="var(--surface-on-island)"
  animationStyle="gradient" />

<div class="card-info">
  <div class="author">
    <div class="avatar"></div>
    <div class="author-name" *ngIf="author">
      <span>{{ author.firstName }}</span>
      <span>{{ author.lastName }}</span>
    </div>
  </div>

  <div class="likes"></div>
</div>
