<div *ngIf="cardData; else emptyCard" class="card">
  <div class="card-data">
    <m-icon [src]="getCardTypeIcon(cardData.type)" size="original" />
    <div class="card-info">
      <span class="card-number">**** {{ cardData.number.slice(-4) }}</span>
      <span class="card-expire-date">{{ cardData.expDate }}</span>
    </div>
  </div>

  <div class="divider"></div>

  <div class="footer">
    <m-radio class="selector" [isChecked]="isActive" (click)="setCardForPayment(cardData.id)">
      <span *ngIf="isActive" i18n="@@user.profile.tariff-and-payments.card-active">
        user.profile.tariff-and-payments.card-active
      </span>
      <span *ngIf="!isActive" i18n="@@user.profile.tariff-and-payments.card-select">
        user.profile.tariff-and-payments.card-select
      </span>
    </m-radio>
    <m-icon
      class="card-delete-btn"
      src="book-delete.svg"
      size="s"
      color="--color-font-text-danger"
      (click)="showDeleteCardModal(cardData.id)"
    />
  </div>
</div>

<ng-template #emptyCard>
  <div *ngIf="newCard" class="new-card" (click)="onAddNewCardClick.emit()">
    <m-icon src="plus.svg" size="m" color="--color-font-main" />
    <span i18n="@@user.profile.tariff-and-payments.add-card">user.profile.tariff-and-payments.add-card</span>
  </div>
  <div *ngIf="!newCard" class="card"></div>
</ng-template>
