<m-ng-island>
  <form [formGroup]="form">
    <div class="avatar">
      <m-icon src="account-avatar-{theme}.svg" size="original" />
      <!-- <img *ngIf="avatarUrl" crossorigin="anonymous" [src]="avatarUrl" /> -->

      <!-- <m-icon class="icon-camera" src="account-avatar-camera.svg" size="m" (click)="fileUpload.click()" /> -->
      <input #fileUpload type="file" accept="image/*" (change)="onFileSelected($event)" />
    </div>

    <div class="controls">
      <div class="control">
        <span class="label" i18n="@@user.profile.main.first-name">user.profile.main.first-name</span>
        <m-ng-input formControlName="firstName" />
      </div>

      <div class="control">
        <span class="label" i18n="@@user.profile.main.last-name">user.profile.main.last-name</span>
        <m-ng-input formControlName="lastName" />
      </div>

      <div class="control">
        <span class="label" i18n="@@user.profile.main.role">user.profile.main.role</span>
        <m-select
          class="select"
          input-style="normal"
          size="m-plus"
          [options]="roleOptions"
          formControlName="role"
        ></m-select>
      </div>

      <div class="action-block">
        <div class="buttons">
          <m-button
            button-style="secondary"
            (onClick)="onSaveButtonClick()"
            [is-disabled]="!form.valid || !hasChange"
            i18n="@@user.profile.main.save-changes"
          >
            user.profile.main.save-changes
          </m-button>
        </div>

        <span *ngIf="savedStatus" class="status" i18n="@@user.profile.main.saved">user.profile.main.saved</span>
      </div>
    </div>
  </form>

  <div class="footer">
    <span
      *ngIf="isLogoutButtonAvailable"
      class="delete-account-button"
      (click)="onShowDeleteAccountModal()"
      i18n="@@user.profile.delete-account-button"
    >
      user.profile.delete-account-button
    </span>
  </div>
</m-ng-island>

<m-modal
  *ngIf="isDeleteAccountModalVisible"
  position="center"
  [closeButtonVisible]="false"
  (close)="onCloseDeleteAccountModal()"
>
  <div class="delete-modal">
    <span class="title" i18n="@@user.profile.main.delete-account-confirmation">
      user.profile.main.delete-account-confirmation
    </span>

    <form [formGroup]="formDeleteConfirmation" class="form-delete-confirmation">
      <div class="control">
        <span class="label">
          <span i18n="@@user.profile.main.delete-account-enter-confirmation">
            user.profile.main.delete-account-enter-confirmation
          </span>
          <span class="confirmation-phrase" i18n="@@user.profile.main.delete-account-confirmation-phrase">
            user.profile.main.delete-account-confirmation-phrase
          </span>
        </span>
        <m-ng-input formControlName="confirmText" />
      </div>
    </form>

    <div class="buttons-modal">
      <m-button
        button-style="secondary"
        size="full-width"
        (onClick)="onCloseDeleteAccountModal()"
        i18n="@@dialog.cancel-button"
      >
        dialog.cancel-button
      </m-button>
      <m-button
        button-style="primary"
        [is-disabled]="!isDeleteAccountButtonActive"
        (onClick)="onDeleteAccount()"
        i18n="@@dialog.delete-button"
      >
        dialog.delete-button
      </m-button>
    </div>
  </div>
</m-modal>
