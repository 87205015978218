import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { Company, CompanyUpdateData } from "../../models/company";
import { AdminCompanyApi } from "./company.api";
import { AdminCompanyStore } from "./company.store";

export type UpdateCompanyResult = {
  status: GeneralResultStatus;
  error?: unknown;
  company?: Company;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyService {
  constructor(
    private readonly adminCompanyApi: AdminCompanyApi,
    private readonly adminCompanyStore: AdminCompanyStore,
  ) {}

  async refreshCompany() {
    const company = await this.adminCompanyApi.getCompany();
    this.adminCompanyStore.setCompany(company);
  }

  async updateCompany(data: CompanyUpdateData): Promise<UpdateCompanyResult> {
    try {
      const company = await this.adminCompanyApi.updateCompany(data);
      this.adminCompanyStore.setCompany(company);
      return {
        status: "success",
        company: company,
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }
}
