import { Injectable } from '@angular/core';
import { CompanyApi } from './company.api';
import { CompanyStore } from './company.store';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private readonly companyApi: CompanyApi,
    private readonly companyStore: CompanyStore,
  ) {}

  async refreshCompany() {
    const company = await this.companyApi.getCompany();
    this.companyStore.setCompany(company);
  }
}
