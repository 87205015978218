<div *ngIf="isModal" class="backdrop" [class.clickable]="closeOnBackDropClick" (click)="onBackDropClick()">
  <div *ngIf="closeButtonVisible" class="close-icon" (click)="onCloseClick()">
    <m-ng-icon src="close-cross.svg" [size]="16" />
  </div>
</div>

<m-generation-results
  [user]="user"
  [isModal]="isModal"
  [imageGenerations]="imageGenerations"
  [stylesBasic]="getBasicStyles()"
  [stylesAdvanced]="getAdvancedStyles()"
  [isImageGenerationsLoading]="isImageGenerationsLoading"
  (onScroll)="onScroll()"
  (onPreviewImage)="onShowImagePreviewClick($event)"
  (onSelectImage)="onSelectImage($event)"
  (onDeleteImageGeneration)="onShowDeleteImageGenerationModal($event)"
  (onDownnloadImage)="onDownloadImageClick($event)"
  (onDeleteImage)="onShowDeleteGeneratedImageModal($event)"
  (onUpscaleImage)="onMadeUpscaleImageClick($event)"
  (onUnzoomImage)="onMadeUnzoomImageClick($event)"
  (onEditImageGeneration)="onEditImageGenerationClick($event)"
/>

<div *ngIf="isModal" class="modal-divider"></div>

<m-sidebar-fixed class="sidebar-right" [class.modal]="isModal">
  <m-sidebar-tabs-new
    [selectedTab]="selectedTab"
    i18n-tab1Title="@@image-generation.sidebar.tabs.basic"
    tab1Title="image-generation.sidebar.tabs.basic"
    i18n-tab2Title="@@image-generation.sidebar.tabs.advanced"
    tab2Title="image-generation.sidebar.tabs.advanced"
    (onTabClick)="onTabClick($event)"
  >
    <div id="tab1">
      <form #formBasicElement *ngIf="formBasic" [formGroup]="formBasic" class="tab-content">
        <div class="helper-wrapper" (click)="onHelperClick()">
          <div class="description" i18n="@@image-generation.generation.helper.description">
            image-generation.generation.helper.description
          </div>
          <m-ng-icon class="arrow-next-icon" src="arrow-next-square.svg" [size]="20" />
        </div>

        <div class="divider"></div>

        <m-sidebar-section-2 title="image-generation.image-size" i18n-title="@@image-generation.image-size">
          <m-image-proportion-selector formControlName="proportion" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-sidebar-section-2 title="image-generation.image-count" i18n-title="@@image-generation.image-count">
          <m-images-count-selector formControlName="imagesCount" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-sidebar-section-2 title="image-generation.prompt" i18n-title="@@image-generation.prompt">
          <m-textarea
            formControlName="prompt"
            placeholder="image-generation.prompt.placeholder"
            i18n-placeholder="@@image-generation.prompt.placeholder"
            [rows]="5"
            [maxlength]="promptMaxLenght"
          />

          <!-- <m-prompt-restriction *ngIf="formBasic.errors?.restrictedWord" /> -->
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-styles-selector
          formControlName="styleId"
          title="image-generation.select-style"
          i18n-title="@@image-generation.select-style"
          [data]="getBasicStyles()"
          textViewAllData="image-generation.select-style.all-styles"
          i18n-textViewAllData="@@image-generation.select-style.all-styles"
        />
      </form>
    </div>

    <div id="tab2">
      <form #formAdvancedElement *ngIf="formAdvanced" [formGroup]="formAdvanced" class="tab-content">
        <m-sidebar-section-2 title="image-generation.image-size" i18n-title="@@image-generation.image-size">
          <m-image-proportion-selector formControlName="proportion" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-sidebar-section-2 title="image-generation.image-count" i18n-title="@@image-generation.image-count">
          <m-images-count-selector formControlName="imagesCount" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-sidebar-section-2
          title="image-generation.advanced-generation-mode"
          i18n-title="@@image-generation.advanced-generation-mode"
          infoText="image-generation.advanced-generation-mode.info"
          i18n-infoText="@@image-generation.advanced-generation-mode.info"
        >
          <m-image-advanced-generation-mode formControlName="advancedGenerationMode" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-sidebar-section-2 title="image-generation.prompt" i18n-title="@@image-generation.prompt">
          <m-textarea
            formControlName="prompt"
            placeholder="image-generation.prompt.placeholder"
            i18n-placeholder="@@image-generation.prompt.placeholder"
            [rows]="5"
            [maxlength]="promptMaxLenght"
          />

          <!-- <m-prompt-restriction *ngIf="formAdvanced.errors?.restrictedWord" /> -->
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-styles-selector
          formControlName="styleId"
          title="image-generation.select-style"
          i18n-title="@@image-generation.select-style"
          [data]="getAdvancedStyles()"
          [hasNewMark]="true"
          textViewAllData="image-generation.select-style.all-styles"
          i18n-textViewAllData="@@image-generation.select-style.all-styles"
        />

        <div class="divider"></div>

        <m-sidebar-section-2 title="image-generation.mood" i18n-title="@@image-generation.mood">
          <m-select
            size="m-plus"
            formControlName="mood"
            [options]="getMoodOptions()"
            noSelectedText="common.select-component.not-selected"
            i18n-noSelectedText="@@common.select-component.not-selected"
          />

          <m-info-block type="error" [labels]="moodErrors" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <!-- <m-sidebar-section-2>
          <div class="switch-section">
            <m-switch formControlName="isAgeEnable">
              <span class="select-title" i18n="@@image-generation.age">image-generation.age</span>
            </m-switch>

            <m-select
              @fadeInOut
              *ngIf="isAgeSelectVisible"
              size="m-plus"
              formControlName="age"
              [options]="getAgeOptions()"
            />

            <m-ng-input @fadeInOut *ngIf="isSetAgeYearInputVisible" formControlName="ageYear" />
          </div>
        </m-sidebar-section-2>

        <div class="divider"></div> -->

        <m-sidebar-section-2 title="image-generation.detalization" i18n-title="@@image-generation.detalization">
          <m-slider-selector formControlName="detalization" [points]="getDetalizationPoints()" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <!-- <m-sidebar-section-2 title="image-generation.contrast" i18n-title="@@image-generation.contrast">
          <m-slider-selector formControlName="contrast" [points]="getContrastPoints()" />
        </m-sidebar-section-2>

        <div class="divider"></div> -->

        <m-sidebar-section-2>
          <div class="switch-section">
            <m-switch formControlName="isColorSchemeEnable">
              <span class="select-title" i18n="@@image-generation.color-scheme">image-generation.color-scheme</span>
            </m-switch>

            <m-color-scheme-selector @fadeInOut *ngIf="isColorSchemeVisible" formControlName="colorScheme" />
          </div>
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-sidebar-section-2>
          <div class="switch-section">
            <m-switch formControlName="isNegativePromptEnable">
              <span class="select-title" i18n="@@image-generation.negative-prompt">
                image-generation.negative-prompt
              </span>
            </m-switch>

            <m-textarea
              @fadeInOut
              *ngIf="isNegativePromptVisible"
              formControlName="negativePrompt"
              placeholder="image-generation.negative-prompt"
              i18n-placeholder="@@image-generation.negative-prompt"
              [rows]="5"
              [maxlength]="promptMaxLenght"
            />
          </div>
        </m-sidebar-section-2>
      </form>
    </div>
  </m-sidebar-tabs-new>

  <div class="buttons">
    <m-button
      *ngIf="!isModal"
      button-style="secondary"
      icon-position="only"
      icon="button-back.svg"
      (onClick)="onBackClick()"
    />

    <m-button button-style="primary" size="full-width" (onClick)="onGenerationClick()">
      <span [class.not-enought-tokens]="!isEnoughtTokens()" i18n="@@image-generation.generate">
        image-generation.generate
      </span>
      <div class="price" [class.not-enought-tokens]="!isEnoughtTokens()">
        <m-ng-icon class="token-icon" src="/assets/img/app-bar-menu/token-simple-{theme}.svg" [size]="20" />
        <span *ngIf="igBasicPrice || igAdvancedPrice">{{getPrice()}}</span>
      </div>
    </m-button>
  </div>

  <m-low-balance-modal
    *ngIf="isLowBalanceModalVisible && (tariffsForUpgrade$ | async) as tariffs"
    @fadeInOut
    [tariffs]="tariffs"
    [balanceData]="imageGenerationPaymentData"
    [activeSubscription]="activeSubscription"
    [isImageGeneration]="true"
    (close)="closePricingModal()"
    (buySubscription)="onBuySubscription($event)"
  />

  <m-preview-image-modal
    *ngIf="previewGeneratedImage && previewImageGeneration"
    [prices]="prices"
    [imageGeneration]="previewImageGeneration"
    [generatedImage]="previewGeneratedImage"
    [hasPrevious]="hasPreviousImage()"
    [hasNext]="hasNextImage()"
    [isModal]="isModal"
    (onPrevious)="onPreviousClick()"
    (onNext)="onNextClick()"
    (onSelect)="onSelectImageFromModalClick($event)"
    (onClose)="onCloseImagePreviewClick()"
  ></m-preview-image-modal>

  <m-modal-confirmation
    *ngIf="imageGenerationForDelete"
    text="image-generation.generation.delete-generation-confirmation"
    i18n-text="@@image-generation.generation.delete-generation-confirmation"
    confirmText="dialog.delete-button"
    i18n-confirmText="@@dialog.delete-button"
    cancelText="dialog.cancel-button"
    i18n-cancelText="@@dialog.cancel-button"
    (onConfirm)="deleteImageGeneration()"
    (onClose)="onCloseDeleteImageGenerationModal()"
  />

  <m-modal-confirmation
    *ngIf="generatedImageForDelete"
    [text]="modalConfirmationDeleteImageText"
    confirmText="dialog.delete-button"
    i18n-confirmText="@@dialog.delete-button"
    cancelText="dialog.cancel-button"
    i18n-cancelText="@@dialog.cancel-button"
    (onConfirm)="deleteImage()"
    (onClose)="onCloseDeleteGeneratedImageModal()"
  />
</m-sidebar-fixed>

<m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>
