<m-ng-island>
  <div class="container">
    <div class="header-group">
      <div class="title" i18n="@@home.promo-menu-buttons.title">home.promo-menu-buttons.title</div>
      <div class="description" i18n="@@home.promo-menu-buttons.description">home.promo-menu-buttons.description</div>
    </div>

    <m-ng-icon class="icon-stars-1" src="stars-1.svg" [size]="200" />
    <m-ng-icon class="icon-stars-2" src="stars-2.svg" [size]="340" />

    <div class="buttons-group">
      <div *ngFor="let b of buttons; trackBy: trackByButtonId " class="button">
        <div class="button-background-wrapper">
          <img class="button-background" [src]="b.iconScr" />
        </div>
        <m-localized-text class="button-text" [text]="b.title" />
        <m-ng-icon *ngIf="!b.hasSoonMark" class="button-icon-arrow" src="open-link.svg" [size]="16" />
        <m-soon-mark *ngIf="b.hasSoonMark" />
        <div class="button-zone" (click)="onClick(b.action)"></div>
      </div>
    </div>
  </div>
</m-ng-island>
