import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import * as _ from "lodash-es";
import { BehaviorSubject } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { StyleSettings } from "../../../../models/styles";
import { EditorBlock } from "../../../../views/markup-editor/editor.models";
import { TextEditor } from "../../../text-editor/text-editor/text-editor.view";
import { BlockMergeEvent, BlockMovementEvent, BlockSplitEvent } from "../../editor.events";
import { Footnote, TextSelectionState } from "../../editor.service";
import { BlockDelegate } from "../block-delegate";
import { FlatListData, ListData, ListStyle } from "./interfaces/list-data.interface";
import { ListItemView } from "./list-item-view/list-item-view";

@Component({
  selector: "m-markup-editor-list-block",
  templateUrl: "./list-block.component.html",
  styleUrls: ["./list-block.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListBlockComponent implements BlockDelegate, OnInit {
  @Input() data!: EditorBlock;
  @Input() styleDisplayOpts: StyleSettings | null = null;

  @ViewChildren(ListItemView)
  listItems: QueryList<ListItemView> | undefined = undefined;

  @Output()
  footnoteAdded: EventEmitter<Footnote[]> = new EventEmitter();
  @Output()
  footnoteChanged: EventEmitter<Footnote[]> = new EventEmitter();
  @Output()
  contentChanged = new EventEmitter<EditorBlock>();

  protected items: FlatListData[] = [];

  protected listContextMenuVisible: boolean[] = [];
  protected listContextMenuPositionX: number[] = [];
  // TODO use currentActiveIndex instead
  protected currentItemIndex = 0;
  protected currentActiveIndex: number | null = null;

  protected startingNotesIndexes: number[] = [];

  private indexSelectionEvent$ = new BehaviorSubject<number>(0);

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.indexSelectionEvent$.pipe().subscribe((index) => {
      this.currentItemIndex = index;
      this.setCaretToBegin();
    });

    this.items = this.flattenItems(this.data.data.items);
  }

  onListItemBlur(newData: FlatListData) {
    const data = this.items.find((item) => item.uniqueId === newData.uniqueId);
    if (data) {
      data.content = newData.content;
    }
    if (this.items.length > 0) {
      const nestedItems = this.makeNestedFromFlat(this.items);
      this.data.data.items = nestedItems;
    }

    this.contentChanged.emit(this.data.data);
  }

  onContextMenuItemClick(event: any, index: number) {
    if (this.listItems) {
      const level = this.items[index].level;

      for (const listItem of this.items) {
        if (listItem.level === level) {
          listItem.style = event.listStyleMarker;
        }
      }
      this.updateMarkersExternally();
      this.cdr.detectChanges();
      const nestedItems = this.makeNestedFromFlat(this.items);
      this.data.data.items = nestedItems;
    }
  }

  getContextMenuStyle(index: number) {
    const styles = {
      position: "absolute",
      left: `${this.listContextMenuPositionX[index]}px`,
      top: "unset",
      "z-index": 1,
    };
    return styles;
  }

  onContextMenu(event: MouseEvent, index: number): void {
    event.preventDefault();
    if (this.currentActiveIndex !== null) {
      this.listContextMenuVisible[this.currentActiveIndex] = false;
    }
    this.listContextMenuPositionX[index] = event.clientX;
    this.listContextMenuVisible[index] = true;
    this.currentActiveIndex = index;
  }

  flattenItems(items: ListData[], result: FlatListData[] = [], parent?: FlatListData) {
    let index = 1;
    for (const item of items) {
      const newItem: FlatListData = {
        ...item,
        parent,
        index,
        uniqueId: this.generateUniqueId(),
      };
      index = index + 1;

      result.push(newItem);

      if (item.items && item.items.length > 0) {
        this.flattenItems(item.items, result, newItem);
      }
    }
    return result;
  }

  makeNestedFromFlat(flattenedItems: FlatListData[]): ListData[] {
    const nestedItems: ListData[] = [];
    const stack: { parent: ListData; level: number; content: string; style: ListStyle }[] = [];

    for (const flattenedItem of flattenedItems) {
      const listItem: ListData = {
        ...(_.omit(flattenedItem, ["parent", "items"]) as FlatListData),
        content: flattenedItem.content,
        items: [],
      };

      const styleToUse = flattenedItem.style;
      listItem.style = styleToUse;

      while (stack.length > 0 && stack[stack.length - 1].level >= flattenedItem.level) {
        stack.pop();
      }

      if (stack.length > 0) {
        stack[stack.length - 1].parent.items.push(listItem);
      } else {
        nestedItems.push(listItem);
      }

      stack.push({
        parent: listItem,
        level: flattenedItem.level,
        content: listItem.content,
        style: listItem.style,
      });
    }

    this.cdr.detectChanges();
    return nestedItems;
  }

  onSplit(event: BlockSplitEvent) {
    const currentItem = this.items[this.currentItemIndex];

    const newStyle = currentItem.style;

    const newItem: FlatListData = {
      content: event.text,
      level: currentItem.level,
      style: newStyle,
      parent: currentItem.parent,
      index: currentItem.index + 1,
      uniqueId: this.generateUniqueId(),
    };

    for (let i = this.currentItemIndex + 1; i < this.items.length; i++) {
      const item = this.items[i];
      if (item.level > currentItem.level) {
        continue;
      }
      if (item.level < currentItem.level) {
        break;
      }
      item.index++;
    }

    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (item.parent === currentItem) {
        item.parent = newItem;
      }
    }

    this.items.splice(this.currentItemIndex + 1, 0, newItem);
    this.cdr.detectChanges();
    this.selectItem(this.currentItemIndex + 1);
    this.updateMarkersExternally();
  }

  onMergeWithPrev(event: BlockMergeEvent) {
    if (this.currentItemIndex > 0) {
      const currentItem = this.items[this.currentItemIndex];
      const prevItem = this.items[this.currentItemIndex - 1];
      prevItem.content += ` ${currentItem.content}`;
      this.items.splice(this.currentItemIndex, 1);
      this.currentItemIndex--;

      for (let i = this.currentItemIndex + 1; i < this.items.length; i++) {
        const item = this.items[i];
        if (item.parent === currentItem) {
          item.parent = prevItem;
        }
      }
      for (let i = this.currentItemIndex + 1; i < this.items.length; i++) {
        const item = this.items[i];
        if (item.level === currentItem.level) {
          item.index--;
        }
      }

      this.cdr.detectChanges();
      this.selectItemAndSetCursor(this.currentItemIndex, "end");
      const nestedItems = this.makeNestedFromFlat(this.items);
      this.data.data.items = nestedItems;
      this.updateMarkersExternally();
    }
  }

  updateMarkersExternally() {
    if (this.listItems) {
      this.collectFootnotes(); // TODO place in different method (?)
      for (const listItem of this.listItems) {
        listItem.updateMarker();
      }
    }
  }

  onListItemFocus(index: number) {
    this.currentItemIndex = index;
  }

  onBlockMovement(event: BlockMovementEvent) {
    if (event.direction === "next") {
      this.selectItem(this.currentItemIndex + 1);
    } else if (event.direction === "prev") {
      this.selectItem(this.currentItemIndex - 1);
    }
  }

  getTextSelection(): TextSelectionState | undefined {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      return te.getTextSelectionState();
    }
    return undefined;
  }

  applyBold(): void {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      te.applyBold();
    }
  }

  applyItalick(): void {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      te.applyItalic();
    }
  }

  addFootnote(): void {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      te.addFootnote();
    }
  }

  updateFootnotesText(footnotes: Footnote[]) {
    // const te = this.getActiveListItemTextEditor();
    // if (te) {
    //   return te.updateFootnotesText(footnotes);
    // }
    //
    if (!this.listItems) {
      return;
    }
    for (let i = 0; i < this.listItems.length; i++) {
      const item = this.listItems.get(i);
      item?.textEditor?.updateFootnotesText(footnotes);
    }
  }

  onFootnoteAdded(footnotes: Footnote[]) {
    this.footnoteAdded.emit(this.collectFootnotes());
  }

  onFootnoteChanged(footnotes: Footnote[]) {
    this.footnoteChanged.emit(this.collectFootnotes());
  }

  private collectFootnotes(): Footnote[] {
    this.startingNotesIndexes = [];

    const notes: Footnote[] = [];
    if (!this.listItems) {
      return [];
    }

    let noteIndex = 1;
    for (let i = 0; i < this.listItems.length; i++) {
      const item = this.listItems.get(i);
      this.startingNotesIndexes[i] = noteIndex;

      const itemNotes = item!.textEditor?.refreshFootnotes();
      if (itemNotes) {
        for (let j = 0; j < itemNotes.length; j++) {
          itemNotes[j].id = noteIndex;
          noteIndex++;
          notes.push(itemNotes[j]);
        }
      }
    }

    this.cdr.detectChanges();

    return notes;
  }

  removeFootnote(id: number): void {
    if (!this.listItems) {
      return;
    }
    for (let i = 0; i < this.listItems.length; i++) {
      const item = this.listItems.get(i);
      const isRemoved = item?.textEditor?.removeFootnote(id);
      if (isRemoved) {
        break;
      }
    }
  }

  setCaretToBegin(): void {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      te.setCaretToBegin();
    }
  }

  setCaretToEnd(): void {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      te.setCaretToEnd();
    }
  }

  appendTextAndFocus(text: string): void {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      te.appendTextAndFocus(text);
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick(event: any): void {
    for (let i = 0; i < this.listContextMenuVisible.length; i++) {
      this.listContextMenuVisible[i] = false;
    }
  }

  @HostListener("keydown.tab", ["$event"])
  onTabKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    this.tabItemRight();
  }

  @HostListener("keydown.shift.tab", ["$event"])
  onShiftDeleteKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    this.tabItemLeft();
  }

  trackByFn(index: number, item: FlatListData): string {
    return item.uniqueId!;
  }

  private updateItemMarkers() {
    const nestedItems = this.makeNestedFromFlat(this.items);
    this.data.data.items = nestedItems;
    this.updateMarkersExternally();
  }

  private tabItemLeft() {
    if (this.currentItemIndex >= 0) {
      const currentItem = this.items[this.currentItemIndex];
      if (currentItem.level > 0) {
        currentItem.level -= 1;
      }
    }
    this.recalcIndexes();
    this.updateItemMarkers();
    this.cdr.markForCheck();
  }

  private tabItemRight() {
    if (this.currentItemIndex >= 0) {
      const currentItem = this.items[this.currentItemIndex];
      const prevItem = this.items[this.currentItemIndex - 1];

      if (prevItem && prevItem.level >= currentItem.level) {
        currentItem.level += 1;
        this.recalcIndexes();
        this.updateMarkersExternally();
        this.cdr.markForCheck();
      }
    }
  }

  private recalcIndexes() {
    this.reindex(this.items, 0, undefined);
  }

  private reindex(items: FlatListData[], index: number, prevLevelParent?: FlatListData) {
    if (index >= items.length - 1) {
      return;
    }

    const currentItem = items[index];
    const nextItem = items[index + 1];

    if (nextItem.level > currentItem.level) {
      nextItem.parent = currentItem;
      nextItem.index = 1;

      this.reindex(items, index + 1, currentItem);
    } else if (nextItem.level === currentItem.level) {
      nextItem.parent = currentItem.parent;
      nextItem.index = currentItem.index + 1;

      this.reindex(items, index + 1, prevLevelParent);
    } else {
      // find parent with lavel more than next item level, so we dont skip levels
      while (prevLevelParent && prevLevelParent.level > nextItem.level) {
        prevLevelParent = prevLevelParent.parent;
      }
      if (prevLevelParent) {
        nextItem.parent = prevLevelParent.parent;
        nextItem.index = prevLevelParent.index + 1;
        this.reindex(items, index + 1, prevLevelParent.parent);
      } else {
        nextItem.parent = undefined;
        // find last item with no parent and base current index off found one
        for (let i = index; i >= 0; i--) {
          if (!items[i].parent) {
            nextItem.index = i + 1;
            break;
          }
        }

        this.reindex(items, index + 1, undefined);
      }
    }
  }

  private findItemUpWithLevel(index: number, level: number): FlatListData | undefined {
    let parentIndex = index - 1;
    while (parentIndex >= 0 && this.items[parentIndex].level >= level) {
      parentIndex--;
    }

    if (parentIndex < 0) {
      return undefined;
    }
    return this.items[parentIndex];
  }

  private selectItemAndSetCursor(index: number, setCursor: "start" | "end") {
    this.indexSelectionEvent$.next(index);
    this.setCursorPosition(setCursor);
  }

  private setCursorPosition(setCursor: "start" | "end") {
    const te = this.getActiveListItemTextEditor();

    if (te) {
      if (setCursor === "start") {
        te.setCaretToBegin();
      } else if (setCursor === "end") {
        te.setCaretToEnd();
      }
    }
  }

  private selectItem(index: number) {
    this.indexSelectionEvent$.next(index);
  }

  private generateUniqueId(): string {
    return uuidv4();
  }

  private getActiveListItemTextEditor(): TextEditor | undefined {
    const currentItem = this.listItems?.get(this.currentItemIndex);
    return currentItem?.textEditor;
  }
}
