import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { AdminCompanyCredsSettingsApi } from "./companies-creds.api";
import { AdminCompanyCredsSettingsStore } from "./companies-creds.store";
import { CompanyCloudPaymentsSettings, CompanyS3Settings } from "../../models/company";
import { CompanyUnisenderCreds } from "../../models/unisender-settings";

export type UpdateCompanySettingsResult = {
  status: GeneralResultStatus;
  error?: unknown;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyCredsService {
  constructor(
    private readonly api: AdminCompanyCredsSettingsApi,
    private readonly store: AdminCompanyCredsSettingsStore,
  ) { }

  async refreshS3Settings() {
    const settings = await this.api.getS3Settings();
    this.store.setS3Settings(settings);
  }

  async updateS3Settings(data: CompanyS3Settings): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updateS3Settings(data);
      this.store.setS3Settings(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  async deleteS3Settings(): Promise<{ status: GeneralResultStatus }> {
    try {
      await this.api.deleteS3Settings();
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
      };
    }
  }

  async refreshCloudPaymentsSettings() {
    const settings = await this.api.getCloudPaymentsSettings();
    this.store.setCloudPaymentsSettings(settings);
  }

  async updateCloudPaymentsSettings(data: CompanyCloudPaymentsSettings): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updateCloudPaymentsSettings(data);
      this.store.setCloudPaymentsSettings(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  async deleteCloudPaymentsSettings(): Promise<{ status: GeneralResultStatus }> {
    try {
      await this.api.deleteCloudPaymentsSettings();
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
      };
    }
  }

  async refreshUnisenderCreds() {
    const settings = await this.api.getUnisenderCreds();
    this.store.setUnisenderCreds(settings);
  }

  async updateUnisenderCreds(data: CompanyUnisenderCreds): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updateUnisenderCreds(data);
      this.store.setUnisenderCreds(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  async deleteUnisenderCreds(): Promise<{ status: GeneralResultStatus }> {
    try {
      await this.api.deleteUnisenderCreds();
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
      };
    }
  }
}
