<m-ng-island class="header">
  <h1 i18n="@@books.list.title">books.list.title</h1>

  <m-button button-style="primary" i18n="@@books.book.create-button" (onClick)="onNewBookClick()">
    books.book.create-button
  </m-button>
</m-ng-island>

<div class="empty" *ngIf="!(books$ | async)?.length">
  <img src="/assets/img/books-empty.png" alt="" class="empty-icon" />
  <h2 i18n="@@books.list.empty">books.list.empty</h2>
  <div>
    <m-button button-style="primary" i18n="@@books.book.create-button" (onClick)="onNewBookClick()">
      books.book.create-button
    </m-button>
  </div>
</div>

<m-book-card
  class="book"
  *ngFor="let book of books$ | async"
  [book]="book"
  (onEditClick)="setBookToEdit(book)"
  (onDeleteClick)="setBookToDelete(book)"
></m-book-card>

<!-- <m-book-data-modal
  *ngIf="bookToEdit"
  [step]="bookToEditStep"
  [book]="bookToEdit"
  [showOnePageAtTime]="true"
  (onUpdateBookInfoData)="onInfoSet($event, false)"
  (onUpdateBookSizeData)="onBookSizeSet($event, false)"
  (onEndUpdateBookInfoData)="onInfoSet($event, true)"
  (onEndUpdateBookSizeData)="onBookSizeSet($event, true)"
  (close)="cancelEdition()"
  (backClick)="cancelEdition()"
/> -->

<m-delete-project-modal
  *ngIf="bookToDelete"
  [project-title]="bookToDelete.title"
  (onCancel)="cancelDeletion()"
  (onDelete)="deleteBook(bookToDelete)"
/>

<m-info-modal
  *ngIf="isChangeTariffModalVisible"
  [title]="getInfoModalTitle()"
  [text]="getInfoModalText()"
  actionText="books.info-modal.projects-count-limit.action-text"
  i18n-actionText="@@books.info-modal.projects-count-limit.action-text"
  [backgroundImage]="'/assets/img/info-modal-projects-count-limit.png'"
  (actionClick)="onClickChangeTariffModal()"
  (close)="onCloseChangeTariffModal()"
/>

<m-modal-project-router-host />
