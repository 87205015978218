import { Component, type OnDestroy, type OnInit, Renderer2 } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  Book,
  Dimensions,
  MarginKey,
  Margins,
  MarginsState,
  PagePosition,
  RunningTitleMargins,
  RunningTitleMarginsKey,
  RunningTitlesState,
} from "@metranpage/book-data";
import { CompanyStore } from "@metranpage/company";
import {
  InfoBlockData,
  NavTab,
  PaletteDTO,
  SelectValue,
  fadeInOutOnEnterLeave,
  slideInOutSidebarLTR,
  slideInOutVertical,
} from "@metranpage/components";
import { LoadingService, filterUndefined } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { OnboardingService } from "@metranpage/onboarding";
import { PricingViewService } from "@metranpage/pricing";
import { UserStore } from "@metranpage/user-data";
import { UntilDestroy } from "@ngneat/until-destroy";
import * as _ from "lodash-es";
import { Subscription, timer } from "rxjs";
import { first } from "rxjs/operators";
import { BooksStore } from "../../services/books.store";
import { MarginsService } from "../../services/margins.service";
import { MarginsStore } from "../../services/margins.store";
import { RunningTitlesService } from "../../services/running-titles.service";
import { RunningTitlesStore } from "../../services/running-titles.store";
import { RunningTitlesPositions } from "../../views/running-titles-position-group/running-titles-position-group.component";

export type RunningTitleStyleName = "runningTitle" | "pageNumber";

@UntilDestroy()
@Component({
  selector: "m-running-titles-page",
  templateUrl: "./running-titles.page.html",
  styleUrls: ["./running-titles.page.scss"],
  animations: [fadeInOutOnEnterLeave, slideInOutVertical, slideInOutSidebarLTR],
})
export class RunningTitlesPage implements OnInit, OnDestroy {
  protected pageSize: Dimensions = { width: 100, height: 150 };
  protected fontSizeRunningTitles = 12;
  protected title = "";
  protected author = "";

  protected marginsState!: MarginsState;

  protected runningTitlesOptions = [
    { id: 2, editable: true, value: $localize`:@@books.running-titles.options.author:` },
    { id: 1, editable: true, value: $localize`:@@books.running-titles.options.book-name:` },
    // { id: 3, editable: false, value: $localize`:@@books.running-titles.options.chapters-name:` },
    { id: 0, editable: true, value: $localize`:@@books.running-titles.options.custom:` },
    // { id: 4, editable: false, value: $localize`:@@books.running-titles.options.empty:` },
  ];

  protected runningTitlesPositions: RunningTitlesPositions[] = [
    { value: 0, disabled: false },
    { value: 2, disabled: false },
    { value: 1, disabled: false },
  ];

  protected activeSidebarTabNumber = 1;
  protected isMarginsBordersHide = false;

  protected isRunningTitlesSettingsVisible = false;
  protected isPageNumbersSettingsVisible = false;
  protected runningTitlesTextWidthErrors: PagePosition[] = [];
  protected smallMargins: MarginKey[] = [];

  protected minimalMarginsSize: RunningTitleMargins = {
    marginTop: 15,
    marginBottom: 15,
    marginOuter: 20,
  };

  protected palette?: PaletteDTO;

  protected form!: FormGroup;

  protected isLoading = true;

  protected book?: Book;

  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;

  protected hasOnboarding = true;
  protected timeoutOnboarding = 2000;

  protected navTabs: NavTab[] = [
    { id: "margins", isActive: false, text: $localize`:@@books.nav-tabs.margins:` },
    { id: "running-titles", isActive: true, text: $localize`:@@books.nav-tabs.running-titles:` },
  ];

  protected canProduceEpub = true;

  private sub = new Subscription();

  constructor(
    private readonly marginsService: MarginsService,
    private readonly runningTitlesService: RunningTitlesService,
    private readonly booksStore: BooksStore,
    private readonly pricingViewService: PricingViewService,
    private readonly marginsStore: MarginsStore,
    private readonly runningTitlesStore: RunningTitlesStore,
    private readonly router: Router,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly onboardingService: OnboardingService,
    userStore: UserStore,
    companyStore: CompanyStore,
    renderer: Renderer2,
  ) {
    marginsService.renderer = renderer;

    this.sub.add(
      this.onboardingService.onStartOnboarding$.pipe().subscribe(() => {
        this.startOnboarding(true);
      }),
    );

    this.sub.add(
      userStore
        .getActiveSubscriptionObservable()
        .pipe(filterUndefined())
        .subscribe((activeSubscription) => {
          this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
          this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;

          if (!this.hasPaidTariff && !this.hasTrialPeriod) {
            this.hasOnboarding = false;
            this.router.navigate(["books"]);
          }
        }),
    );

    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.canProduceEpub = company.canProduceEpub;
        }),
    );
  }

  ngOnInit(): void {
    this.sub.add(
      this.booksStore
        .getActiveBookObservable()
        .pipe(filterUndefined(), first())
        .subscribe(async (book) => {
          this.book = book;

          if (!book.exportPrint || !book.bookSettings?.isEditable) {
            this.hasOnboarding = false;
            this.router.navigate(["books", this.book?.id, "markup"]);
          }

          this.palette = _.pick(book.bookSettings!, ["colorAccent", "colorPrimary", "colorSecondary", "colorDecor"]);

          this.initState(book);
        }),
    );
  }

  async ngAfterViewInit() {
    this.sub.add(
      timer(this.timeoutOnboarding).subscribe(() => {
        this.startOnboarding();
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  startOnboarding(showForced = false) {
    if (!this.hasOnboarding) {
      return;
    }
    this.onboardingService.startOnboarding("running-titles-page", showForced, 500);
  }

  private initState(book: Book) {
    this.pageSize = _.pick(book.bookSettings!, ["width", "height"]);
    this.fontSizeRunningTitles = book.bookSettings!.mainSize - 2;
    this.title = book.title;
    this.author = `${book.authorLastName ?? ""} ${book.authorFirstName ?? ""} ${book.authorMiddleName ?? ""}`.trim();

    const margins: Margins = _.pick(book.bookSettings!, [
      "marginTop",
      "marginBottom",
      "marginOuter",
      "marginInner",
      "gutter",
      "widePadding",
    ]);

    const partMarginsState = _.pick(book.bookSettings!, [
      "widePaddingState",
      "widePaddingLeftPosition",
      "widePaddingRightPosition",
      "columnsCount",
    ]);

    this.marginsState = {
      ...partMarginsState,
      margins: margins,
      spaceLevel: 1,
      lastChangeSource: "sidebar",
    };

    this.marginsStore.setMarginsState(this.marginsState);

    const partRunningTitlesState = _.pick(book.bookSettings!, [
      "runningTitleState",
      "runningTitleLeft",
      "runningTitleLeftText",
      "runningTitleRight",
      "runningTitleRightText",
      "runningTitlePosition",
      "runningTitleStyle",
      "pageNumberState",
      "pageNumberPosition",
      "pageNumberStyle",
    ]);

    const runningTitlesState: RunningTitlesState = {
      ...partRunningTitlesState,
      fontSizeInPixeles: 0,
    };

    this.runningTitlesStore.setRunningTitlesState(runningTitlesState);

    this.isLoading = false;
    this.subscribeToUpdates();
  }

  private subscribeToUpdates() {
    this.sub.add(
      this.runningTitlesStore
        .getRunningTitlesStateObservable()
        .pipe(first())
        .subscribe((state) => {
          this.initForm(state);

          this.watchFormChanges();

          this.checkMarginsSize(state);
        }),
    );
  }

  private initForm(state: RunningTitlesState) {
    const leftText = this.getText(state.runningTitleLeft, state.runningTitleLeftText);
    this.setText("left", leftText);
    const rightText = this.getText(state.runningTitleRight, state.runningTitleRightText);
    this.setText("right", rightText);

    this.form = new FormGroup({
      runningTitleState: new FormControl(state.runningTitleState),
      runningTitlePosition: new FormControl(state.runningTitlePosition, [Validators.required]),
      runningTitleStyle: new FormControl(state.runningTitleStyle, [Validators.required]),
      runningTitleLeft: new FormControl(state.runningTitleLeft, [Validators.required]),
      runningTitleLeftText: new FormControl(leftText, [Validators.required]),
      runningTitleRight: new FormControl(state.runningTitleRight, [Validators.required]),
      runningTitleRightText: new FormControl(rightText, [Validators.required]),
      pageNumberState: new FormControl(state.pageNumberState),
      pageNumberPosition: new FormControl(state.pageNumberPosition, [Validators.required]),
      pageNumberStyle: new FormControl(state.pageNumberStyle, [Validators.required]),
    });
  }

  watchFormChanges() {
    // RunningTitle settings
    // RunningTitle settings
    this.form.controls["runningTitleState"].valueChanges.subscribe((value) => {
      this.runningTitlesStore.setRunningTitleState(value);
      this.setTextFieldsValidators(value);
      if (!value) {
        this.isRunningTitlesSettingsVisible = false;
      }
    });
    this.form.controls["runningTitlePosition"].valueChanges.subscribe((position: number) => {
      this.runningTitlesStore.setRunningTitlePosition(position);
      this.updatePositionOrientation("pageNumber");
      this.updatePositionForCenteredStyles("runningTitle");
    });
    this.form.controls["runningTitleStyle"].valueChanges.subscribe((style: number) => {
      this.runningTitlesStore.setRunningTitleStyle(style);
      this.updatePositionForCenteredStyles("runningTitle");
    });
    this.form.controls["runningTitleLeft"].valueChanges.subscribe((value) => {
      this.changeSelectedType("right", value);
      this.updateText("left", value);
      this.runningTitlesStore.setRunningTitleLeft(value);
    });
    this.form.controls["runningTitleLeftText"].valueChanges.subscribe((text: string) => {
      this.changeSelectorToCustom("left");
      this.runningTitlesStore.setRunningTitleLeftText(text);
    });
    this.form.controls["runningTitleRight"].valueChanges.subscribe((value) => {
      this.changeSelectedType("left", value);
      this.updateText("right", value);
      this.runningTitlesStore.setRunningTitleRight(value);
    });
    this.form.controls["runningTitleRightText"].valueChanges.subscribe((text: string) => {
      this.changeSelectorToCustom("right");
      this.runningTitlesStore.setRunningTitleRightText(text);
    });

    // Page Number settings
    // Page Number settings
    this.form.controls["pageNumberState"].valueChanges.subscribe((value) => {
      this.runningTitlesStore.setPageNumberState(value);
      if (!value) {
        this.isPageNumbersSettingsVisible = false;
        this.form.controls["pageNumberStyle"].setValue(1);
      }
    });
    this.form.controls["pageNumberPosition"].valueChanges.subscribe((position: number) => {
      this.runningTitlesStore.setPageNumberPosition(position);
      this.updatePositionOrientation("runningTitle");
      this.updatePositionForCenteredStyles("pageNumber");

      if (this.isPageNumberSettingsIconDisabled() && this.isPageNumbersSettingsVisible) {
        this.isPageNumbersSettingsVisible = false;
      }
    });
    this.form.controls["pageNumberStyle"].valueChanges.subscribe((style: number) => {
      this.runningTitlesStore.setPageNumberStyle(style);
      this.updatePositionForCenteredStyles("pageNumber");
    });
  }

  private checkMarginsSize(state: RunningTitlesState) {
    const runningTitleMargins: MarginKey[] = ["marginTop", "marginBottom", "marginOuter"];
    for (const marginKey of runningTitleMargins) {
      if (this.marginsState.margins[marginKey] >= this.minimalMarginsSize[marginKey as RunningTitleMarginsKey]) {
        continue;
      }
      this.smallMargins.push(marginKey);
      const position = this.runningTitlesService.convertMarginKeyToPosition(marginKey);
      const positionItem = this.runningTitlesPositions.find((item) => item.value === position);
      if (positionItem) {
        positionItem.disabled = true;

        const positionEnable = this.runningTitlesPositions.find((item) => !item.disabled)?.value ?? 0;
        if (this.runningTitlesStore.getRunningTitlePosition() === positionItem.value) {
          this.form.patchValue({ runningTitlePosition: positionEnable });
          this.runningTitlesStore.setRunningTitlePosition(positionEnable);
        }
        if (this.runningTitlesStore.getPageNumberPosition() === positionItem.value) {
          this.form.patchValue({ pageNumberPosition: positionEnable });
          this.runningTitlesStore.setPageNumberPosition(positionEnable);
        }
      }
    }

    if (this.smallMargins.length === 3) {
      this.form.controls["runningTitleState"].setValue(false);
      this.form.controls["runningTitleState"].disable();
      this.form.controls["pageNumberState"].setValue(false);
      this.form.controls["pageNumberState"].disable();
    } else {
      if (state.runningTitleState) {
        this.form.controls["runningTitleState"].setValue(true);
      }
      if (state.pageNumberState) {
        this.form.controls["pageNumberState"].setValue(true);
      }
    }
  }

  private setTextFieldsValidators(state: boolean) {
    for (const pagePosition of ["left", "right"]) {
      const controlName = `runningTitle${this.runningTitlesService.capitalizeFirstLetter(pagePosition)}Text`;

      if (state) {
        this.form.controls[controlName].setValidators([Validators.required]);
      } else {
        this.form.controls[controlName].clearValidators();
        this.form.controls[controlName].updateValueAndValidity();
      }
    }
  }

  private changeSelectedType(position: PagePosition, value: number) {
    const controlName = `runningTitle${this.runningTitlesService.capitalizeFirstLetter(position)}`;
    if (value !== 0 && this.form.controls[controlName].value === value) {
      const anotherValue = this.runningTitlesOptions.find((element) => element.id !== value)?.id;
      this.form.controls[controlName].setValue(anotherValue);
    }
  }

  protected changeSelectorToCustom(pagePosition: PagePosition) {
    const customPosition = 0;
    if (pagePosition === "left") {
      this.form.controls["runningTitleLeft"].setValue(customPosition, { emitEvent: false });
      this.runningTitlesStore.setRunningTitleLeft(customPosition);
    } else {
      this.form.controls["runningTitleRight"].setValue(customPosition, { emitEvent: false });
      this.runningTitlesStore.setRunningTitleRight(customPosition);
    }
  }

  private getText(option: number, text = "") {
    switch (option) {
      case 0:
        break;
      case 1:
        text = this.title;
        break;
      case 2:
        text = this.author;
        break;
      case 3:
        text = $localize`:@@books.running-titles.chapter-name:`;
        break;
      default:
        text = "";
        break;
    }
    return text;
  }

  private updateText(pagePosition: PagePosition, option: number) {
    const text = this.getText(option, "");
    const controlName = `runningTitle${this.runningTitlesService.capitalizeFirstLetter(pagePosition)}Text`;
    this.form.controls[controlName].setValue(text, { emitEvent: false });
    this.setText(pagePosition, text);
  }

  protected toggleRunningTitlesSettings() {
    this.isPageNumbersSettingsVisible = false;
    this.isRunningTitlesSettingsVisible = !this.isRunningTitlesSettingsVisible;
  }

  protected togglePageNumbersSettings() {
    if (this.isPageNumberSettingsIconDisabled()) {
      return;
    }
    this.isRunningTitlesSettingsVisible = false;
    this.isPageNumbersSettingsVisible = !this.isPageNumbersSettingsVisible;
  }

  protected async onBackClick() {
    if (!this.form.valid) {
      this.router.navigate(["books", this.booksStore.getActiveBook()?.id, "margins"]);
      return;
    }
    const activeBook = this.booksStore.getActiveBook()!;
    const result = await this.save(activeBook);
    if (result === "success") {
      this.router.navigate(["books", this.booksStore.getActiveBook()?.id, "margins"]);
    }
  }

  protected async onNextClick() {
    if (!this.form.valid) {
      return;
    }
    const activeBook = this.booksStore.getActiveBook()!;
    const result = await this.save(activeBook);
    if (result === "success") {
      this.router.navigate(["books", activeBook.id, "preview"]);
    }
  }

  protected async onNavTabClick(navTab: NavTab) {
    if (navTab.id === "running-titles") {
      return;
    }

    const activeBook = this.booksStore.getActiveBook()!;

    if (!this.form.valid) {
      this.router.navigate(["books", activeBook.id, navTab.id]);
      return;
    }

    const result = await this.save(activeBook);
    if (result === "success") {
      this.router.navigate(["books", activeBook.id, navTab.id]);
    }
  }

  private async save(activeBook: Book) {
    this.loadingService.startLoading({
      fullPage: true,
      description: $localize`:@@books.running-titles.action-save-running-titles-state:`,
    });
    const result = await this.runningTitlesService.updateState(activeBook);
    if (result === "success") {
      this.loadingService.stopLoading();
    } else {
      this.notificationService.error($localize`:@@books.error.save-running-titles-state:`);
      this.loadingService.stopLoading();
    }

    return result;
  }

  protected onLayoutEpubClick() {}

  protected isPageNumberSettingsIconDisabled() {
    if (!this.runningTitlesStore.getRunningTitleState()) {
      return false;
    }
    return this.runningTitlesStore.getRunningTitlePosition() === this.runningTitlesStore.getPageNumberPosition();
  }

  protected isRunningTitleInputVisible(position: PagePosition) {
    const controlName = `runningTitle${this.runningTitlesService.capitalizeFirstLetter(position)}`;
    const id = this.form.controls[controlName].value ?? 0;
    return this.runningTitlesOptions.find((item) => item.id === id)?.editable;
  }

  protected getRunningTitleOptions(): SelectValue[] {
    return this.runningTitlesOptions;
  }

  protected pluralizeChargeOff() {
    const bookPrice = this.booksStore.getBookPrice(this.booksStore.getActiveBook()!.id) ?? 0;

    return `${$localize`:@@books.margins.sidebar-tabs.ebook.price:`} ${this.pricingViewService.pluralizeChargeOff(
      bookPrice,
    )} ${bookPrice} ${this.pricingViewService.pluralizeCredits(bookPrice)}`;
  }

  protected getRunningTitlesState() {
    return this.runningTitlesStore.getRunningTitleState();
  }

  protected getPageNumbersState() {
    return this.runningTitlesStore.getPageNumberState();
  }

  private updatePositionOrientation(styleName: RunningTitleStyleName) {
    const runningTitlePosition = this.runningTitlesStore.getRunningTitlePosition();
    const runningTitlePositionMarginKey = this.runningTitlesService.convertPositionToMarginKey(runningTitlePosition);
    const pageNumberPosition = this.runningTitlesStore.getPageNumberPosition();
    const pageNumberPositionMarginKey = this.runningTitlesService.convertPositionToMarginKey(pageNumberPosition);
    const position = styleName === "runningTitle" ? pageNumberPosition : runningTitlePosition;
    if (
      (this.marginsService.isMarginHorizontal(runningTitlePositionMarginKey) &&
        !this.marginsService.isMarginHorizontal(pageNumberPositionMarginKey)) ||
      (!this.marginsService.isMarginHorizontal(runningTitlePositionMarginKey) &&
        this.marginsService.isMarginHorizontal(pageNumberPositionMarginKey))
    ) {
      this.form.controls[`${styleName}Position`].setValue(position, { emitEvent: false });
      this.setPosition(styleName, position);

      this.form.controls["pageNumberStyle"].setValue(1);

      if (this.isPageNumbersSettingsVisible) {
        this.isPageNumbersSettingsVisible = false;
      }
    }
  }

  private updatePositionForCenteredStyles(styleName: RunningTitleStyleName) {
    const modifiableStyleName: RunningTitleStyleName = styleName === "runningTitle" ? "pageNumber" : "runningTitle";
    const selectedStyle = this.getStyle(styleName);
    const position = this.getPosition(styleName);
    if (styleName === "runningTitle" && !this.runningTitlesService.isRunningTitleStyleCentered(selectedStyle)) {
      return;
    }
    if (styleName === "pageNumber" && !this.runningTitlesService.isPageNumberStyleCentered(selectedStyle)) {
      return;
    }
    let modifiablePosition: number;
    switch (position) {
      case 0:
        modifiablePosition = 2;
        break;
      case 1:
        modifiablePosition = 1;
        break;
      case 2:
        modifiablePosition = 0;
        break;
      default:
        modifiablePosition = 0;
        break;
    }
    this.form.controls[`${modifiableStyleName}Position`].setValue(modifiablePosition, { emitEvent: false });
    this.setPosition(modifiableStyleName, modifiablePosition);
  }

  protected getRunningTitleErrors(position: PagePosition) {
    const errors: InfoBlockData[] = [];

    if (this.runningTitlesTextWidthErrors.includes(position)) {
      errors.push({ textData: [{ text: $localize`:@@books.error.text-gt-margin-size:` }] });
    }

    if (this.form.get(`runningTitle${this.runningTitlesService.capitalizeFirstLetter(position)}Text`)?.errors) {
      errors.push({ textData: [{ text: $localize`:@@books.error.empty-margin:` }] });
    }

    return errors;
  }

  protected isMarginsSizeErrorVisible() {
    return this.smallMargins.length > 0;
  }

  private getRunningTitlesStylesText() {
    let text = "";
    switch (this.form.controls["runningTitleLeft"].value) {
      case 0:
        text = this.runningTitlesStore.getRunningTitleLeftText();
        break;
      case 1:
        text = $localize`:@@books.running-titles.options.author:`;
        break;
      case 2:
        text = $localize`:@@books.running-titles.options.book-name:`;
        break;
      case 3:
        text = $localize`:@@books.running-titles.options.chapters-name:`;
        break;

      default:
        break;
    }
    return text;
  }

  protected getRunningTitlesStyles() {
    return this.runningTitlesService.getRunningTitlesStyles();
  }

  protected getRunningTitleText() {
    return {
      runningTitle: this.getRunningTitlesStylesText(),
      pageNumber: "",
    };
  }

  protected getPageNumbersStyles() {
    return this.runningTitlesService.getPageNumbersStyles();
  }

  protected getPageNumberText() {
    return {
      runningTitle: "",
      pageNumber: "1",
    };
  }

  protected onRunningTitlesTextWidthErrors(runningTitlesTextWidthErrors: PagePosition[]) {
    this.runningTitlesTextWidthErrors = runningTitlesTextWidthErrors;
  }

  private getPosition(styleName: RunningTitleStyleName) {
    let position = 0;
    if (styleName === "runningTitle") {
      position = this.runningTitlesStore.getRunningTitlePosition();
    } else {
      position = this.runningTitlesStore.getPageNumberPosition();
    }
    return position;
  }

  private setPosition(styleName: RunningTitleStyleName, position: number) {
    if (styleName === "runningTitle") {
      this.runningTitlesStore.setRunningTitlePosition(position);
    } else {
      this.runningTitlesStore.setPageNumberPosition(position);
    }
  }

  private getStyle(styleName: RunningTitleStyleName) {
    let style = 0;
    if (styleName === "runningTitle") {
      style = this.runningTitlesStore.getRunningTitleStyle();
    } else {
      style = this.runningTitlesStore.getPageNumberStyle();
    }
    return style;
  }

  private setStyle(styleName: RunningTitleStyleName, style: number) {
    if (styleName === "runningTitle") {
      this.runningTitlesStore.setRunningTitleStyle(style);
    } else {
      this.runningTitlesStore.setPageNumberStyle(style);
    }
  }

  private setText(pagePosition: PagePosition, text: string) {
    if (pagePosition === "left") {
      this.runningTitlesStore.setRunningTitleLeftText(text);
    } else {
      this.runningTitlesStore.setRunningTitleRightText(text);
    }
  }

  protected getInfoLabels(): InfoBlockData[] {
    const labels: InfoBlockData[] = [];

    if (this.marginsState.margins.marginOuter < this.minimalMarginsSize.marginOuter) {
      labels.push({ textData: [{ text: $localize`:@@books.error.all-margins-small-for-running-titles:` }] });
    }

    return labels;
  }

  protected activeSidebarTab(tabNumber: number) {
    this.activeSidebarTabNumber = tabNumber;

    if (this.activeSidebarTabNumber === 2) {
      this.isRunningTitlesSettingsVisible = false;
      this.isPageNumbersSettingsVisible = false;
    }
  }

  protected getSidebarTabCssClassList(tabNumber: number): string[] {
    const result: string[] = [];

    result.push("sidebar-tab");
    if (this.activeSidebarTabNumber === tabNumber) {
      result.push("active-tab");
    }

    return result;
  }

  protected getTabIconColor(tabNumber: number) {
    if (this.activeSidebarTabNumber === tabNumber) {
      return "--color-font-tab-active";
    }
    return "--color-font-tab";
  }
}
