import { Component, HostBinding, Input, SimpleChanges } from "@angular/core";
import { BookCover } from "@metranpage/book-data";
import { CommunityDataService } from "../../community-data.service";

@Component({
  selector: "m-community-cover-view",
  templateUrl: "cover.view.html",
  styleUrls: ["cover.view.scss"],
})
export class CommunityCoverView {
  @Input()
  cover!: BookCover;

  protected url = "";

  constructor(private readonly communityDataService: CommunityDataService) {}

  @HostBinding("style.aspect-ratio")
  get aspectRatio() {
    return this.cover?.width / this.cover?.height;
  }

  @HostBinding("style.width")
  get width() {
    return `${this.cover?.width * this.scaleFactor}px`;
  }
  @HostBinding("style.height")
  get height() {
    return `${this.cover?.height * this.scaleFactor}px`;
  }

  get scaleFactor() {
    const maxAvailableHeight = Math.round((window.innerHeight / 100) * 65); // vh
    if (maxAvailableHeight < this.cover?.height) {
      return maxAvailableHeight / this.cover?.height;
    }
    return 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cover) {
      this.url = this.getUrlForImage(this.cover)!;
    }
  }

  protected getUrlForImage(cover: BookCover) {
    return this.communityDataService.getImageUrlForObject("covers", cover.id, false);
  }
}
