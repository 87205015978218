import { CommonModule, NgClass } from "@angular/common";
import { Component, Input, SecurityContext, ViewEncapsulation } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "m-localized-text",
  template: `
    <span [innerHTML]="getHtml()"></span>
  `,
  styles: [
    `
    :host ::ng-deep {
      a {
        text-decoration: underline;
      }
    }
  `,
  ],
  // encapsulation: ViewEncapsulation.ShadowDom,
  standalone: true,
  imports: [CommonModule, NgClass, ReactiveFormsModule],
})
export class LocalizedTextComponent {
  @Input()
  text?= "";

  constructor(private readonly domSanitizer: DomSanitizer) { }

  getHtml() {
    if (!this.text) return;
    return this.domSanitizer.sanitize(SecurityContext.HTML, this.text);
  }
}
