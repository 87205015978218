import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@metranpage/core";
import { OAuthModule } from "angular-oauth2-oidc";
import { InputFieldErrorComponent } from "./components/input-field-errors";
import { PricingMenuItemComponent } from "./components/menu-item/menu-item.component";
import { AwaitPaymentLinkPage } from "./pages/await-payment-link/await-payment-link.page";
import { PaymentFailedPage } from "./pages/payment-failed/payment-failed.page";
import { PaymentNextTariffConnectedPage } from "./pages/payment-next-tariff-connected/payment-next-tariff-connected.page";
import { PaymentSuccessPage } from "./pages/payment-success/payment-success.page";
import { PricingHostPage } from "./pages/pricing-host/pricing-host.page";
import { PricingSubscriptionPage } from "./pages/pricing-subscription/pricing-subscription.page";
import { BuyingCreditsView } from "./views/buying-credits/buying-credits.view";
import { CreditsCalculatorBuyCreditsView } from "./views/calculator-panel-buy-credits/calculator-panel-buy-credits.view";
import { CreditsCalculatorGoldCreditView } from "./views/calculator-panel-gold-credit/calculator-panel-gold-credit.view";
import { CreditsCalculatorProfitView } from "./views/calculator-panel-profit/calculator-panel-profit.view";
import { CreditsCalculatorPanelView } from "./views/calculator-panel/calculator-panel.view";
import { CreditsCalculatorResultView } from "./views/calculator-result/calculator-result.view";
import { CreditsCalculatorView } from "./views/calculator/calculator.view";
import { CreditsInfoModal } from "./views/credits-info-modal/credits-info-modal.view";
import { CreditsTableView } from "./views/credits-table/credits-table.view";
import { FAQView } from "./views/faq/faq.view";
import { InfoPanelView } from "./views/info-panel/info-panel.view";
import { LowBalanceModalComponent } from "./views/low-balace-modal/low-balance-modal.component";
import { PricingNavView } from "./views/nav/nav.view";
import { PromoCard } from "./views/promo-card/promo-card";
import { PromocodeEnterMenuView } from "./views/promocode-enter-menu/promocode-enter-menu.view";
import { TariffActiveCardView } from "./views/tariff-active-card/tariff-active-card.view";
import { TariffCardView } from "./views/tariff-card/tariff-card.view";
import { TariffGridComponent } from "./views/tariff-grid/tariff-grid.component";
import { TariffPeriodSelectorView } from "./views/tariff-period-selector/tariff-period-selector.view";
import { TariffsView } from "./views/tariffs/tariffs.view";

@NgModule({
  declarations: [
    // components
    InputFieldErrorComponent,
    PricingMenuItemComponent,
    TariffGridComponent,
    // views
    CreditsTableView,
    CreditsCalculatorPanelView,
    CreditsCalculatorView,
    CreditsCalculatorResultView,
    CreditsCalculatorBuyCreditsView,
    CreditsCalculatorGoldCreditView,
    CreditsCalculatorProfitView,
    InfoPanelView,
    CreditsInfoModal,
    BuyingCreditsView,
    TariffsView,
    TariffCardView,
    TariffActiveCardView,
    TariffPeriodSelectorView,
    PromocodeEnterMenuView,
    FAQView,
    PricingNavView,
    LowBalanceModalComponent,
    // pages
    PricingHostPage,
    AwaitPaymentLinkPage,
    PaymentSuccessPage,
    PaymentFailedPage,
    PricingSubscriptionPage,
    PaymentNextTariffConnectedPage,
    PromoCard,
  ],
  exports: [CreditsCalculatorPanelView, TariffGridComponent, LowBalanceModalComponent],
  imports: [CoreModule, OAuthModule.forRoot(), RouterModule],
})
export class PricingModule {}
