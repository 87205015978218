<div class="wrapper">
  <div class="section main">
    <ng-scrollbar *ngIf="reversedObjects" class="scroll-container">
      <div
        class="layers-container"
        cdkDropList
        [cdkDropListData]="objects"
        (cdkDropListDropped)="reorderCoverObjects($event)"
      >
        <div
          *ngFor="let object of reversedObjects"
          class="layer-wrapper"
          cdkDrag
          [cdkDragDisabled]="object.isLocked"
          class="plate layer-wrapper"
          [class.current]="isSelected(object)"
          [class.invalid]="!validateObjectName(object.name)"
          (click)="onObjectClick(object, $event)"
        >
          <div class="cell type">
            <m-ng-icon [size]="24" [src]="getObjectTypeIconPath(object)"></m-ng-icon>
            <div
              class="object-role"
              *ngIf="user.isAdmin && object.role"
              [ngClass]="object.role.role"
              [class.mapped-by-id]="object.role.isMappedById"
            ></div>
          </div>
          <div
            class="cell name"
            spellcheck="false"
            contenteditable
            [(ngModel)]="object.name"
            (contenteditablechange)="onNameChange(object, $event)"
            (contenteditableblur)="onNameBlur(object)"
            (contenteditablefocus)="onNameFocus(object, $event)"
          ></div>
          <div class="cell visibility" *ngIf="!object.isVisible">
            <m-ng-icon [size]="24" src="/assets/icons/eye-close.svg"></m-ng-icon>
          </div>
          <div class="cell drag-handle" *ngIf="!object.isLocked" cdkDragHandle>
            <m-ng-icon [size]="24" src="/assets/icons/drag-handle-01.svg"></m-ng-icon>
          </div>
          <div class="cell lock" *ngIf="object.isLocked">
            <m-ng-icon [size]="24" src="/assets/icons/locked-01.svg"></m-ng-icon>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>

  <div class="section" *ngIf="currentObject">
    <div class="layer-controls-wrapper">
      <div class="block opacity">
        <form [formGroup]="form" *ngIf="values$ | async as values">
          <m-ng-input-number-with-icon
            iconLeft="opacity-01.svg"
            formControlName="opacity"
            size="32"
            [iconSize]="24"
            text-align="right"
          />
        </form>
      </div>
      <div class="block">
        <div class="button">
          <m-ng-icon [src]="visibilityIcon" [size]="24" (click)="toggleObjectVisibility(currentObject)"></m-ng-icon>
        </div>
        <div class="button">
          <m-ng-icon [src]="lockIcon" [size]="24" (click)="toggleLockObject(currentObject)"></m-ng-icon>
        </div>
        <div class="button">
          <m-ng-icon src="/assets/icons/clone-01.svg" [size]="24" (click)="cloneObject(currentObject)"></m-ng-icon>
        </div>
        <div class="button delete">
          <m-ng-icon src="/assets/icons/delete-01.svg" [size]="24" (click)="deleteObject(currentObject)"></m-ng-icon>
        </div>
      </div>
    </div>
  </div>
</div>
