import { ElementRef, Injectable, Renderer2 } from "@angular/core";
import * as _ from "lodash-es";
import { ListData } from "../views/markup-editor/blocks/list-block/interfaces/list-data.interface";
import { FakeCursorNode } from "../views/markup-editor/editor.models";

@Injectable({
  providedIn: "root",
})
export class HtmlRendererService {
  rawToListHtml(renderer: Renderer2, data: ListData): string {
    const root = this.createList(renderer, data);

    return root.outerHTML;
  }

  saveData(data: ListData, editorContainer: ElementRef) {
    _.assign(data, this.htmlToRaw(editorContainer.nativeElement));
  }

  addInlineTagInRange(tagName: string, range: Range) {
    const contents = range.extractContents();

    const newFragment = document.createDocumentFragment();
    const tag = document.createElement(tagName);
    tag.appendChild(contents);
    newFragment.appendChild(tag);

    range.insertNode(newFragment);
    range.commonAncestorContainer.normalize();
  }

  removeInlineTagInRange(tagName: string, range: Range, inputElement: Element) {
    this.surroundRangeWithFakeCursor(range);

    const tags: HTMLCollection = inputElement.getElementsByTagName(tagName);

    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];

      if (range.intersectsNode(tag)) {
        const isTagStartingInSelection = range.isPointInRange(tag, 0);
        const isTagEndingInSelection = range.isPointInRange(tag, tag.childNodes.length);

        if (isTagStartingInSelection && isTagEndingInSelection) {
          // tag is entire in selection
          // | some <i>text</i> and |
          // just remove the tag
          this.unnestElement(tag);
          i--;
          continue;
        }
        // else we will need to split elements to parts

        // if tags starting sooner than selection and ending later
        // <i>text | more te|xt</i>
        // we cut tag into three parts: before, in selection, after

        const fragmentWithoutTag = document.createDocumentFragment();

        const { before, inSelection, after } = this.splitTagOnRange(tag, tagName, range);
        fragmentWithoutTag.append(before);
        fragmentWithoutTag.append(inSelection);
        fragmentWithoutTag.append(after);

        const tagParent = tag.parentNode;
        tagParent?.replaceChild(fragmentWithoutTag, tag);
      }
    }

    this.restoreRangeToFakeCursor(range, inputElement);

    this.removeEmptyChilds(inputElement);
    inputElement.normalize();

    const event = new Event("selectionchange");
    document.dispatchEvent(event);
  }

  focusFakeCursor(inputElement: Element) {
    const fc = inputElement.querySelector("span.fake-cursor");
    if (!fc) {
      console.error("no fake cursor to focus");
      return;
    }

    const sel = window.getSelection();
    const range = document.createRange();

    range.setStart(fc, 0);
    range.collapse(true);

    sel?.removeAllRanges();
    sel?.addRange(range);

    fc.remove();
  }

  private surroundRangeWithFakeCursor(range: Range) {
    const cont = range.extractContents();
    const f1 = FakeCursorNode.cloneNode(true) as HTMLElement;
    f1.id = "start";
    const f2 = FakeCursorNode.cloneNode(true) as HTMLElement;
    f2.id = "end";

    const fragment = document.createDocumentFragment();
    fragment.append(f1);
    fragment.append(cont);
    fragment.append(f2);
    range.insertNode(fragment);
  }

  private restoreRangeToFakeCursor(range: Range, inputElement: Element) {
    const f1 = inputElement.querySelector("span#start.fake-cursor");
    const f2 = inputElement.querySelector("span#end.fake-cursor");

    if (f1 && f2) {
      range.setStartBefore(f1);
      range.setEndAfter(f2);

      f1.remove();
      f2.remove();
    }
  }

  private removeEmptyChilds(element: Element | Node) {
    const tagsToRemove = ["i", "b"];

    if (tagsToRemove.includes(element.nodeName.toLowerCase())) {
      if (element.textContent?.length === 0) {
        (element as Element).remove();
      }
    }

    for (let i = element.childNodes.length - 1; i >= 0; i--) {
      this.removeEmptyChilds(element.childNodes[i]);
    }
  }

  private unnestElement(element: Element) {
    const fragment = document.createDocumentFragment();
    for (let i = 0; i < element.childNodes.length; i++) {
      fragment.append(element.childNodes[i]);
      i--;
    }
    const elementParent = element.parentNode;
    elementParent?.replaceChild(fragment, element);
  }

  private splitTagOnRange(tag: Element, tagName: string, range: Range) {
    const before = document.createElement(tagName);
    const inSelection = document.createDocumentFragment();
    const after = document.createElement(tagName);

    let isBefore = true;
    for (let i = 0; i < tag.childNodes.length; i++) {
      const child = tag.childNodes[i];

      if (range.intersectsNode(child)) {
        if (child.nodeType === Node.TEXT_NODE) {
          // we are in text node, so process text only

          let splitStartIndex = 0;
          let splitEndIndex = child.textContent?.length;
          let text = child.textContent!;

          if (child === range.startContainer) {
            // if selection starts in middle of text container -
            // then split text in parts, first part goes in "before"
            // last part goes in "inSelection"
            text = child.textContent!;

            splitStartIndex = range.startOffset;
            const firstPart = text.slice(0, splitStartIndex);

            before.append(document.createTextNode(firstPart));
          }

          if (child === range.endContainer) {
            // if selection ends in middle of text container -
            // then split text in parts,
            // first part goes in "inSelection"
            // last part goes in "after"

            text = child.textContent!;
            splitEndIndex = range.endOffset;
            const lastPart = text.slice(splitEndIndex, text.length);

            after.append(document.createTextNode(lastPart));
          }

          const middleText = text.slice(splitStartIndex, splitEndIndex);
          inSelection.append(document.createTextNode(middleText));
        } else {
          inSelection.append(child);
          i--; // when we append child - it was removed from old parent, so we need to decrement index
        }

        isBefore = false;
      } else {
        if (isBefore) {
          // we are currently before the selection, add child to before section
          before.append(child);
          i--; // when we append child - it was removed from old parent, so we need to decrement index
        } else {
          // we are after selection
          after.append(child);
          i--; // when we append child - it was removed from old parent, so we need to decrement index
        }
      }
    }

    return { before, inSelection, after };
  }

  private createList(renderer: Renderer2, data: ListData) {
    let listTag = "";
    if (data.style === "ordered") {
      listTag = "ol";
    } else if (data.style === "unordered") {
      listTag = "ul";
    } else {
      // unordered by default
      listTag = "ul";
    }

    const root = renderer.createElement(listTag);

    this.addListChilds(root, renderer, data.items);

    return root;
  }

  private addListChilds(element: HTMLElement, renderer: Renderer2, items: ListData[]) {
    for (const item of items) {
      const child = renderer.createElement("li");
      if (item.content !== undefined) {
        child.innerHTML = item.content || "";
      }

      if (item.items && item.items.length > 0) {
        child.appendChild(this.createList(renderer, item));
      }
      element.appendChild(child);
    }
  }

  private htmlToRaw(element: Element): ListData {
    const data = this.parseElement(element.children[0]);

    return data!;
  }

  private parseElement(element: Element): ListData | undefined {
    const allowedTags = ["b", "i", "em", "strong"];

    if (!element.tagName) {
      return undefined;
    }
    const data: ListData = {
      style: 'unordered',
      items: [],
      content: '',
      level: 0
    };
    if (element.tagName.toLowerCase() === "ul") {
      data.style = "unordered";
      data.items = this.parseItems(element);
    } else if (element.tagName.toLowerCase() === "ol") {
      data.style = "ordered";
      data.items = this.parseItems(element);
    } else if (element.tagName.toLowerCase() === "li") {
      data.content = "";

      for (let i = 0; i < element.childNodes.length; i++) {
        const child = element.childNodes[i];
        if (child.nodeType === Node.TEXT_NODE) {
          data.content += child.textContent;
          continue;
        }

        if (child instanceof Element) {
          if (allowedTags.includes(child.tagName.toLowerCase())) {
            data.content += child.outerHTML;
          }
        }
      }

      data.items = this.parseItems(element);
    } else {
      return undefined;
    }

    return data;
  }

  private parseItems(element: Element): ListData[] {
    const data: ListData[] = [];
    for (let i = 0; i < element.children.length; i++) {
      const child = element.children[i];
      const item = this.parseElement(child);
      if (item) {
        data.push(item);
      }
    }
    return data;
  }
}
