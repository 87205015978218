import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsPopUpService } from "@metranpage/core";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "m-login-one-time-token",
  templateUrl: "./login-one-time-token.page.html",
  styleUrls: ["./login-one-time-token.page.scss"],
})
export class LoginOneTimeTokenPage implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly notificationsService: NotificationsPopUpService,
  ) {}

  async ngOnInit(): Promise<void> {
    const token = this.route.snapshot.queryParamMap.get("token");
    if (!token) {
      this.router.navigate(["/login"]);
      this.notificationsService.error($localize`:@@user.auth.cant-login-one-time-token:`);
      return;
    }

    const result = await this.authService.processOneTimeTokenLogin(token);

    if (result === "success") {
      this.router.navigate(["/books"]);
    } else if (result === "one-time-token-error") {
      this.router.navigate(["/login"]);
      this.notificationsService.error($localize`:@@user.auth.cant-login-one-time-token:`);
    }
  }
}
