import { CommonModule, NgClass } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "m-soon-mark",
  template: `
    <ng-content select="[text-left]"></ng-content>
    <span class="mark" i18n="@@soon-mark">soon-mark</span>
    <ng-content select="[text-right]"></ng-content>
  `,
  styles: [
    `
    @import '../mixins';

    :host {
      display: flex;
      align-items: center;
      gap: 8px;
      text-wrap: nowrap;
    }

    .mark {
      @include text;

      display: flex;
      width: fit-content;
      height: 16px;
      padding: 0 4px;
      border-radius: 4px;
      background: var(--surface-on-island-2);
      color: var(--text-text-64);
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-semibold);
      gap: 8px;
      letter-spacing: -0.007em;
      line-height: 14.4px;
      text-align: left;
      align-items: center;
    }
    `,
  ],
  standalone: true,
  imports: [CommonModule, NgClass],
})
export class SoonMarkComponent {}
