<div class="container">
  <div *ngIf="colorName" class="row">
    <span class="title">{{ colorName }}</span>
  </div>

  <div #saturationWrapper class="selector-wrapper saturation">
    <canvas
      #saturationSelector
      class="slider saturation-slider"
      (mousedown)="onSaturationMouseDown($event)"
      i18n="@@canvas.error"
    >
      canvas.error
    </canvas>
  </div>

  <div class="bottom">
    <div *ngIf="showEyeDropper" class="eyedropper-wrapper" (click)="onEyeDropper()">
      <m-icon size="s" src="/assets/icons/eyedropper-01.svg" color="fake"></m-icon>
    </div>
    <div class="color-opacity-wrapper">
      <div #colorWrapper class="selector-wrapper color">
        <canvas
          #colorSelector
          class="slider color-slider"
          (mousedown)="onColorsMouseDown($event)"
          i18n="@@canvas.error"
        >
          canvas.error
        </canvas>
      </div>
      <div #opacityWrapper [class.hidden]="!showOpacity" class="selector-wrapper opacity">
        <canvas
          #opacitySelector
          class="slider opacity-slider"
          (mousedown)="onOpacityMouseDown($event)"
          i18n="@@canvas.error"
        >
          canvas.error
        </canvas>
      </div>
    </div>
  </div>

  <form *ngIf="isColorConverShow" class="color-converter" [formGroup]="formColorSelector">
    <m-color-converter formControlName="colorConverter"></m-color-converter>
  </form>
</div>
