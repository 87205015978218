import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { InfoBlockData, fadeInOutOnEnterLeave } from "@metranpage/components";
import { RouterService, filterUndefined } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { PricingService } from "@metranpage/pricing";
import { ActiveSubscription, Tariff } from "@metranpage/pricing-data";
import { User, UserBalance, UserStore } from "@metranpage/user-data";
import { BalanceData } from "@metranpage/user-payment-data";
import * as _ from "lodash-es";
import { Observable, Subject, Subscription, combineLatest, first, map } from "rxjs";
import { DownloadGeneratedImageData, GeneratedImage } from "../../models/generated-image";
import {
  AdvancedGenerationMode,
  ImageGeneration,
  ImageGenerationMode,
  ImageGenerationPrices,
  LastImageGenerationsDataDto,
} from "../../models/image-generation";
import { GeneratedImageService } from "../../services/generated-image.service";
import { GeneratedImageStore } from "../../services/generated-image.store";
import { ImageGenerationDataService } from "../../services/image-generation-data.service";
import {
  AdvancedFormChangeData,
  BasicFormChangeData,
  ImageGenerationFormService,
} from "../../services/image-generation-form.service";
import { ImageGenerationService } from "../../services/image-generation.service";
import { ImageGenerationStore } from "../../services/image-generation.store";
import { PublishedImageStore } from "../../services/published-image.store";
import { PreviewImageData } from "../generation-result/generation-result.view";

@Component({
  selector: "m-image-generation-view",
  templateUrl: "./image-generation.view.html",
  styleUrls: ["./image-generation.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class ImageGenerationView implements AfterViewInit {
  @Input()
  isModal = false;
  @Input()
  closeOnBackDropClick = true;
  @Input()
  closeButtonVisible = true;
  @Input()
  selectedTab = 2;

  @Output()
  onSelectedImage = new EventEmitter<GeneratedImage>();
  @Output()
  onClose = new EventEmitter();
  @Output()
  onTabSelect = new EventEmitter<number>();
  @Output()
  onInit = new EventEmitter<void>();

  @ViewChild("formBasicElement", { read: ElementRef })
  formBasicElement?: ElementRef;
  @ViewChild("formAdvancedElement", { read: ElementRef })
  formAdvancedElement?: ElementRef;

  protected imageGenerations: ImageGeneration[] = [];
  protected publishedImageSettings?: ImageGeneration;

  protected page = 1;
  protected pageCount = 1;

  protected promptMaxLenght = 200;

  protected isLowBalanceModalVisible = false;
  tariffsForUpgrade$!: Observable<Tariff[]>;
  protected activeSubscription?: ActiveSubscription;
  protected higherTariff?: Tariff;
  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;
  protected balance!: UserBalance;

  protected formBasic?: FormGroup;
  protected formAdvanced?: FormGroup;

  protected user!: User;
  protected imageGenerationPaymentData!: BalanceData;
  protected tariff?: Tariff;
  protected igBasicImagesCount = 4;
  protected igBasicSelectedStyleId = 0;
  protected igBasicPrice?: number;
  protected igAdvancedImagesCount = 4;
  protected igAdvancedPrice?: number;
  protected prices!: ImageGenerationPrices;

  protected isLoading = true;
  protected isImageGenerationsLoading = true;

  sub: Subscription = new Subscription();

  protected basicFormValueChangesEvent$ = new Subject<BasicFormChangeData | undefined>();
  protected advancedFormValueChangesEvent$ = new Subject<AdvancedFormChangeData | undefined>();

  protected previewImageGeneration?: ImageGeneration;
  protected previewGeneratedImage?: GeneratedImage;
  protected previewGeneratedImageIndex?: number;

  protected imageGenerationForDelete?: ImageGeneration;
  protected generatedImageForDelete?: GeneratedImage;

  protected modalConfirmationDeleteImageText = "";

  protected generatedImageForShare?: GeneratedImage;

  protected isAgeSelectVisible = false;
  protected isSetAgeYearInputVisible = false;
  protected isColorSchemeVisible = false;
  protected isNegativePromptVisible = false;

  protected moodErrors: InfoBlockData[] = [];

  constructor(
    userStore: UserStore,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly notificationService: NotificationsPopUpService,
    private readonly imageGenerationStore: ImageGenerationStore,
    private readonly imageGenerationService: ImageGenerationService,
    private readonly imageGenerationDataService: ImageGenerationDataService,
    private readonly imageGenerationFormService: ImageGenerationFormService,
    private readonly generatedImageService: GeneratedImageService,
    private readonly generatedImageStore: GeneratedImageStore,
    private readonly pricingService: PricingService,
    private readonly publishedImageStore: PublishedImageStore,
    private readonly routerService: RouterService,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        if (!user) {
          return;
        }
        this.user = user;
      }),
    );

    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.activeSubscription = activeSubscription;
        this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
        this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;

        this.getHigherTariff();
      }),
    );

    this.sub.add(
      userStore.getBalanceObservable().subscribe((balance) => {
        if (!balance) {
          return;
        }
        this.balance = balance;
      }),
    );

    this.tariffsForUpgrade$ = combineLatest([
      userStore.getActiveSubscriptionObservable(),
      pricingService.getTariffsForCompany(),
    ]).pipe(
      map(([subscription, tariffs]) => ({ subscription, tariffs: tariffs.filter((v) => v.isFree === false) })),
      map((info) => {
        if (!info.subscription || info.subscription.tariff.isFree) {
          return info.tariffs.filter((t) => t.period === 1);
        }
        return info.tariffs.filter((t) => t.period === info.subscription?.tariff.period);
      }),
    );

    this.basicFormValueChangesEvent$ = this.imageGenerationFormService.basicFormValueChangesEvent$;
    this.sub.add(
      this.basicFormValueChangesEvent$.pipe(filterUndefined()).subscribe((value) => {
        this.igBasicImagesCount = value.imagesCount;
        this.igBasicSelectedStyleId = value.styleId;
        const hasElements = this.isStyleHasElements(this.igBasicSelectedStyleId);
        this.updatePrice(this.igBasicImagesCount, "basic", hasElements, undefined);
      }),
    );

    this.advancedFormValueChangesEvent$ = this.imageGenerationFormService.advancedFormValueChangesEvent$;
    this.sub.add(
      this.advancedFormValueChangesEvent$.pipe(filterUndefined()).subscribe((value) => {
        this.igAdvancedImagesCount = value.imagesCount;
        this.updatePrice(this.igAdvancedImagesCount, "advanced", false, value.advancedGenerationMode);

        this.isAgeSelectVisible = value.isAgeEnable;
        this.isColorSchemeVisible = value.isColorSchemeEnable;
        this.isNegativePromptVisible = value.isNegativePromptEnable;

        if (this.isAgeSelectVisible && value.age === "set-year") {
          this.isSetAgeYearInputVisible = true;
        } else {
          this.isSetAgeYearInputVisible = false;
        }

        if (value.mood) {
          this.moodErrors = [];
        }
      }),
    );

    this.sub.add(
      this.publishedImageStore
        .getPublishedImageSettingsObservable()
        .pipe(filterUndefined())
        .pipe(first())
        .subscribe(async (settings) => {
          this.publishedImageSettings = settings;

          this.publishedImageStore.setPublishedImageSettings(undefined);

          if (this.imageGenerationService.isAdvancedGeneration(this.publishedImageSettings)) {
            this.selectedTab = 2;
            this.cdr.markForCheck();
          }
        }),
    );
  }

  async ngAfterViewInit() {
    // this.loadingService.startLoading({ fullPage: true });
    this.isLoading = true;

    const lastImageGenerations = await this.imageGenerationService.getLastImageGenerations();

    await this.loadImageGenerationsPaginated();

    this.prices = await this.imageGenerationService.loadPrices();
    const stylesBasic = await this.imageGenerationService.loadImageGenerationBasicStyles();
    const stylesAdvanced = await this.imageGenerationService.loadImageGenerationAdvancedStyles();

    this.formBasic = this.imageGenerationFormService.initBasicForm(
      this.igBasicImagesCount,
      stylesBasic,
      lastImageGenerations.lastBasicIG,
    );
    this.formAdvanced = this.imageGenerationFormService.initAdvancedForm(
      this.igAdvancedImagesCount,
      stylesAdvanced,
      lastImageGenerations.lastAdvancedIG,
    );

    this.updateDefaultPrice(lastImageGenerations);

    this.updateHiddenFields(lastImageGenerations.lastAdvancedIG);

    // await this.imageGenerationService.loadImageGenerations();

    this.imageGenerationFormService.setPublishedImageSettings(this.publishedImageSettings);

    this.onInit.emit();

    this.sub.add(
      this.imageGenerationStore
        .getImageGenerationsObservable()
        .pipe(filterUndefined())
        .subscribe(async (imageGenerations) => {
          this.imageGenerations = imageGenerations.filter((ig) => !ig.deletedAt && !ig.rootGeneratedImageId);

          if (this.page < this.pageCount && this.imageGenerations.length < 5) {
            await this.onScroll();
          }

          this.updatePreviewGeneratedImage();

          this.cdr.markForCheck();
        }),
    );

    this.sub.add(
      this.imageGenerationStore
        .getImageGenerationsPageCountObservable()
        .pipe(filterUndefined())
        .subscribe((count) => {
          this.pageCount = count;
        }),
    );

    this.sub.add(
      this.generatedImageStore
        .getPreviewGeneratedImageObservable()
        .pipe(filterUndefined())
        .subscribe((previewGeneratedImage) => {
          this.previewGeneratedImage = previewGeneratedImage;
        }),
    );

    // this.loadingService.stopLoading();
    this.isLoading = false;

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.imageGenerationFormService.destroyForm();
    this.sub.unsubscribe();
  }

  protected onCloseClick() {
    this.onClose.emit();
  }

  onBackClick() {
    this.router.navigate(["/"]);
  }

  private updateHiddenFields(lastImageGeneration: ImageGeneration | undefined) {
    if (!lastImageGeneration) {
      return;
    }

    if (lastImageGeneration.age && /^[0-9]{1,4}$/.test(lastImageGeneration.age)) {
      this.isSetAgeYearInputVisible = true;
    }
    if (lastImageGeneration.isAgeEnable !== undefined) {
      this.isAgeSelectVisible = lastImageGeneration.isAgeEnable;
    }
    if (lastImageGeneration.isColorSchemeEnable !== undefined) {
      this.isColorSchemeVisible = lastImageGeneration.isColorSchemeEnable;
    }
    if (lastImageGeneration.isNegativePromptEnable !== undefined) {
      this.isNegativePromptVisible = lastImageGeneration.isNegativePromptEnable;
    }
  }

  private updateDefaultPrice(lastImageGenerations: LastImageGenerationsDataDto) {
    let basicIGImagesCount = this.igBasicImagesCount;
    let basicIGHasElements = false;
    if (lastImageGenerations.lastBasicIG) {
      basicIGImagesCount = lastImageGenerations.lastBasicIG.imagesCount;
      basicIGHasElements = this.isStyleHasElements(lastImageGenerations.lastBasicIG.styleId);
    }
    this.updatePrice(basicIGImagesCount, "basic", basicIGHasElements, undefined);

    let advancedIGImagesCount = this.igAdvancedImagesCount;
    let advancedGenerationMode: AdvancedGenerationMode = "quality";
    if (lastImageGenerations.lastAdvancedIG) {
      advancedIGImagesCount = lastImageGenerations.lastAdvancedIG.imagesCount;
      advancedGenerationMode = lastImageGenerations.lastAdvancedIG.advancedGenerationMode ?? "quality";
    }
    this.updatePrice(advancedIGImagesCount, "advanced", false, advancedGenerationMode);
  }

  private updatePrice(
    imagesCount: number,
    mode: "basic" | "advanced",
    hasElements: boolean,
    advancedGenerationMode: AdvancedGenerationMode | undefined,
  ) {
    if (!this.prices) {
      return;
    }

    let price = 1;

    if (mode === "basic") {
      price = this.prices.basic[`${imagesCount}`];
      if (hasElements) {
        price *= this.prices.elementsCoeficient;
      }
      this.igBasicPrice = Math.ceil(imagesCount * price);
    }
    if (mode === "advanced") {
      price = this.prices.advancedFast[`${imagesCount}`];
      if (advancedGenerationMode === "quality") {
        price = this.prices.advancedQuality[`${imagesCount}`];
      }
      if (hasElements) {
        price *= this.prices.elementsCoeficient;
      }
      this.igAdvancedPrice = Math.ceil(imagesCount * price);
    }
  }

  isStyleHasElements(styleId: number) {
    const styles = this.getBasicStyles();
    const style = styles.find((s) => s.id === styleId);
    return !!style?.elements && style.elements.length > 0;
  }

  protected getPrice() {
    let price = this.igBasicPrice;
    if (this.selectedTab === 2) {
      price = this.igAdvancedPrice;
    }
    return price;
  }

  async onGenerationClick() {
    if (!this.user?.isVerified) {
      this.router.navigate(["/account/account-verification"], { queryParams: { from: "image-generation" } });
      return;
    }

    if (this.isGenerateDisabled()) {
      this.checkFormErrors();
      return;
    }

    if (!this.isEnoughtTokens()) {
      await this.calculatePaymentData();
      return;
    }

    if (this.selectedTab === 1) {
      await this.basicImageGeneration();
      return;
    }

    await this.advancedImageGeneration();
  }

  private checkFormErrors() {
    this.updateMoodErrors();
    this.markInvalidControlAsDirty();
    this.scrollToFirstInvalidControl();
  }

  private markInvalidControlAsDirty(): void {
    let controls = this.formBasic?.controls;
    if (this.selectedTab === 2) {
      controls = this.formAdvanced?.controls;
    }
    for (const k in controls) {
      const control = controls[k];
      if (!control.valid) {
        control.markAsDirty();
      }
    }
  }

  private scrollToFirstInvalidControl(): void {
    let formElement = this.formBasicElement?.nativeElement;
    if (this.selectedTab === 2) {
      formElement = this.formAdvancedElement?.nativeElement;
    }
    if (formElement) {
      const firstErrorElement = formElement.querySelector(".ng-invalid");
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      }
    }
  }

  isEnoughtTokens(): boolean {
    if (this.selectedTab === 1) {
      if (this.igBasicPrice && this.balance && this.igBasicPrice <= this.balance.credits) {
        return true;
      }
    }

    if (this.selectedTab === 2) {
      if (this.igAdvancedPrice && this.balance && this.igAdvancedPrice <= this.balance.credits) {
        return true;
      }
    }

    return false;
  }

  async basicImageGeneration() {
    if (!this.formBasic || !this.formBasic.valid) {
      return;
    }

    let data = this.formBasic.getRawValue();

    const proportion = data.proportion;
    data = { ...data, ...proportion };
    data.prompt = data.prompt.replaceAll("<br>", " ");
    data = _.omit(data, "proportion");

    const result = await this.imageGenerationService.basicImageGeneration(data);
    if (result === "error") {
      this.notificationService.error($localize`:@@image-generation.generation.cant-start-processing-error:`);
    }

    // this.setDefaultBasicFormValue();
    this.scrollGenerationRelultsToTop();
  }

  async advancedImageGeneration() {
    if (!this.formAdvanced || !this.formAdvanced.valid) {
      return;
    }

    let data = this.formAdvanced.getRawValue();
    const proportion = data.proportion;
    data = { ...data, ...proportion };
    data.colorScheme = this.imageGenerationService.convertColorSchemeToString(data.colorScheme);
    data.prompt = data.prompt.replaceAll("<br>", " ");
    data.negativePrompt = data.negativePrompt.replaceAll("<br>", " ");
    if (data.age === "set-year") {
      data.age = `${data.ageYear}`;
    }
    data = _.omit(data, ["proportion", "ageYear"]);

    const result = await this.imageGenerationService.advancedImageGeneration(data);
    if (result === "error") {
      this.notificationService.error($localize`:@@image-generation.generation.cant-start-processing-error:`);
    }

    // this.setDefaultAdvancedFormValue();
    this.scrollGenerationRelultsToTop();
  }

  private async calculatePaymentData() {
    // this.loadingService.startLoading({ fullPage: true });
    this.isLoading = true;

    let imagesCount = this.igBasicImagesCount;
    let mode: ImageGenerationMode = "basic";
    let hasElements = this.isStyleHasElements(this.igBasicSelectedStyleId);
    if (this.selectedTab === 2) {
      imagesCount = this.igAdvancedImagesCount;
      mode = "advanced";
      hasElements = false;
    }

    const imageGenerationPaymentData = await this.imageGenerationService.checkCredits({
      imagesCount,
      mode,
      hasElements,
    });
    if (imageGenerationPaymentData === "error") {
      this.notificationService.error($localize`:@@image-generation.generation.cant-start-processing-error:`);
      // this.loadingService.stopLoading();
      this.isLoading = false;

      return;
    }

    this.imageGenerationPaymentData = imageGenerationPaymentData;
    this.balance = imageGenerationPaymentData.userBalance;

    // this.loadingService.stopLoading();
    this.isLoading = false;

    this.isLowBalanceModalVisible = true;

    this.cdr.markForCheck();
  }

  protected closePricingModal() {
    this.isLowBalanceModalVisible = false;
  }

  protected onBuySubscription(tariff: Tariff) {
    this.closePricingModal();
    window.open(`payments/await-payment-link?tariffId=${tariff.id}`, "_blank");
  }

  protected async getHigherTariff() {
    this.higherTariff = await this.pricingService.getHigherTariff();
  }

  protected onShowImagePreviewClick(value: PreviewImageData) {
    if (!this.isModal) {
      this.routerService.showInModal([value.imageGeneration.id, value.generatedImage.id]);
      return;
    }

    this.previewImageGeneration = value.imageGeneration;
    this.previewGeneratedImage = value.generatedImage;
    this.previewGeneratedImageIndex = this.generatedImageService.getGeneratedImageIndex(
      value.generatedImage,
      this.previewImageGeneration?.generatedImages,
    );

    this.generatedImageStore.setPreviewGeneratedImage(this.previewGeneratedImage);
  }

  protected onCloseImagePreviewClick() {
    this.resetPreview();
  }

  protected onPreviousClick() {
    if (this.previewGeneratedImageIndex === undefined || !this.hasPreviousImage()) {
      return;
    }

    this.previewGeneratedImageIndex--;
    this.previewGeneratedImage = this.previewImageGeneration?.generatedImages[this.previewGeneratedImageIndex];
  }

  protected onNextClick() {
    if (this.previewGeneratedImageIndex === undefined || !this.hasNextImage()) {
      return;
    }

    this.previewGeneratedImageIndex++;
    this.previewGeneratedImage = this.previewImageGeneration?.generatedImages[this.previewGeneratedImageIndex];
  }

  protected hasPreviousImage() {
    if (!this.previewImageGeneration || this.previewGeneratedImageIndex === undefined) {
      return false;
    }
    return this.previewGeneratedImageIndex - 1 >= 0;
  }

  protected hasNextImage() {
    if (!this.previewImageGeneration || this.previewGeneratedImageIndex === undefined) {
      return false;
    }
    return this.previewGeneratedImageIndex + 1 < this.previewImageGeneration?.generatedImages.length;
  }

  protected onShowDeleteImageGenerationModal(imageGeneration: ImageGeneration) {
    this.imageGenerationForDelete = imageGeneration;
  }

  protected onCloseDeleteImageGenerationModal() {
    this.imageGenerationForDelete = undefined;
    this.cdr.detectChanges();
  }

  protected async deleteImageGeneration() {
    if (!this.imageGenerationForDelete) {
      return;
    }
    await this.imageGenerationService.deleteImageGeneration(this.imageGenerationForDelete.id);

    this.resetPreview();
    this.onCloseDeleteImageGenerationModal();
  }

  protected onShowDeleteGeneratedImageModal(generatedImage: GeneratedImage) {
    this.modalConfirmationDeleteImageText = $localize`:@@image-generation.generation.delete-generated-image-with-child-generations-confirmation:`;
    if (this.isGeneratedImageVariant(generatedImage)) {
      this.modalConfirmationDeleteImageText = $localize`:@@image-generation.generation.delete-generated-image-confirmation:`;
    }
    this.generatedImageForDelete = generatedImage;
  }

  protected isGeneratedImageVariant(generatedImage: GeneratedImage) {
    return this.previewGeneratedImage && this.previewGeneratedImage.id !== generatedImage.id;
  }

  protected onMadeUpscaleImageClick(data: PreviewImageData) {
    this.onShowImagePreviewClick(data);
  }

  protected onMadeUnzoomImageClick(data: PreviewImageData) {
    this.onShowImagePreviewClick(data);
  }

  protected onEditImageGenerationClick(imageGeneration: ImageGeneration) {
    this.notificationService.notify({
      content: $localize`:@@soon-text-notification:`,
      type: "success",
      showOptsTimeout: 2,
    });
  }

  protected onCloseDeleteGeneratedImageModal() {
    this.generatedImageForDelete = undefined;
    this.cdr.detectChanges();
  }

  private updatePreviewGeneratedImage() {
    for (const ig of this.imageGenerations) {
      let isPreviewGeneratedImageFound = false;
      for (const gi of ig.generatedImages) {
        if (this.previewGeneratedImage?.id === gi.id) {
          this.previewGeneratedImage = gi;
          isPreviewGeneratedImageFound = true;
          break;
        }
      }
      if (isPreviewGeneratedImageFound) {
        break;
      }
    }
  }

  protected async deleteImage() {
    if (!this.generatedImageForDelete) {
      return;
    }
    await this.generatedImageService.deleteGeneratedImage(this.generatedImageForDelete.id);

    if (this.isGeneratedImageVariant(this.generatedImageForDelete)) {
      this.onCloseDeleteGeneratedImageModal();
      return;
    }

    this.resetPreview();
    this.onCloseDeleteGeneratedImageModal();
  }

  protected onSelectImage(generatedImage: GeneratedImage) {
    this.onSelectedImage.emit(generatedImage);
  }

  protected onSelectImageFromModalClick(generatedImage: GeneratedImage) {
    this.onSelectImage(generatedImage);
    this.resetPreview();
  }

  protected resetPreview() {
    this.previewImageGeneration = undefined;
    this.previewGeneratedImage = undefined;
    this.previewGeneratedImageIndex = undefined;

    this.generatedImageStore.setPreviewGeneratedImage(this.previewGeneratedImage);
    this.cdr.markForCheck();
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }

  protected async onDownloadImageClick(downloadGeneratedImageData: DownloadGeneratedImageData) {
    await this.generatedImageService.downloadGeneratedImage(downloadGeneratedImageData);
  }

  protected async onDownloadImageFromModalClick(generatedImage: GeneratedImage) {
    await this.generatedImageService.downloadGeneratedImage({
      imageGenerationId: generatedImage.imageGenerationId,
      imageUrl: generatedImage.imageUrl,
    });
  }

  private async loadImageGenerationsPaginated() {
    this.isImageGenerationsLoading = true;
    await this.imageGenerationService.loadImageGenerationsPaginated(this.page);
    this.isImageGenerationsLoading = false;
  }

  protected async onScroll() {
    this.page++;
    await this.loadImageGenerationsPaginated();
    this.cdr.markForCheck();
  }

  protected isGenerateDisabled(): boolean {
    if (this.selectedTab === 1) {
      return !this.formBasic?.valid || !this.igBasicPrice || !this.balance;
    }
    if (this.selectedTab === 2) {
      return !this.formAdvanced?.valid || !this.igAdvancedPrice || !this.balance;
    }
    return false;
  }

  protected onTabClick(selectedTab: number) {
    if (this.selectedTab === selectedTab) {
      return;
    }
    this.selectedTab = selectedTab;
    this.onTabSelect.emit(this.selectedTab);
  }

  getDetalizationPoints() {
    return this.imageGenerationDataService.getDetalizationPoints();
  }

  getMoodOptions() {
    return this.imageGenerationDataService.getMoodOptions();
  }

  getAgeOptions() {
    return this.imageGenerationDataService.getAgeOptions();
  }

  getContrastPoints() {
    return this.imageGenerationDataService.getContrastPoints();
  }

  getModelOptions() {
    return this.imageGenerationDataService.getModelOptions();
  }

  getModeOptions() {
    return this.imageGenerationDataService.getModeOptions();
  }

  getBasicStyles() {
    return this.imageGenerationFormService.getBasicStyles();
  }

  getAdvancedStyles() {
    return this.imageGenerationFormService.getAdvancedStyles();
  }

  private scrollGenerationRelultsToTop() {
    this.imageGenerationService.onScrollToTopImageGenerationResults();
  }

  protected onHelperClick() {
    const prompt = this.formBasic?.get("prompt")?.value;
    if (prompt) {
      this.formAdvanced?.patchValue({ prompt });
    }
    this.onTabClick(2);
  }

  protected updateMoodErrors() {
    this.moodErrors = [];

    if (!this.formAdvanced?.get("mood")?.valid) {
      this.moodErrors.push({
        textData: [{ text: $localize`:@@image-generation.mood.error:` }],
      });
    }

    this.cdr.markForCheck();
  }

  @HostBinding("class")
  get cssClassList() {
    const classes: string[] = [];

    if (this.isModal) {
      classes.push("modal");
    }

    return classes.join(" ");
  }

  @HostListener("window:keydown.escape")
  protected handleKeyDown() {
    this.onCloseClick();
  }
}
