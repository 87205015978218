<m-ng-island class="image-container">
  <m-community-image-view *ngIf="image" [image]="image" />
  <m-community-cover-view *ngIf="cover" [cover]="cover" />
</m-ng-island>

<m-ng-island  class="details-container">
  <m-community-image-details-view *ngIf="image" [image]="image" (onUnpublish)="onUnpublishImage(image)" />
  <m-community-cover-details-view *ngIf="cover" [cover]="cover" (onUnpublish)="onUnpublishCover(cover)"/>
</m-ng-island>

<m-loading-spinner *ngIf="isLoading" @fadeInOut  />

<m-modal *ngIf="isUnpublishModalVisible" [closeButtonVisible]="true" [closeOnBackDropClick]="true" (close)="onUnpublishModalClose()">
  <div class="modal-container">
    <div class="modal-content">
      <span i18n="@@home.community.unpublish-modal">home.community.unpublish-modal</span>
    </div>

    <div class="modal-buttons">
      <m-button button-style="secondary" size="full-width" (onClick)="onUnpublishModalClose()" i18n="@@dialog.cancel-button">dialog.cancel-button</m-button>
      <m-button button-style="primary" (onClick)="onUnpublishModalConfirm()" i18n="@@dialog.yes-button">dialog.yes-button</m-button>
    </div>
  </div>
</m-modal>
