import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyCreateData } from '../../models/company';

@Component({
  selector: 'm-admin-add-company-modal',
  templateUrl: './add-company.view.html',
  styleUrls: ['./add-company.view.scss'],
})
export class AddCompanyView {
  @Output()
  onCompanyAdded = new EventEmitter<CompanyCreateData>();
  @Output()
  onCancelAddition = new EventEmitter<void>();

  protected form = new FormGroup({
    title: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
  });

  createCompany() {
    this.onCompanyAdded.emit(this.form.getRawValue());
  }

  onCancel() {
    this.onCancelAddition.emit();
  }
}
