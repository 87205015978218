import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import {
  Book,
  BookCoverLaminationType,
  BookCoverType,
  BookDefaultPrintSettings,
  BookPaperType,
  BookPrintSettings,
  PrintChromaticityType,
} from "@metranpage/book-data";
import { CompanyPrintViewStyle, CompanyStore } from "@metranpage/company";
import { SelectValue } from "@metranpage/components";
import { LoadingService } from "@metranpage/core";
import { PricingViewService } from "@metranpage/pricing";
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  of,
  startWith,
  switchMap,
  tap,
} from "rxjs";
import { BookService } from "../../services/book.service";

type Availability = {
  isAvailable: boolean;
  reason?: string;
};

type EstimationResult = {
  succeeded: boolean;
  errorMessage?: string;
  priceNum?: number;
  priceStr?: string;
};

const printRunMin = 30;
const softCoverMinPages = 38;
const hardCoverMinPages = 100;

export function isNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return Number.isNaN(control.value) ? { isNaN: { value: control.value } } : null;
  };
}

@Component({
  selector: "m-book-print-settings",
  templateUrl: "./book-print-settings.component.html",
  styleUrls: ["./book-print-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookPrintSettingsComponent implements OnInit, OnChanges {
  @Input() book!: Book;

  private refresher: Subject<void> = new Subject<void>();
  estimatedPrice$!: Observable<EstimationResult>;

  readonly form = new FormGroup({
    paperType: new FormControl<BookPaperType | null>(null, [Validators.required]),
    coverType: new FormControl<BookCoverType | null>(null, [Validators.required]),
    coverLaminationType: new FormControl<BookCoverLaminationType | null>(null, [Validators.required]),
    printChromaticityType: new FormControl<PrintChromaticityType | null>(null, [Validators.required]),
    printRun: new FormControl<number | null>(null, [isNumberValidator(), Validators.min(printRunMin)]),
  });

  readonly contactForm = new FormGroup({
    phoneNumber: new FormControl<string | null>(null, [Validators.required, Validators.pattern("^[ 0-9()+,-]*$")]),
    deliveryAddress: new FormControl<string | null>(null, [Validators.required]),
  });

  settings$!: Observable<{
    paperTypeOptions: SelectValue[];
    coverTypeOptions: SelectValue[];
    coverLaminationTypeOptions: SelectValue[];
    printChromaticityTypeOptions: SelectValue[];
    printSettings: BookPrintSettings | BookDefaultPrintSettings;
  }>;

  isContactFormVisible = false;
  isFinalizeVisible = false;
  processingRequest = false;

  printAvailability: Availability = { isAvailable: true };
  estimationAvailability: Availability = { isAvailable: true };

  pagesInBook = 0;

  displayStyle: CompanyPrintViewStyle = "calculator";
  redirectUrlTemplate?: string;
  private sub: Subscription = new Subscription();

  constructor(
    private readonly companyStore: CompanyStore,
    private readonly bookService: BookService,
    private readonly pricingViewService: PricingViewService,
    private readonly cdr: ChangeDetectorRef,
    private readonly loadingService: LoadingService,
  ) {
    this.sub.add(
      this.companyStore.getCompanyObservable().subscribe((company) => {
        this.displayStyle = company?.printSettings?.printViewStyle ?? "calculator";
        this.redirectUrlTemplate = company?.printSettings?.redirectUrl;
      }),
    );
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.book) {
      this.pagesInBook = this.book.bookResults?.previews?.length ?? 0;
      this.onBook();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  checkPrintAvailability() {
    if (!this.book.bookResults?.isPrintFinalsReady || !this.book.bookResults.previews?.length) {
      this.printAvailability = {
        isAvailable: false,
        reason: $localize`:@@books.book.print-settings.constraint-finalize-first:`,
      };
      return;
    }

    if (this.pagesInBook < softCoverMinPages && this.displayStyle === "calculator") {
      this.printAvailability = {
        isAvailable: false,
        reason: $localize`:@@books.book.print-settings.constraint-pages:`,
      };
      return;
    }
    this.printAvailability = {
      isAvailable: true,
      reason: undefined,
    };
  }

  checkEstimationAvailability() {
    if (this.book.bookSettings?.width !== 148 || this.book.bookSettings?.height !== 210) {
      this.estimationAvailability = {
        isAvailable: false,
        reason: $localize`:@@books.book.print-settings.constraint-size-format:`,
      };
      return;
    }
    this.estimationAvailability = {
      isAvailable: true,
      reason: undefined,
    };
  }

  private getDefaultPrintSettings(): BookDefaultPrintSettings {
    return {
      paperType: "offset",
      coverType: this.pagesInBook < 100 ? "soft" : "hard",
      coverLaminationType: "glossy",
      printChromaticityType: "1+1",
      printRun: 30,
    };
  }

  onBook() {
    this.checkPrintAvailability();
    this.checkEstimationAvailability();
    if (!this.printAvailability.isAvailable) {
      return;
    }

    this.form.reset();
    this.settings$ = combineLatest([
      this.bookService.getPrintSettingsConstants(),
      this.bookService.getPrintSettings(this.book.id),
    ]).pipe(
      map(([c, s]) => {
        const result = {
          paperTypeOptions: c.paperTypes.map((v) => <SelectValue>{ id: v.type, value: v.name }),
          coverTypeOptions: c.coverTypes.map((v) => <SelectValue>{ id: v.type, value: v.name }),
          coverLaminationTypeOptions: c.coverLaminationTypes.map((v) => <SelectValue>{ id: v.type, value: v.name }),
          printChromaticityTypeOptions: c.printChromaticityTypes.map((v) => <SelectValue>{ id: v.type, value: v.name }),
          printSettings: s ?? this.getDefaultPrintSettings(),
        };
        if (this.pagesInBook < hardCoverMinPages) {
          result.coverTypeOptions = result.coverTypeOptions.filter((v) => v.id !== "hard");
        }
        return result;
      }),
      tap((s) => {
        this.form.patchValue(s.printSettings);
        this.contactForm.patchValue(s.printSettings);
      }),
    );

    if (!this.estimationAvailability.isAvailable) {
      return;
    }

    this.estimatedPrice$ = merge(
      this.form.valueChanges.pipe(distinctUntilChanged(), debounceTime(300)),
      this.refresher,
    ).pipe(
      startWith(() => {}),
      switchMap(() => {
        if (this.form.valid) {
          return this.bookService.calculatePrintEstimatedPrice(this.book.id, this.form.value as BookPrintSettings);
        }
        return of(undefined);
      }),
      map((v) => {
        if (v !== undefined) {
          return <EstimationResult>{
            succeeded: true,
            priceNum: v,
            priceStr: this.pricingViewService.priceFormat(v, "₽"),
          };
        }
        return <EstimationResult>{
          succeeded: false,
        };
      }),
    );
  }

  onShowContactForm() {
    this.isContactFormVisible = true;
  }

  onCloseContactForm() {
    if (this.processingRequest) {
      return;
    }
    this.isContactFormVisible = false;
    this.isFinalizeVisible = false;
  }

  async requestCalculation() {
    if (!this.form.valid || !this.contactForm.valid) {
      return;
    }

    this.processingRequest = true;
    this.loadingService.startLoading({ fullPage: true });

    await this.bookService.createPrintCalculationRequest(this.book.id, {
      ...(this.form.value as BookPrintSettings),
      ...(this.contactForm.value as BookPrintSettings),
    });
    this.isFinalizeVisible = true;
    this.cdr.markForCheck();

    this.processingRequest = false;
    this.loadingService.stopLoading();
  }

  async calculateEstimatedPrice() {
    if (!this.form.valid) {
      return;
    }
    this.refresher.next();
  }

  redirectToPrint() {
    let url = this.redirectUrlTemplate;
    if (!url) {
      return;
    }

    function replaceUrlTemplate(template: string, replacements: { [key: string]: string | number }): string {
      return Object.entries(replacements).reduce((result, [key, value]) => {
        const placeholder = `\\[${key}\\]`;
        return result.replace(new RegExp(placeholder, "g"), String(value));
      }, template);
    }

    url = replaceUrlTemplate(url, {
      id: this.book.id.toString(),
      integrationProjectId: this.book.integrationProjectId ?? "",
      width: this.book.bookSettings?.width.toString() ?? "",
      height: this.book.bookSettings?.height.toString() ?? "",
      isbn: this.book.isbn ?? "",
    });

    window.open(url, "_blank");
  }
}
