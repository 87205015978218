import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BookCover } from "@metranpage/book-data";
import { UserStore } from "@metranpage/user-data";
import { CommunityDataService } from "../../community-data.service";

@Component({
  selector: "m-community-cover-details-view",
  templateUrl: "cover-details.view.html",
  styleUrls: ["cover-details.view.scss"],
})
export class CommunityCoverDetailsView {
  @Input()
  cover!: BookCover;
  @Output()
  onUnpublish = new EventEmitter<void>();

  constructor(
    private readonly communityDataService: CommunityDataService,
    private readonly userStore: UserStore,
  ) { }

  async ngOnInit() { }

  protected onCopyPublishedImageSettings() {
    // this.publishedImageStore.setPublishedImageSettings(this.image?.generationSettings);
    // this.router.navigateByUrl("/image-generation");
  }

  protected canUnpublish() {
    return (
      this.userStore.isUserAdmin() ||
      (this.userStore.isUserHasPaidSubscription() && this.userStore.getUser()?.id === this.cover?.author?.id)
    );
  }

  protected onShowUnpublishImageModal() {
    this.onUnpublish.emit();
  }

  protected getUrlForImage(cover: BookCover) {
    return this.communityDataService.getImageUrlForObject("covers", cover.id, true)!;
  }
}
