<div class="wrapper" *ngIf="user$ | async as user">
  <div class="section header-wrapper">
    <div class="caption" i18n="@@cover-editor.templates.header.caption">cover-editor.templates.header.caption</div>
    <m-ng-icon class="close-icon" src="cross-02.svg" [size]="20" (click)="onClose()" />
  </div>

  <div class="section top"></div>

  <div class="section main">
    <ng-scrollbar class="scroll-container">
      <div class="templates-container" *ngIf="data$ | async as data">
        <div
          class="template-wrapper"
          *ngFor="let template of data.templates"
          [class.selected]="data.activeTemplate?.id === template.id"
        >
          <div class="image-wrapper">
            <img [src]="template.previewUrl" class="image" />
          </div>
          <div class="checkmark-wrapper">
            <m-ng-icon [size]="48" src="/assets/icons/checkmark-03.svg"></m-ng-icon>
          </div>
          <div class="toolbar-wrapper">
            <m-button
              button-style="primary"
              size="full-width"
              icon-position="left"
              icon="mark-02.svg"
              class="apply"
              (click)="onApply(template)"
              i18n="@@cover-editor.templates.button.apply"
            >
              cover-editor.templates.button.apply
            </m-button>
            <m-button
              *ngIf="user.isAdmin"
              button-style="secondary"
              size="full-width"
              icon-position="left"
              icon="delete-02.svg"
              (click)="delete(template); $event.stopPropagation()"
              i18n="@@cover-editor.templates.button.delete"
            >
              cover-editor.templates.button.delete
            </m-button>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>

  <div class="section bottom" *ngIf="user.isAdmin">
    <m-button
      button-style="secondary"
      size="full-width"
      icon-position="left"
      icon="save-01.svg"
      class="apply"
      (click)="onSave()"
      i18n="@@cover-editor.templates.button.save-current"
    >
      cover-editor.templates.button.save-current
    </m-button>
  </div>
</div>
