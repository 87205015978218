import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { CompanyStore } from "@metranpage/company";
import { IS_IMAGES_GENERATION_AVAILABLE, IS_PURCHASES_AVAILABLE, filterUndefined } from "@metranpage/core";
import { OnboardingService } from "@metranpage/onboarding";
import { User, UserBalance } from "@metranpage/user-data";
import { Observable, Subscription, filter, map } from "rxjs";

@Component({
  selector: "m-app-bar-menu",
  templateUrl: "./app-bar-menu.view.html",
  styleUrls: ["./app-bar-menu.view.scss"],
})
export class AppBarMenuView implements OnInit, OnDestroy {
  @Input()
  user!: User;
  @Input()
  userBalance!: UserBalance;
  @Input()
  isLanguageSwitchAvailable = true;

  @Output()
  onThemeToggle = new EventEmitter<void>();
  @Output()
  onLanguageChange = new EventEmitter<string>();
  @Output()
  onLogoutClick = new EventEmitter<void>();
  @Output()
  onPromocodeEnter = new EventEmitter<string>();

  protected isMenuOpened = false;
  protected isOnboardingAvailable = false;
  protected isAboutGoldCreditsVisible = false;

  protected isPromocodeModalVisible = false;

  knowledgeBaseUrl?: string;
  isLogoutButtonAvailable = true;

  sub: Subscription = new Subscription();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly onboardingService: OnboardingService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    @Inject(IS_PURCHASES_AVAILABLE) protected readonly isPurchasesAvailable: boolean,
    @Inject(IS_IMAGES_GENERATION_AVAILABLE)
    protected readonly isImagesGenerationAvailable: boolean,
    companyStore: CompanyStore,
  ) {
    const routeData = this.route.snapshot.root.firstChild?.data ?? {};
    this.isOnboardingAvailable = routeData && "hasOnboarding" in routeData;

    this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe(async () => {
      const routeData = this.router.routerState.snapshot.root.firstChild!.data;
      this.isOnboardingAvailable = routeData && "hasOnboarding" in routeData;
    });

    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.knowledgeBaseUrl = company.knowledgeBaseUrl;
          this.isLogoutButtonAvailable = company.isLogoutButtonAvailable;
        }),
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected toggleMenu() {
    this.isMenuOpened = !this.isMenuOpened;
  }

  protected closeMenu() {
    this.isMenuOpened = false;
  }

  protected onThemeSwitchToggle() {
    this.onThemeToggle.emit();
  }

  protected onLanguageChangeRequested(targetLang: string) {
    this.onLanguageChange.emit(targetLang);
    this.closeMenu();
  }

  protected onLogoutButtonClick() {
    this.onLogoutClick.emit();
    this.closeMenu();
  }

  fontSize(value: number) {
    const symbolsCount = value.toString().length;
    if (symbolsCount <= 3) {
      return "14px";
    }
    const size = Math.round(40 / symbolsCount);
    if (size > 14) {
      return "14px";
    }
    return `${size}px`;
  }

  async startOnboarding(): Promise<void> {
    this.closeMenu();
    this.onboardingService.onStartOnboarding(true);
  }

  onTokenSimpleAddClick() {
    this.closeMenu();
    this.router.navigate(["/payments/subscription"]);
  }

  onTokenGoldInfoShow() {
    this.closeMenu();
    this.isAboutGoldCreditsVisible = true;
  }

  onTokenGoldInfoClose() {
    this.isAboutGoldCreditsVisible = false;
  }

  showPromocodeModal() {
    this.closeMenu();
    this.isPromocodeModalVisible = true;
  }

  hidePromocodeModal() {
    this.isPromocodeModalVisible = false;
  }

  protected promocodeEnter(promocode: string) {
    this.onPromocodeEnter.emit(promocode);
  }

  @HostListener("document:click", ["$event"])
  protected onClickOutside(event: Event) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      // click in component
    } else {
      if (this.isMenuOpened) {
        this.closeMenu();
      }
    }
  }

  @HostListener("window:keydown.escape")
  protected handleKeyDown() {
    this.closeMenu();
  }
}
