import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { PricingService } from "@metranpage/pricing";
import { BankCardData } from "@metranpage/pricing-data";
import { User, UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";
import { UserService } from "../../services/user.service";

@Component({
  selector: "m-account-bank-cards-page",
  templateUrl: "./account-bank-cards.page.html",
  styleUrls: ["./account-bank-cards.page.scss"],
})
export class AccountBankCardsPage {
  user?: User;
  bankCards: BankCardData[] = [];

  protected emptyBankCards: undefined[] = [];
  protected cardsCountInRow = 3;

  protected isDeleteCardModalVisible = false;
  protected cardIdForDelete?: number;

  sub: Subscription = new Subscription();

  constructor(
    private readonly userService: UserService,
    private readonly userStore: UserStore,
    private readonly notificationsService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly pricingService: PricingService,
    private readonly route: ActivatedRoute,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(async (params) => {
      const success = params.get("Success");
      if (success === "False") {
        this.notificationsService.error($localize`:@@user.profile.tariff-and-payments.add-card-error:`);
      }
    });

    this.updateUserData();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async onAddNewCardClick() {
    window.open("payments/await-payment-link?newCard=true", "_blank");
  }

  async onSetCardForPaymentClick(cardId: number) {
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.userService.updateUserCard({ paymentCardId: cardId });
    this.loadingService.stopLoading();
  }

  async onDeleteCard(cardId: number) {
    this.loadingService.startLoading({ fullPage: true });
    this.bankCards = await this.pricingService.deleteBankCard(cardId);
    this.loadingService.stopLoading();
    this.updateEmptyCards();
  }

  protected isCardActive(cardData: BankCardData) {
    return this.user?.paymentCardId === cardData.id;
  }

  private updateEmptyCards() {
    const cardsCount = this.bankCards.length + 1;
    const n = cardsCount % this.cardsCountInRow ? this.cardsCountInRow - (cardsCount % this.cardsCountInRow) : 0;
    this.emptyBankCards = Array.from({ length: n }, () => undefined);
  }

  async updateUserData() {
    this.bankCards = await this.pricingService.getBankCards();
    this.updateEmptyCards();
  }

  trackByCardId(index: number, card: BankCardData) {
    return card.id;
  }

  onDeleteCardClick() {
    this.isDeleteCardModalVisible = false;
    if (this.cardIdForDelete) {
      this.onDeleteCard(this.cardIdForDelete);
    }
  }

  protected onShowDeleteCardModal(cardId: number) {
    this.cardIdForDelete = cardId;
    this.isDeleteCardModalVisible = true;
  }

  protected onCloseDeleteCardModal() {
    this.isDeleteCardModalVisible = false;
  }
}
