import { AuthGuard } from "@metranpage/auth";
import { Routes } from "@metranpage/core";
import { AccountActiveTariffPage } from "./pages/account-active-tariff/account-active-tariff.page";
import { AccountBankCardsPage } from "./pages/account-bank-cards/account-bank-cards.page";
import { AccountContactsPage } from "./pages/account-contacts/account-contacts.page";
import { AccountHostPage } from "./pages/account-host.page";
import { AccountMainPage } from "./pages/account-main/account-main.page";
import { AccountNotificationsPage } from "./pages/account-notifications/account-notifications.page";
import { AccountPasswordPage } from "./pages/account-password/account-password.page";
import { AccountPaymentsHistoryPage } from "./pages/account-payments-history/account-payments-history.page";
import { AccountTariffAndPaymentsHostPage } from "./pages/account-tariff-and-payments-host/account-tariff-and-payments-host.page";
import { AccountVerificationCheckTokenPage } from "./pages/account-verification-check-token/account-verification-check-token.page";
import { AccountVerificationPage } from "./pages/account-verification/account-verification.page";
import { AccountDevPage } from "./pages/account-dev/account-dev.page";

export const userRoutes: Routes = [
  {
    path: "account",
    component: AccountHostPage,
    canActivate: [AuthGuard],
    data: {
      showMobileWarning: true,
      welcomeModalVisible: true,
      breadcrumbs: {
        titleKey: "account",
      },
    },
    children: [
      {
        path: "",
        component: AccountMainPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },

      {
        path: "password",
        component: AccountPasswordPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "tariff-and-payments",
        component: AccountTariffAndPaymentsHostPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
        children: [
          {
            path: "payments-info",
            component: AccountBankCardsPage,
            data: {
              showTopBar: true,
              showMobileWarning: true,
            },
          },
          {
            path: "payments-history",
            component: AccountPaymentsHistoryPage,
            data: {
              showTopBar: true,
              showMobileWarning: true,
            },
          },
          {
            path: "",
            component: AccountActiveTariffPage,
            data: {
              showTopBar: true,
              showMobileWarning: true,
            },
          },
        ],
      },

      {
        path: "contacts",
        component: AccountContactsPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "notifications",
        component: AccountNotificationsPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "developer-settings",
        component: AccountDevPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
    ],
  },
  {
    path: "account/account-verification",
    component: AccountVerificationPage,
    canActivate: [AuthGuard],
    data: {
      showTopBar: false,
      showMobileWarning: false,
      welcomeModalVisible: false,
    },
  },
  {
    path: "verify-email/:token",
    component: AccountVerificationCheckTokenPage,
    data: {
      showTopBar: false,
      showMobileWarning: false,
    },
  },
];
