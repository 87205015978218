import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { NotificationsPopUpService } from "@metranpage/core";
import { GeneratedImage, GeneratedImageService } from "@metranpage/image-generation";
import { Subscription } from "rxjs";
import { CommunityDataService } from "../community-data.service";
import { BookCover } from "@metranpage/book-data";
import { CoverService } from "@metranpage/book";

@Component({
  selector: "m-community-detail-view",
  templateUrl: "community-detail.view.html",
  styleUrls: ["community-detail.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class CommunityDetailView {
  protected objectType = "images";
  protected objectId = "";

  // TODO need to think about it. The problem was in images, that not in store yet
  // object: PublishedObjectsDataSourceType | undefined;

  image: GeneratedImage | undefined;
  cover: BookCover | undefined;

  isLoading = false;
  isUnpublishModalVisible = false;
  unpublishCallback: (() => Promise<void>) | undefined;

  private sub = new Subscription();

  constructor(
    private readonly communityDataService: CommunityDataService,
    private readonly generatedImageService: GeneratedImageService,
    private readonly coverService: CoverService,
    private readonly route: ActivatedRoute,
    private readonly notificationService: NotificationsPopUpService,
  ) { }

  async ngOnInit() {
    this.sub.add(
      this.route.params.subscribe(async (params) => {
        if (params.objectType) {
          this.objectType = params.objectType;
        } else {
          this.objectType = "images";
        }
        if (params.objectId) {
          this.objectId = params.objectId;
        } else {
        }

        await this.initObject();
      }),
    );
  }

  async initObject() {
    if (this.objectType === "images" && this.objectId) {
      this.image = await this.getGeneratedImage(Number(this.objectId));
    }
    if (this.objectType === "covers" && this.objectId) {
      this.cover = await this.getBookCover(Number(this.objectId));
    }
  }

  async getGeneratedImage(id: number) {
    this.isLoading = true;
    let generatedImage: GeneratedImage | undefined = undefined;
    try {
      generatedImage = await this.generatedImageService.getGeneratedImageById(id);
    } catch (error) {
      this.onErrorLoadGeneration();
      return;
    }

    if (!generatedImage || generatedImage.deletedAt) {
      this.onErrorLoadGeneration();
      return;
    }

    this.isLoading = false;
    return generatedImage;
  }

  async getBookCover(id: number) {
    this.isLoading = true;
    let cover: BookCover | undefined = undefined;
    try {
      cover = await this.coverService.getPublishedCover(id);
    } catch (error) {
      this.onErrorLoadGeneration();
      return;
    }

    if (!cover) {
      this.onErrorLoadGeneration();
      return;
    }

    this.isLoading = false;
    return cover;
  }

  async onUnpublishImage(image: GeneratedImage) {
    this.isUnpublishModalVisible = true;
    this.unpublishCallback = async () => {
      await this.generatedImageService.unpublishGeneratedImage(image.id);
    };
  }

  async onUnpublishCover(cover: BookCover) {
    this.isUnpublishModalVisible = true;
    this.unpublishCallback = async () => {
      await this.coverService.unpublishBookCover(cover.id);
    };
  }

  async onUnpublishModalConfirm() {
    await this.unpublishCallback!();
    this.notificationService.notify({content: $localize`:@@home.community.unpublish-confirm-notification:`});
    this.isUnpublishModalVisible = false;
  }
  onUnpublishModalClose() {
    this.isUnpublishModalVisible = false;
  }

  private onErrorLoadGeneration() {
    this.notificationService.error($localize`:@@image-generation.generated-image.cant-load-image-error:`);
    this.isLoading = false;
  }
}
