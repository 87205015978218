<m-ng-island paddingSize="l">
<form
  *ngIf="!!tariff; else noTariff"
  [formGroup]="form"
>
  <div class="controls">
    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.title"
      >admin.tariffs.tariff.title</span>
      <m-ng-input formControlName="title" />
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.subtitle"
      >admin.tariffs.tariff.subtitle</span>
      <m-select
        class="select"
        input-style="normal"
        size="s"
        [options]="getOptionsForSubtitleSelect()"
        formControlName="subtitle"
      ></m-select>
      <!-- <m-ng-input  formControlName="subtitle" size="s" input-style="on-panel" type="normal" /> -->
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.price"
      >admin.tariffs.tariff.price</span>
      <m-ng-input-number formControlName="price" />
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.profit"
      >admin.tariffs.tariff.profit</span>
      <m-ng-input-number formControlName="profit" />
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.currency"
      >admin.tariffs.tariff.currency</span>
      <m-select
        class="select"
        input-style="normal"
        size="s"
        [options]="getOptionsForCurrencySelect()"
        formControlName="currency"
      ></m-select>
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.period"
      >admin.tariffs.tariff.period</span>
      <m-ng-input-number formControlName="period" />
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.credit-price"
      >admin.tariffs.tariff.credit-price</span>
      <m-ng-input-number formControlName="creditPrice" />
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.credits-per-month"
      >admin.tariffs.tariff.credits-per-month</span>
      <m-ng-input-number formControlName="creditsPerMonth" />

      <span class="label help">
        <span i18n="@@admin.tariffs.tariff.credits-helper">admin.tariffs.tariff.credits-helper</span>
        {{form.get('creditsPerMonth')!.value * form.get('period')!.value}}
      </span>
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.gold-credits-per-month"
      >
        admin.tariffs.tariff.gold-credits-per-month
      </span>
      <m-ng-input-number formControlName="goldCreditsPerMonth" />
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.active-projects-count"
      >
        admin.tariffs.tariff.active-projects-count
      </span>
      <m-ng-input-number formControlName="activeProjects" />
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.is-free"
      >admin.tariffs.tariff.is-free</span>
      <m-switch
        formControlName="isFree"
        input-style="on-panel"
      />
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.gift-period"
      >admin.tariffs.tariff.gift-period</span>
      <m-ng-input-number formControlName="giftPeriod" />
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.trial-period"
      >admin.tariffs.tariff.trial-period</span>
      <m-ng-input-number formControlName="trialPeriodInDay" />
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.legal-entity"
      >admin.tariffs.tariff.legal-entity</span>
      <m-switch
        formControlName="forLegalEntity"
        input-style="on-panel"
      />
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.file-volume-limit"
      >admin.tariffs.tariff.file-volume-limit</span>
      <m-ng-input-number formControlName="fileLimitMb" />
    </div>

    <div class="control">
      <span
        class="label"
        i18n="@@admin.tariffs.tariff.level"
      >admin.tariffs.tariff.level</span>
      <m-ng-input-number formControlName="level" />
    </div>
  </div>
  <div class="bottom-bar">
    <div class="buttons">
      <m-button
        button-style="secondary"
        (onClick)="onCancel()"
        i18n="@@admin.common.cancel"
      >
        admin.common.cancel
      </m-button>
      <m-button
        button-style="primary"
        (onClick)="onSave()"
        i18n="@@admin.common.save"
      >admin.common.save</m-button>
    </div>
  </div>
</form>
<ng-template #noTariff>No tariff loaded</ng-template>
</m-ng-island>
