import { CommonModule, NgClass } from "@angular/common";
import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";

export type TextAreaSize = "xs" | "s" | "m" | "l";
export type TextAreaTextAlign = "left" | "center" | "right" | "leftJustify";

@Component({
  selector: "m-textarea",
  templateUrl: "./text-area.component.html",
  styleUrls: ["./text-area.component.scss"],
  standalone: true,
  imports: [CommonModule, NgClass, ReactiveFormsModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent implements ControlValueAccessor {
  @Input()
  rows = 5;
  @Input()
  placeholder = "";
  @Input()
  isRequired = false;
  @Input("text-align")
  textAlign: TextAreaTextAlign = "left";
  @Input()
  name = "";
  // @Input("size")
  // inputSize: TextAreaSize = "m";
  @Input()
  tabIndex = 0;
  @Input()
  maxlength?: number;
  @Input()
  resizable = false;

  @Input("is-highlighted")
  isHighlighted = false;
  @Output()
  focus = new EventEmitter<void>();
  @Output()
  focusout = new EventEmitter<void>();

  protected isInputFocused = false;
  protected isPasswordVisible = false;
  protected isDisabled = false;
  protected value = "";

  @ViewChild("input", { static: true, read: ElementRef })
  protected inputElementRef?: ElementRef;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(private renderer: Renderer2) {}

  writeValue(value: string): void {
    if (!value) {
      value = "";
    }
    value = value?.replaceAll("<br>", "\n");

    this.inputElementRef!.nativeElement.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInputChange(event: Event) {
    let value = (event.target! as HTMLInputElement).value;

    value = value.replaceAll("\n", "<br>");

    this.onChange(value);
  }

  /**
   * Return values available here:
   *  https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  protected getAutocomplete(): string {
    return "random-string-as-off-value-not-work";
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    // result.push(`size-${this.inputSize}`);

    result.push(`text-align-${this.textAlign}`);

    if (this.isInputFocused) {
      result.push("focused");
    }
    if (this.isHighlighted) {
      result.push("highlighted");
    }
    if (this.isDisabled) {
      result.push("disabled");
    }

    return result;
  }

  protected onInputFocus() {
    this.isInputFocused = true;
    this.onTouched();

    this.focus.emit();
  }

  protected onInputBlur() {
    this.isInputFocused = false;

    this.focusout.emit();
  }

  addNewLineTag(text: string, token: string) {
    return text.replaceAll(new RegExp(token, "g"), `${token} <br>`);
  }
}
