import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-email-verification-card-view",
  templateUrl: "account-notification-email-verification-card.view.html",
  styleUrls: ["account-notification-email-verification-card.view.scss"],
})
export class AccountNotificationEmailVerificationCardView extends AccountNotificationBaseCardView<undefined> {
  constructor(private readonly router: Router) {
    super();
  }

  openAccountVerificationPage() {
    this.router.navigate(["/account/account-verification"], { queryParams: { from: "notifications" } });
  }
}
