<div *ngIf="showSectionHeader" class="section-header" [class.can-collapsing]="canCollapsing" (click)="toggle()">
  <div class="section-title-group">
    <div class="section-title">
      <span *ngIf="title">{{ title }}</span>
      <m-new-mark *ngIf="hasNewMark" />
    </div>

    <div class="paginator">
      <m-ng-icon
        class="arrow-left"
        [class.disabled]="!hasPreviousPage()"
        src="paginator-arrow.svg"
        [size]="20"
        (click)="goToPreviousPage()"
      />
      <div>
        <span>{{ page }}</span>
        /
        <span class="pages-count">{{ pagesCount }}</span>
      </div>
      <m-ng-icon
        class="arrow-right"
        [class.disabled]="!hasNextPage()"
        src="paginator-arrow.svg"
        [size]="20"
        (click)="goToNextPage()"
      />
    </div>

    <span *ngIf="canCollapsing" class="icon" [class.collapsed]="isCollapsed"></span>
    <m-ng-icon *ngIf="canCollapsing" src="dropdown-arrow-down.svg" [size]="16" [class.collapsed]="isCollapsed" />
  </div>
</div>
<div class="section-content" *ngIf="isCollapsed" @slideInOutVertical>
  <div #cards class="cards">
    <div
      #card
      *ngFor="let d of dataPaginated"
      class="card"
      [attr.id]="d.id"
      [ngClass]="{'active': isActive(d)}"
      (click)="onSelect(d)"
    >
      <m-skeleton-image
        *ngIf="d.image"
        class="card-image"
        [url]="d.image"
        width="100"
        height="100"
        unit="%"
        animationStyle="gradient"
        backgroudColor="var(--surface-on-island)"
      />

      <div *ngIf="!d.image" class="no-image">
        <m-ng-icon src="no-style.svg" [size]="40" />
      </div>

      <div *ngIf="isActive(d)" class="active-selector">
        <m-ng-icon src="checkmark-01.svg" [size]="16" />
      </div>
      <div *ngIf="d.image" class="card-fade"></div>
      <span class="title" [class.colored]="!d.image">{{d.title}}</span>
    </div>

    <div #modalCard *ngFor="let image of getEmptyCard()" class="card-empty"></div>

    <m-button button-style="secondary" size="full-width" (onClick)="showModal()">{{ textViewAllData }}</m-button>
  </div>

  <m-modal *ngIf="isModalAllDataVisible" position="center" [paddingSize]="0" (close)="closeModal()">
    <div class="modal">
      <div *ngIf="tabs.length > 2" class="modal-tabs">
        <div
          *ngFor="let tab of tabs; let i = index"
          class="modal-tab"
          [class.active]="selectedTab === tab.value"
          [class.bst]="selectedTabIndex - 1  === i"
          [class.ast]="selectedTabIndex + 1  === i"
          (click)="goToTab(tab.value)"
        >
          {{ tab.title }}
        </div>
      </div>

      <div class="modal-container">
        <overlay-scrollbars>
          <div class="modal-cards">
            <div
              #modalCard
              *ngFor="let d of filteredData"
              class="modal-card"
              [attr.id]="d.id"
              [ngClass]="{'active': isModalActive(d)}"
              (click)="onModalSelect(d)"
            >
              <m-skeleton-image
                *ngIf="d.image"
                class="modal-card-image"
                [url]="d.image"
                width="200"
                height="200"
                animationStyle="gradient"
                backgroudColor="var(--surface-on-island)"
              />

              <div *ngIf="!d.image" class="no-image">
                <m-ng-icon src="no-style.svg" [size]="40" />
              </div>

              <div *ngIf="isModalActive(d)" class="modal-active-selector">
                <m-ng-icon src="checkmark-01.svg" [size]="16" />
              </div>
              <div *ngIf="d.image" class="card-fade"></div>
              <span class="modal-title" [class.colored]="!d.image">{{d.title}}</span>
            </div>
          </div>
        </overlay-scrollbars>

        <div class="divider"></div>
        <div class="modal-footer">
          <div *ngIf="getStyleTitle(modalSelectedStyleId)" class="modal-selected-style-group">
            <span i18n="@@image-generation.style-details.selected-style">
              image-generation.style-details.selected-style
            </span>
            <span class="modal-selected-style">{{ getStyleTitle(modalSelectedStyleId) }}</span>
          </div>
          <div class="buttons">
            <m-button button-style="secondary" (onClick)="closeModal()" i18n="@@dialog.cancel-button">
              dialog.cancel-button
            </m-button>

            <m-button button-style="primary" (onClick)="onModalApply()" i18n="@@image-generation.style-details.apply">
              image-generation.style-details.apply
            </m-button>
          </div>
        </div>
      </div>
    </div>
  </m-modal>
</div>
