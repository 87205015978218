import { Injectable } from "@angular/core";
import {
  ApplyTemplateRequestDto,
  BookCover,
  BookCoverTemplate,
  CoverImageType,
  PredefinedSvgObjects,
  UploadGeneratedImageRequestDto,
  UploadImageResponseDto,
} from "@metranpage/book-data";
import { plainToInstance } from "class-transformer";
import { CoverApi } from "./cover.api";

@Injectable({
  providedIn: "root",
})
export class CoverService {
  constructor(private readonly coverApi: CoverApi) {}

  async getCoverByBookId(bookId: number): Promise<BookCover> {
    const cover = await this.coverApi.getCover(bookId);
    return plainToInstance(BookCover, cover);
  }

  async createDefaultCover(bookId: number): Promise<BookCover> {
    const cover = await this.coverApi.createDefaultCover(bookId);
    return plainToInstance(BookCover, cover);
  }

  updateCover(bookId: number, data: Partial<BookCover>): Promise<BookCover> {
    return this.coverApi.updateCover(bookId, data);
  }

  toTemplate(coverId: number): Promise<BookCoverTemplate> {
    return this.coverApi.toTemplate(coverId);
  }

  async applyTemplate(data: ApplyTemplateRequestDto): Promise<BookCover> {
    const cover = await this.coverApi.applyTemplate(data);
    return plainToInstance(BookCover, cover);
  }

  /////////////////////////////
  // Images

  uploadImage(bookId: number, file: File, type: CoverImageType): Promise<UploadImageResponseDto> {
    return this.coverApi.uploadImage(bookId, file, type);
  }

  uploadGeneratedObjectImage(data: UploadGeneratedImageRequestDto): Promise<UploadImageResponseDto> {
    return this.coverApi.uploadGeneratedObjectImage(data);
  }

  getObjectImageUrl(bookId: number, imageName: string): string {
    return this.coverApi.getObjectImageUrl(bookId, imageName);
  }

  getCoverPreviewImageUrl(bookId: number): string {
    return this.coverApi.getCoverPreviewImageUrl(bookId);
  }

  getCoverFullsizeImageUrl(bookId: number): string {
    return this.coverApi.getCoverFullsizeImageUrl(bookId);
  }

  getCoverPreviewImageBlob(bookId: number): Promise<Blob> {
    return this.coverApi.getCoverPreviewImageBlob(bookId);
  }

  getCoverFullsizeImageBlob(bookId: number): Promise<Blob> {
    return this.coverApi.getCoverFullsizeImageBlob(bookId);
  }

  /////////////////////////////////////
  // SVG Shapes

  getPredefinedSvgObjects(): Promise<PredefinedSvgObjects[]> {
    return this.coverApi.getSvgObjects();
  }

  /////////////////////////////////////
  // Share

  async publishBookCover(id: number) {
    return await this.coverApi.publishBookCover(id);
  }

  async unpublishBookCover(id: number) {
    return await this.coverApi.unpublishBookCover(id);
  }

  async getPublishedCover(id: number) {
    return await this.coverApi.getPublishedCover(id);
  }
}
