import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
  ApplyTemplateRequestDto,
  BookCover,
  BookCoverTemplate,
  CoverImageType,
  PredefinedSvgObjects,
  UploadGeneratedImageRequestDto,
  UploadImageResponseDto,
} from "@metranpage/book-data";
import { Api, BASE_URL } from "@metranpage/core-api";

@Injectable({
  providedIn: "root",
})
export class CoverApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  getCover(bookId: number): Promise<BookCover> {
    return this.get<BookCover>(`books/${bookId}/cover`);
  }

  createDefaultCover(bookId: number): Promise<BookCover> {
    return this.post<BookCover>(`books/${bookId}/cover`, {});
  }

  updateCover(bookId: number, data: Partial<BookCover>): Promise<BookCover> {
    return this.put<BookCover>(`books/${bookId}/cover`, data);
  }

  toTemplate(coverId: number): Promise<BookCoverTemplate> {
    return this.post<BookCoverTemplate>(`admin/covers/${coverId}/to-template`, {});
  }

  applyTemplate(data: ApplyTemplateRequestDto): Promise<BookCover> {
    return this.post<BookCover>(`books/${data.bookId}/cover/apply-template`, data);
  }

  //////////////////////////////////
  // Images

  uploadImage(bookId: number, file: File, type: CoverImageType): Promise<UploadImageResponseDto> {
    const formData = new FormData();
    const headers = new HttpHeaders();

    formData.append("file", file);
    formData.append("type", type);
    headers.append("Content-Type", "multipart/form-data");

    return this.post<UploadImageResponseDto>(`books/${bookId}/cover/images`, formData, { headers });
  }

  uploadGeneratedObjectImage(data: UploadGeneratedImageRequestDto): Promise<UploadImageResponseDto> {
    return this.post<UploadImageResponseDto>(`books/${data.bookId}/cover/images/generated`, data);
  }

  getObjectImageUrl(bookId: number, imageName: string): string {
    return `${this.baseUrl}books/${bookId}/cover/images/file/objects/${imageName}`;
  }

  getCoverPreviewImageUrl(bookId: number): string {
    const etag = Math.floor(Math.random() * 10000);
    return `${this.baseUrl}books/${bookId}/cover/images/file/cover-preview?etag=${etag}`;
  }

  getCoverFullsizeImageUrl(bookId: number): string {
    const etag = Math.floor(Math.random() * 10000);
    return `${this.baseUrl}books/${bookId}/cover/images/file/cover-fullsize?etag=${etag}`;
  }

  getCoverPreviewImageBlob(bookId: number): Promise<Blob> {
    return this.getAsBlob(`books/${bookId}/cover/images/file/cover-preview`);
  }

  getCoverFullsizeImageBlob(bookId: number): Promise<Blob> {
    return this.getAsBlob(`books/${bookId}/cover/images/file/cover-fullsize`);
  }

  /////////////////////////////////////
  // SVG Objects

  getSvgObjects(): Promise<PredefinedSvgObjects[]> {
    return this.get<PredefinedSvgObjects[]>("covers/svg-objects");
  }

  /////////////////////////////////////
  // Share

  async publishBookCover(id: number) {
    return this.post("books/covers/publish", { id });
  }

  async unpublishBookCover(id: number) {
    return this.post("books/covers/unpublish", { id });
  }

  async getPublishedCoversPaginated(page: number) {
    return this.get<{ items: BookCover[]; pageCount: number }>("books/covers/published", {
      page,
    });
  }

  async getPublishedCover(id: number) {
    return this.get<BookCover>(`books/covers/published/${id}`);
  }

  getUrlForCoverByCoverId(coverId: number) {
    return this.absoluteUrl(`books/covers/published/${coverId}/image`);
  }

  getUrlForCoverThumbnailByCoverId(coverId: number) {
    return this.absoluteUrl(`books/covers/published/${coverId}/thumbnail`);
  }
}
