import { fabric } from "fabric";
import * as _ from "lodash-es";

export class Guideline {
  position: number;
  distance?: number;

  constructor(position: number, distance: number | undefined = undefined) {
    this.position = position;
    this.distance = distance;
  }
}

export class Guidelines {
  v: Guideline[] = [];
  h: Guideline[] = [];
}

export class Snapper {
  private _potentialGuidelines: Guidelines = new Guidelines();
  private _activeShapeGuidelines: Guidelines = new Guidelines();
  private _snapToGuidelines: Guidelines = new Guidelines();

  constructor(public snapTolerance: number) {}

  get snapToGuidelines(): Guidelines {
    return this._snapToGuidelines;
  }

  resetGuidelines() {
    this._potentialGuidelines = new Guidelines();
    this._activeShapeGuidelines = new Guidelines();
    this._snapToGuidelines = new Guidelines();
  }

  calculatePotentialGuidelines(shapes: fabric.Object[]) {
    this._potentialGuidelines = this.getGuidelines(shapes);
  }

  calculateActiveShapeGuidelines(shape: fabric.Object) {
    this._activeShapeGuidelines = this.getShapeGuidelines(shape);
    this.calculateSnapToGuidelines();
  }

  private calculateSnapToGuidelines() {
    this._snapToGuidelines = this.getSnapToGuidelines(this._potentialGuidelines, this._activeShapeGuidelines);
  }

  private getGuidelines(shapes: fabric.Object[]): Guidelines {
    const v: Guideline[] = [];
    const h: Guideline[] = [];
    for (const shape of shapes) {
      const guidelines = this.getShapeGuidelines(shape);
      v.push(...guidelines.v);
      h.push(...guidelines.h);
    }
    return <Guidelines>{
      v: _.uniqBy(v, "position"),
      h: _.uniqBy(h, "position"),
    };
  }

  private getShapeGuidelines(shape: fabric.Object): Guidelines {
    const bound = shape.getBoundingRect(true, true);
    const v: number[] = [bound.left, bound.left + bound.width / 2, bound.left + bound.width];
    const h: number[] = [bound.top, bound.top + bound.height / 2, bound.top + bound.height];
    return <Guidelines>{ v: v.map(i => new Guideline(i)), h: h.map(i => new Guideline(i)) };
  }

  private getSnapToGuidelines(allGuidelines: Guidelines, shapeGuidelines: Guidelines): Guidelines {
    const result: Guidelines = {
      v: [],
      h: [],
    };

    for (const destV of allGuidelines.v) {
      const src = shapeGuidelines.v
        .filter((i) => Math.abs(destV.position - i.position) <= this.snapTolerance)
        .map((i) => new Guideline(destV.position, destV.position - i.position));
      if (src.length > 0) {
        result.v.push(src[0]);
      }
    }

    for (const destH of allGuidelines.h) {
      const src = shapeGuidelines.h
        .filter((i) => Math.abs(destH.position - i.position) <= this.snapTolerance)
        .map((i) => new Guideline(destH.position, destH.position - i.position));
      if (src.length > 0) {
        result.h.push(src[0]);
      }
    }

    if(result.v.length) {
      result.v.sort((a, b) => (a.distance ?? 0) - (b.distance ?? 0));
      result.v = [result.v[0]];
    }

    if (result.h.length) {
      result.h.sort((a, b) => (a.distance ?? 0) - (b.distance ?? 0));
      result.h = [result.h[0]];
    }

    return result;
  }
}
