import { Component } from "@angular/core";
@Component({
  selector: "m-success-payment",
  template: `
    <m-icon src="/assets/img/payment-failed-{theme}.png" size="original"></m-icon>
    <div class="content">
      <div class="title" i18n="@@pricing.failed-page.title">pricing.success-page.title</div>
      <div class="text" i18n="@@pricing.failed-page.text">pricing.success-page.text</div>
    </div>
    <m-button button-style="primary" i18n="@@pricing.failed-page.return" [routerLink]="'/books'">
      pricing.success-page.return
    </m-button>
  `,
  styles: [
    `
      @import '../../../../../../apps/metranpage/src/styles/mixins';

      :host {
        display: flex;
        width: 100%;
        height: calc(100vh - var(--app-bar-height));
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;
      }

      .content {
        @include text;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .title {
          font-size: var(--font-size-xxxl);
          font-weight: var(--font-weight-em);
          letter-spacing: 0.64px;
          line-height: 130%;
        }

        .text {
          font-size: var(--font-size);
          font-weight: var(--font-weight);
          letter-spacing: 0.32px;
          line-height: 130%;
        }
      }
    `,
  ],
})
export class PaymentFailedPage {}
