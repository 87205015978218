<div
  class="container"
  infiniteScroll
  [scrollWindow]="false"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScrollEvent()"
>
  <div class="preview">
    <m-ng-island class="preview-group">
      <!-- <m-filter-switch
        class="filter-published-from"
        [options]="filterOptions"
        [selectedOptionId]="filterPublishedFrom"
        (onSelect)="onFilterChanged($event)"
      /> -->

      <!-- <div class="divider"></div> -->

      <m-moderation-cover-preview
        #previewCoverElement
        [bookCover]="previewModerationCoverRequest?.bookCover"
        [hasPrevious]="hasPreviousCover()"
        [hasNext]="hasNextCover()"
        (onPrevious)="onPrevious()"
        (onNext)="onNext()"
      />
    </m-ng-island>

    <m-admin-moderation-covers-queue
      [moderatingCovers]="moderatingCovers"
      (onSelect)="onSelectModerationCoverRequest($event)"
    />
  </div>

  <div class="sidebar-right">
    <m-ng-island paddingSize="m">
      <div *ngIf="previewModerationCoverRequest" class="data">
        <div class="user-data">
          <div class="avatar"></div>
          <div class="user-name">
            <span>{{ previewModerationCoverRequest.bookCover.author!.firstName }}</span>
            <span>{{ previewModerationCoverRequest.bookCover.author!.lastName }}</span>
          </div>
        </div>
      </div>
    </m-ng-island>

    <m-ng-island paddingSize="m" class="middle-island">
      <div *ngIf="previewModerationCoverRequest" class="moderator-data">
        <form *ngIf="form" [formGroup]="form" class="moderator-description">
          <m-radio
            *ngFor="let o of descriptionOptions"
            class="radio-button"
            [value]="o.id"
            [isChecked]="o.id === selectedDescription?.id"
            (onSelect)="onSelectDescription(o)"
          >
            <span class="label">{{ o.text }}</span>
          </m-radio>

          <m-textarea
            class="textarea"
            [rows]="5"
            placeholder="admin.moderation.images.description-placeholder"
            i18n-placeholder="@@admin.moderation.images.description-placeholder"
            formControlName="moderatorDescription"
            type="normal"
            (focus)="onFocusModeratorDescription()"
          />
        </form>

        <m-button
          button-style="tertiary"
          icon="cross-02.svg"
          icon-position="left"
          i18n="@@admin.moderation.images.decline"
          color-theme="danger"
          (onClick)="onDeclineClick()"
          [is-disabled]="!isDeclineAvailable()"
        >
          admin.moderation.images.decline
        </m-button>
      </div>
    </m-ng-island>

    <m-ng-island paddingSize="m">
      <m-button
        *ngIf="previewModerationCoverRequest"
        button-style="tertiary"
        icon="checkmark-02.svg"
        icon-position="left"
        i18n="@@admin.moderation.images.accept"
        (onClick)="onAcceptClick()"
      >
        admin.moderation.images.accept
      </m-button>
    </m-ng-island>
  </div>
</div>
