import { Component, Input } from "@angular/core";

@Component({
  selector: "m-account-tariff-and-payments-menu-item-component",
  template: `
    <span>{{ title }}</span>
  `,
  styles: [
    `
      @import '../../../../../../apps/metranpage/src/styles/mixins';

      :host {
        @include text;

        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        color: var(--color-font-nav-tab-text);
        cursor: pointer;
        font-family: var(--font);
        font-size: var(--font-size-s);
        font-weight: var(--font-weight);
        user-select: none;

        &.active {
          border-bottom: 3px solid var(--accent);
          color: var(--accent);
        }
      }
    `,
  ],
})
export class AccountTariffAndPaymentsMenuItemComponent {
  @Input()
  title!: string;
}
