<m-ng-island paddingSize="l">
<div class="new-referal-link">
  <m-button button-style="primary" (onClick)="onAddReferalLink()" i18n="@@admin.referal-links.add-referal-link">
    admin.referal-links.add-referal-link
  </m-button>
</div>

<form [formGroup]="searchForm">
  <table>
    <thead>
      <th>ID</th>
      <th>
        Token
        <m-ng-input formControlName="token" size="32"/>
      </th>
      <th>Agent Fee</th>
      <th>Registered Users</th>
      <th>Orders Count</th>
      <th>Orders Amount</th>
      <th>Agent Profit</th>
      <th></th>
    </thead>

    <tbody>
      <tr *ngFor="let referalLink of referalLinks; trackBy: trackByReferalLinkId">
        <td>{{referalLink.id}}</td>
        <td>{{referalLink.token}}</td>
        <td>{{referalLink.agentFee}}</td>
        <td>{{referalLink.usersRegistered }}</td>
        <td>{{referalLink.ordersCount }}</td>
        <td>{{referalLink.ordersAmount }}</td>
        <td>{{referalLink.agentProfit }}</td>
        <td>
          <div class="controls">
            <m-icon
              class="settings-icon"
              src="gear.svg"
              size="s"
              color="--color-font-main"
              (click)="showReferalLinkDetails(referalLink)"
            />
            <m-icon
              title="admin.common.copy-text"
              i18n-title="@@admin.common.copy-text"
              class="copy-icon"
              size="s"
              src="copy.svg"
              color="--color-font-main"
              (click)="onCopyReferalLinkUrl(referalLink)"
            />
            <m-icon
              title="admin.common.details"
              i18n-title="@@admin.common.details"
              class="details-icon"
              size="s"
              src="details.svg"
              color="--color-font-main"
              (click)="showReferalLinkStats(referalLink)"
            />
            <!-- <m-icon
              class="delete-icon"
              src="block-delete.svg"
              size="s"
              color="--color-error"
              (click)="showReferalLinkDeleteModal(referalLink)"
            /> -->
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</form>

<m-admin-pagination
  [pageCount]="pageCount"
  [currentPage]="page"
  (onPageChanged)="onPageChanged($event)"
></m-admin-pagination>

<m-admin-add-referal-link-modal
  *ngIf="isAddReferalLinkModalVisible"
  (onReferalLinkAdded)="onReferalLinkAdded($event)"
  (onCancelAddition)="onCloseAddReferalLinkModal()"
/>

<m-modal *ngIf="isDeleteReferalLinkModalVisible" position="center" (close)="onCloseDeleteReferalLinkModal()">
  <div class="delete-referal-link-modal">
    <span>
      <span i18n="@@admin.referal-links.referal-link.delete-referal-link-confirmation">
        admin.referal-links.referal-link.delete-referal-link-confirmation
      </span>

      <span *ngIf="referalLinkForDelete" class="referal-link-name">
        «
        <span class="referal-link-name-accent">{{referalLinkForDelete.token}}</span>
        »
      </span>
      ?
    </span>

    <div class="buttons">
      <m-button
        button-style="secondary"
        size="full-width"
        (onClick)="onCloseDeleteReferalLinkModal()"
        i18n="@@admin.common.cancel"
      >
        admin.common.cancel
      </m-button>
      <m-button button-style="primary" (onClick)="onDeleteReferalLink()" i18n="@@admin.common.delete">
        admin.common.delete
      </m-button>
    </div>
  </div>
</m-modal>
</m-ng-island>
