import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  QueryList,
  SimpleChanges,
  ViewChildren,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { slideInOutVertical } from "@metranpage/components";
import { ImageGenerationAdvancedStyle, ImageGenerationBasicStyle } from "../../models/image-generation";

type TabValue = {
  value: string;
  title: string;
};

@Component({
  selector: "m-styles-selector",
  templateUrl: "./styles-selector.view.html",
  styleUrls: ["./styles-selector.view.scss"],
  animations: [slideInOutVertical],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StylesSelectorView),
      multi: true,
    },
  ],
})
export class StylesSelectorView {
  @Input()
  title?: string;
  @Input()
  data: (ImageGenerationBasicStyle | ImageGenerationAdvancedStyle)[] = [];
  @Input()
  isModalAllDataVisible = false;
  @Input()
  page = 1;
  @Input()
  pageSize = 4;
  @Input()
  textViewAllData = "";
  @Input()
  showSectionHeader = true;
  @Input()
  canCollapsing = false;
  @Input()
  isCollapsed = true;
  @Input()
  hasNewMark = false;

  @ViewChildren("card", { read: ElementRef }) elements?: QueryList<ElementRef>;
  @ViewChildren("modalCard", { read: ElementRef }) modalElements?: QueryList<ElementRef>;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  protected value?: number;
  protected modalSelectedStyleId?: number;

  protected pagesCount = 1;

  protected selectedTab = "all";
  protected selectedTabIndex = 0;

  protected isDisabled = false;

  protected filteredData: (ImageGenerationBasicStyle | ImageGenerationAdvancedStyle)[] = [];
  protected tabs: TabValue[] = [{ value: "all", title: $localize`:@@image-generation.select-style.group.all:` }];
  protected dataPaginated: (ImageGenerationBasicStyle | ImageGenerationAdvancedStyle)[] = [];

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.updatePagesCount();
    this.getPaginatedData();
    this.updateTypesAndStyles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updatePagesCount();
    this.getPaginatedData();
    this.updateTypesAndStyles();
  }

  updateTypesAndStyles() {
    this.prepareTypes();
    this.filterStyles();
  }

  writeValue(value: number): void {
    this.value = value;
    this.modalSelectedStyleId = value;
    this.goToSelectedCardPage(this.value);
    this.cdr.detectChanges();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  filterStyles() {
    this.filteredData = this.data.filter((s) => {
      if (this.selectedTab === "all") {
        return s;
      }
      if (this.selectedTab === s.type) {
        return s;
      }
      return;
    });
  }

  prepareTypes() {
    for (const style of this.data) {
      if (!style.type) {
        continue;
      }

      const title = this.getTypeTitle(style.type);
      const tab = this.tabs.find((t) => t.value === style.type);
      if (!tab) {
        this.tabs.push({
          value: style.type || "",
          title,
        });
      }
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onSelect(value: ImageGenerationBasicStyle | ImageGenerationAdvancedStyle) {
    this.value = value.id;
    this.modalSelectedStyleId = value.id;
    this.scrollToCard(this.elements, value.id);
    this.onChange(this.value);
  }

  isActive(style: ImageGenerationBasicStyle | ImageGenerationAdvancedStyle) {
    return this.value === style.id;
  }

  isModalActive(value: ImageGenerationBasicStyle | ImageGenerationAdvancedStyle) {
    return this.modalSelectedStyleId === value.id;
  }

  getStyleTitle(styleId: number | undefined) {
    const style = this.data.find((s) => s.id === styleId);
    return style?.title;
  }

  goToTab(value: string) {
    this.selectedTab = value;
    this.selectedTabIndex = this.tabs.findIndex((t) => t.value === value);
    this.filterStyles();

    this.scrollModalToCardOrBegin();
  }

  scrollModalToCardOrBegin() {
    let selectedId = this.modalSelectedStyleId;
    const card = this.filteredData.find((v) => v.id === selectedId);
    if (!card) {
      selectedId = this.filteredData[0].id;
    }
    this.scrollToCard(this.modalElements, selectedId, "center");
  }

  onModalSelect(value: ImageGenerationBasicStyle | ImageGenerationAdvancedStyle) {
    this.modalSelectedStyleId = value.id;
    this.scrollToCard(this.modalElements, value.id, "center");
  }

  onModalApply() {
    this.value = this.modalSelectedStyleId;
    this.selectedTab = "all";
    this.goToSelectedCardPage(this.value);
    this.onChange(this.value);
    this.closeModal();
  }

  private goToSelectedCardPage(selectedId: number | undefined) {
    const cardIndex = this.data.findIndex((d) => d.id === selectedId);
    if (cardIndex < 0) {
      return;
    }
    this.page = Math.ceil((cardIndex + 1) / this.pageSize);
    this.getPaginatedData();
  }

  private updatePagesCount() {
    this.pagesCount = Math.ceil(this.data.length / this.pageSize);
  }

  protected hasPreviousPage() {
    return this.page - 1 > 0;
  }

  protected hasNextPage() {
    return this.page + 1 <= this.pagesCount;
  }

  protected goToPreviousPage() {
    if (!this.hasPreviousPage()) {
      return;
    }
    this.page--;
    this.getPaginatedData();
  }

  protected goToNextPage() {
    if (!this.hasNextPage()) {
      return;
    }
    this.page++;
    this.getPaginatedData();
  }

  private getPaginatedData() {
    const fromIndex = (this.page - 1) * this.pageSize;
    const toIndex = fromIndex + this.pageSize;
    this.dataPaginated = this.data.slice(fromIndex, toIndex);
  }

  private scrollToCard(
    elements: QueryList<ElementRef<any>> | undefined,
    selectedId: number | undefined,
    position: "start" | "end" | "center" = "end",
  ) {
    elements?.forEach((element, i) => {
      const elemId = Number.parseInt(element.nativeElement.getAttribute("id"));
      if (elemId === selectedId) {
        element.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: position,
        });
      }
    });
  }

  showModal() {
    this.selectedTab = "all";
    this.filterStyles();
    this.isModalAllDataVisible = true;
    this.cdr.detectChanges();
    this.scrollToCard(this.modalElements, this.value, "center");
  }

  closeModal() {
    this.selectedTab = "all";
    this.selectedTabIndex = 0;

    this.isModalAllDataVisible = false;
    this.cdr.detectChanges();
    this.scrollToCard(this.modalElements, this.value, "center");
  }

  getTypeTitle(value: string | undefined) {
    switch (value) {
      case "graphics":
        return $localize`:@@image-generation.select-style.group.graphics:`;
      case "painting":
        return $localize`:@@image-generation.select-style.group.painting:`;
      case "digital":
        return $localize`:@@image-generation.select-style.group.digital:`;
      case "photo":
        return $localize`:@@image-generation.select-style.group.photo:`;
      case "children-drawing":
        return $localize`:@@image-generation.select-style.group.children-drawing:`;
      case "fantastic":
        return $localize`:@@image-generation.select-style.group.fantastic:`;
      case "comics":
        return $localize`:@@image-generation.select-style.group.comics:`;
      default:
        return "";
    }
  }

  toggle() {
    if (!this.canCollapsing) {
      return;
    }
    this.isCollapsed = !this.isCollapsed;
  }

  getEmptyCard() {
    return [].constructor(this.pageSize - this.dataPaginated.length);
  }
}
