<div
  *ngIf="title || actionTitle || actionIcon"
  class="header"
  [class.can-collapsing]="canCollapsing"
  (click)="toggle()"
>
  <div class="title-group">
    <span class="title">{{ title }}</span>

    <div *ngIf="actionTitle || actionIcon" class="action" (click)="onActionClick.emit()">
      <span *ngIf="actionTitle" class="action-title">{{ actionTitle }}</span>
      <m-ng-icon *ngIf="actionIcon" [src]="actionIcon" [size]="14" />
    </div>

    <div *ngIf="infoText" class="action" (click)="onActionClick.emit()">
      <span *ngIf="actionTitle" class="action-title">{{ actionTitle }}</span>
    </div>

    <span *ngIf="infoText" class="tooltip-host">
      <m-ng-icon src="info.svg" [size]="16" />
      <m-localized-text
        class="tooltip"
        text="image-generation.advanced-generation-mode.info"
        i18n-text="@@image-generation.advanced-generation-mode.info"
      />
    </span>

    <span *ngIf="canCollapsing" class="icon" [class.collapsed]="isCollapsed"></span>
    <m-ng-icon *ngIf="canCollapsing" src="dropdown-arrow-down.svg" [size]="16" [class.collapsed]="isCollapsed" />
  </div>
</div>
<div class="content" *ngIf="isCollapsed" @slideInOutVertical>
  <ng-content></ng-content>
</div>
