<m-ng-island paddingSize="l">
<m-localized-text text="admin.cloudpayments.description" i18n-text="@@admin.cloudpayments.description"/>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div>
    <span i18n="@@admin.cloudpayments.public-id">admin.cloudpayments.public-id</span>
    <m-ng-input  formControlName="publicId" />
  </div>
  <div>
    <span i18n="@@admin.cloudpayments.private-key">admin.cloudpayments.private-key</span>
    <m-ng-input  formControlName="privateKey" />
  </div>
</form>

<div class="row">
  <m-button button-style="primary" (onClick)="onSubmit()" [is-disabled]="!form.valid" i18n="@@admin.common.save">
    admin.common.save
  </m-button>

  <m-button button-style="secondary" (onClick)="onDelete()"  i18n="@@admin.common.delete">
    admin.common.delete
  </m-button>

</div>
</m-ng-island>
