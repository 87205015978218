import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationPayloadImageModeration } from "../../../models/notification";
import { RouterService } from "../../../services/router.service";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-image-moderation-card-view",
  templateUrl: "account-notification-image-moderation-card.view.html",
  styleUrls: ["account-notification-image-moderation-card.view.scss"],
})
export class AccountNotificationImageModerationCardView extends AccountNotificationBaseCardView<NotificationPayloadImageModeration> {
  constructor(
    private readonly routerSerivce: RouterService,
    private readonly router: Router,
  ) {
    super();
  }

  openImageOnHomePage() {
    this.router.navigate(["images", this.payload.imageId]);
  }

  openImageGeneration() {
    this.router.navigate(["image-generation"]);
    setTimeout(() => {
      this.routerSerivce.showInModal([this.payload.generationId, this.payload.imageId]);
    }, 100);
  }
}
