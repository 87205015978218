import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { CompanyCloudPaymentsSettings, CompanyS3Settings } from "../../models/company";
import { CompanyUnisenderCreds } from "../../models/unisender-settings";

export type State = {
  s3Settings: CompanyS3Settings | undefined;
  cloudPaymentsSettings: CompanyCloudPaymentsSettings | undefined;
  unisenderCreds: CompanyUnisenderCreds | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyCredsSettingsStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      s3Settings: undefined,
      cloudPaymentsSettings: undefined,
      unisenderCreds: undefined,
    };
  }

  getS3SettingsObservable() {
    return this.getFieldObservable("s3Settings");
  }

  setS3Settings(settings: CompanyS3Settings | undefined) {
    this.update((state) => ({ ...state, s3Settings: settings }));
  }

  getCloudPaymentsSettingsObservable() {
    return this.getFieldObservable("cloudPaymentsSettings");
  }

  setCloudPaymentsSettings(settings: CompanyCloudPaymentsSettings | undefined) {
    this.update((state) => ({ ...state, cloudPaymentsSettings: settings }));
  }

  getUnisenderCredsObservable() {
    return this.getFieldObservable("unisenderCreds");
  }

  setUnisenderCreds(settings: CompanyUnisenderCreds | undefined) {
    this.update((state) => ({ ...state, unisenderCreds: settings }));
  }
}
