import { ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Book, BookSize, Template } from "@metranpage/book-data";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { FileDropComponent, LoadingService, RouterService, filterUndefined } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { FormatService, FormatStore } from "@metranpage/format-data";
import { UserStore } from "@metranpage/user-data";
import { NgxFileDropEntry } from "ngx-file-drop";
import { Subscription, filter, first, switchMap } from "rxjs";
import { BookModalEventBus } from "../../services/book-modal-event-bus.service";
import { BookRouterService } from "../../services/book-router.service";
import { BookService } from "../../services/book.service";
import { BooksStore } from "../../services/books.store";

@Component({
  selector: "m-modal-book-file-upload",
  templateUrl: "modal-book-file-upload.view.html",
  styleUrls: ["modal-book-file-upload.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class ModalBookFileUploadView {
  @Input()
  runInEditMode = false;

  protected bookFormatName?: string;
  protected templateId = 0;
  protected template?: Template;
  protected book!: Book;
  protected bookSize!: BookSize;

  protected isFileLimitModalVisible = false;
  protected hasTablesInMultiColumnTemplateModalVisible = false;

  protected contentHeader = "";

  protected fileSizeLimit = 1;

  sub: Subscription = new Subscription();

  protected hints = [
    {
      title: $localize`:@@books.upload.hint1.title:`,
      description: $localize`:@@books.upload.hint1.description:`,
      image: $localize`:@@books.upload.hint1.image:`,
    },
    {
      title: $localize`:@@books.upload.hint2.title:`,
      description: $localize`:@@books.upload.hint2.description:`,
      image: $localize`:@@books.upload.hint2.image:`,
    },
    {
      title: $localize`:@@books.upload.hint3.title:`,
      description: $localize`:@@books.upload.hint3.description:`,
      image: $localize`:@@books.upload.hint3.image:`,
    },
  ];

  @ViewChild("filedrop", { static: true })
  protected fileDropComponent!: FileDropComponent;

  constructor(
    private readonly bookService: BookService,
    private readonly bookRouterService: BookRouterService,
    private readonly bookModalEventBus: BookModalEventBus,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly routerService: RouterService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly formatService: FormatService,
    private readonly cdr: ChangeDetectorRef,
    private readonly booksStore: BooksStore,
    userStore: UserStore,
    private readonly formatStore: FormatStore,
  ) {
    this.sub.add(
      userStore
        .getActiveSubscriptionObservable()
        .pipe(filterUndefined())
        .subscribe((sub) => {
          this.fileSizeLimit = sub.tariff.fileLimitMb;
        }),
    );
  }

  async ngAfterViewInit() {
    this.sub.add(
      this.formatStore
        .getFormatsObservable()
        .pipe(
          filterUndefined(),
          filter((formats) => formats.length > 0),
          first(),
          switchMap(() => this.booksStore.getModalBookObservable()),
          filterUndefined(),
        )
        .subscribe(async (book) => {
          await this.init(book);
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async init(book: Book) {
    this.book = book;

    this.templateId = Number(this.routerService.getRouteParam(this.route.snapshot, "templateId"));
    this.bookSize = {
      width: Number(this.routerService.getRouteParam(this.route.snapshot, "width")),
      height: Number(this.routerService.getRouteParam(this.route.snapshot, "height")),
    };
    this.bookFormatName = this.formatService.getFormatNameBySize(this.bookSize.width, this.bookSize.height);

    if (this.templateId !== 0) {
      this.loadingService.startLoading({ fullPage: true });
      const allTemplates = await this.bookService.getTemplates();
      this.template = allTemplates.find((template) => template.id === this.templateId);
      this.loadingService.stopLoading();
    }

    this.updateHeader();
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile && droppedFile.fileEntry.name.toLowerCase().endsWith(".docx")) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {
          this.notificationService.closeAll();

          this.loadingService.startLoading({ fullPage: true, description: $localize`:@@books.upload.process-hint:` });
          const result = await this.bookService.uploadOriginalFile(this.book, file);
          this.loadingService.stopLoading();
          if (result === "success") {
            this.showModalOrGoNextPage();
          } else if (result === "file-limit-error") {
            this.isFileLimitModalVisible = true;
            this.cdr.markForCheck();
          } else if (result === "tables-in-multi-column-template-error") {
            this.hasTablesInMultiColumnTemplateModalVisible = true;
            this.cdr.markForCheck();
            // this.notificationService.error($localize`:@@books.upload.cant-read-file-error:`);
          } else if (result === "error") {
            this.notificationService.error($localize`:@@books.upload.cant-read-file-error:`);
          }
        });
      }
    }
  }

  openFileSelector() {
    this.fileDropComponent.openFileSelector();
  }

  protected async onSampleDocumentClick() {
    const book = this.book;
    this.notificationService.closeAll();

    this.loadingService.startLoading({ fullPage: true, description: $localize`:@@books.upload.process-hint:` });
    const result = await this.bookService.uploadSampleFile(book);
    this.loadingService.stopLoading();

    if (result === "success") {
      this.showModalOrGoNextPage();
    } else if (result === "error") {
      this.notificationService.error($localize`:@@books.upload.cant-read-file-error:`);
    }
  }

  protected async onEmptyDocumentClick() {
    const book = this.book;
    this.notificationService.closeAll();

    this.loadingService.startLoading({ fullPage: true, description: $localize`:@@books.upload.process-hint:` });
    const result = await this.bookService.uploadEmptyFile(book);
    this.loadingService.stopLoading();

    if (result === "success") {
      this.showModalOrGoNextPage();
    } else if (result === "error") {
      this.notificationService.error($localize`:@@books.upload.cant-read-file-error:`);
    }
  }

  showModalOrGoNextPage() {
    if (this.runInEditMode) {
      this.bookModalEventBus.onUploadFile();
      return;
    }

    const book = this.book;
    if (!book.title || !book.authorLastName) {
      this.bookRouterService.showModal(book, "book-data", "new");
      return;
    }

    this.router.navigateByUrl(`/books/${book.id}/markup`);
  }

  protected async onBackClick() {
    const book = this.book;
    this.bookRouterService.showModal(book, "templates", "new");
  }

  protected onCloseFileLimitModalClick() {
    this.isFileLimitModalVisible = false;
  }

  protected onActionClickModal() {
    this.router.navigate(["payments", "subscription"]);
  }

  protected getFileLimitModalText() {
    const fileLimit = this.fileSizeLimit;
    return $localize`:@@books.info-modal.file-size-limit.text:${fileLimit}:fileLimit:`;
  }

  updateHeader() {
    let format = $localize`:@@home.new-project-modal.sidebar-section.item.book.format-custom:`;
    if (this.bookFormatName && this.bookFormatName !== "custom") {
      format = `${$localize`:@@home.new-project-modal.content.header.format:`} ${this.bookFormatName.toUpperCase()}`;
    }

    let templateTitle = "";
    if (this.template) {
      templateTitle = ` / ${this.template.title}`;
      const symbolsLimit = 12;
      if (templateTitle.length > symbolsLimit) {
        templateTitle = `${templateTitle.slice(0, symbolsLimit)}...`;
      }
    }

    this.contentHeader = `${format}${templateTitle} / ${$localize`:@@books.modal.header.upload-step:`}`;
    if (this.runInEditMode) {
      this.contentHeader = `${$localize`:@@books.modal.header.upload-step:`}`;
    }

    this.cdr.detectChanges();
  }

  protected onCloseTablesInMultiColumnTemplateModalClick() {
    this.hasTablesInMultiColumnTemplateModalVisible = false;
  }
}
