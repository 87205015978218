import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { Company } from "../models/company";

@Injectable({
  providedIn: "root",
})
export class CompanyApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getCompany() {
    return this.get<Company>("company");
  }
}
