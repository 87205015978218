import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, Output } from "@angular/core";
import { Book } from "@metranpage/book-data";
import { IS_COVER_EDITOR_ENABLED } from "@metranpage/core";

@Component({
  selector: "m-last-project-edit-menu",
  template: `
    <m-ng-island *ngIf="lastProject" class="edit-menu" paddingSize="s">
      <m-last-project-card-edit
        *ngIf="isCoverEditorEnabled"
        i18n-text="@@books.book.edit-cover"
        text="books.book.edit-cover"
        step="cover"
        [book]="lastProject"
      />
      <m-last-project-card-edit
        i18n-text="@@books.book.edit-book-data"
        text="books.book.edit-book-data"
        step="book-data"
        [book]="lastProject"
      />
      <m-last-project-card-edit
        *ngIf="lastProject.bookResults!.isOriginalFileUploaded"
        i18n-text="@@books.book.edit-book"
        text="books.book.edit-book"
        [book]="lastProject"
      />
      <m-last-project-card-edit
        *ngIf="!lastProject.bookResults!.isOriginalFileUploaded"
        i18n-text="@@books.book.upload-book"
        text="books.book.upload-book"
        [book]="lastProject"
      />
    </m-ng-island>
  `,
  styles: [
    `
      .edit-menu {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 20%;
        display: flex;
        width: max-content;
        min-width: 280px;
        flex-direction: column;
        border: 1px solid var(--text-text-8);
        border-radius: var(--island-radius-16);
        background: var(--surface-on-island);
        cursor: default;
        gap: 4px;
      }
    `,
  ],
})
export class LastProjectEditMenuView {
  @Input()
  lastProject?: Book;
  @Output()
  close = new EventEmitter<void>();

  constructor(
    @Inject(IS_COVER_EDITOR_ENABLED) protected readonly isCoverEditorEnabled: boolean,
    private elementRef: ElementRef) {}

  @HostListener("document:click", ["$event.target"])
  public onDocumentClick(targetElement: HTMLElement): void {
    if (!this.elementRef.nativeElement.contains(targetElement)) {
      this.close.emit();
    }
  }
}
