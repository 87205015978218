import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { UpdateUserActionDto, UserAction } from "./models/user-action.models";
import { UpdateUserCardDto, UpdateUserDto, UpdateUserEmailDto, User } from "./models/user.models";

@Injectable({
  providedIn: "root",
})
export class UserApiService extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async sendVerificationEmail(userLocation: string | undefined) {
    return this.post<void>("verify-email-send", { userLocation });
  }

  async verifyEmail(token: string) {
    return this.post<User>("verify-email-check", { token });
  }

  async getUser() {
    return this.get<User>("user");
  }

  async updateUser(user: UpdateUserDto) {
    return this.put<User>("user", { user });
  }

  async updateUserEmail(user: UpdateUserEmailDto) {
    return this.put<User>("user/profile-email", { user });
  }

  async updateUserCard(user: UpdateUserCardDto) {
    return this.put<User>("user/profile-card", { user });
  }

  async deleteUser() {
    return this.delete<User>("user");
  }

  async uploadUserProfilePicture(userId: number, file: File) {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post("user/profile-picture", formData, {
      headers,
    });
  }

  getUserActions() {
    return this.get<UserAction[]>("user-actions");
  }

  async updateUserAction(action: UpdateUserActionDto) {
    return this.post<UserAction>("user-action", { action });
  }
}
