import { Component, Input } from "@angular/core";
import { ButtonColorTheme } from "@metranpage/components";

@Component({
  selector: "m-book-card-action",
  template: `
    <m-button
      button-style="tertiary"
      [icon]="icon"
      [is-disabled]="isDisabled"
      icon-position="left"
      [color-theme]="colorTheme"
      text-align="left"
      size="fill"
    >
      {{ text }}
    </m-button>
    <ng-content></ng-content>
  `,
  styles: [
    `
      @import '../../../../../../apps/metranpage/src/styles/mixins';

      :host {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      m-button {
        padding: 0 24px;
      }
    `,
  ],
})
export class BookCardActionView {
  @Input()
  icon!: string;
  @Input()
  text!: string;
  @Input()
  isDelete = false;
  @Input()
  isDisabled = false;

  get colorTheme(): ButtonColorTheme {
    if (this.isDelete) {
      return "danger";
    }
    return "primary";
  }
}
