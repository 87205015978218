import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@metranpage/core";
import { AccountTariffAndPaymentsMenuItemComponent } from "./components/account-tariff-and-payments-menu-item/account-tariff-and-payments-menu-item.component";
import { AccountMenuItemComponent } from "./components/menu-item/menu-item.component";
import { AccountActiveTariffPage } from "./pages/account-active-tariff/account-active-tariff.page";
import { AccountBankCardsPage } from "./pages/account-bank-cards/account-bank-cards.page";
import { AccountContactsPage } from "./pages/account-contacts/account-contacts.page";
import { AccountHostPage } from "./pages/account-host.page";
import { AccountMainPage } from "./pages/account-main/account-main.page";
import { AccountNotificationsPage } from "./pages/account-notifications/account-notifications.page";
import { AccountPasswordPage } from "./pages/account-password/account-password.page";
import { AccountPaymentsHistoryPage } from "./pages/account-payments-history/account-payments-history.page";
import { AccountTariffAndPaymentsHostPage } from "./pages/account-tariff-and-payments-host/account-tariff-and-payments-host.page";
import { AccountVerificationCheckTokenPage } from "./pages/account-verification-check-token/account-verification-check-token.page";
import { AccountVerificationPage } from "./pages/account-verification/account-verification.page";
import { UserService } from "./services/user.service";
import { AccountBankCardView } from "./views/account-bank-card/account-bank-card.view";
import { AccountContactsView } from "./views/account-contacts/account-contacts.view";
import { AccountMainView } from "./views/account-main/account-main.view";
import { AccountPasswordView } from "./views/account-password/account-password.view";
import { AccountTariffAndPaymentsNavView } from "./views/account-tariff-and-payments-nav/account-tariff-and-payments-nav.view";
import { AccountVerificationView } from "./views/account-verification/account-verification.view";
import { AccountNavView } from "./views/nav/nav.view";
import { AccountDevView } from "./views/account-dev/account-dev.view";
import { AccountDevPage } from "./pages/account-dev/account-dev.page";

@NgModule({
  declarations: [
    //components
    AccountMenuItemComponent,
    //views
    AccountMainView,
    AccountPasswordView,
    AccountTariffAndPaymentsNavView,
    AccountTariffAndPaymentsMenuItemComponent,
    AccountContactsView,
    AccountVerificationView,
    AccountNavView,
    AccountBankCardView,
    AccountDevView,

    //pages
    AccountHostPage,
    AccountMainPage,
    AccountPasswordPage,
    AccountTariffAndPaymentsHostPage,
    AccountVerificationPage,
    AccountVerificationCheckTokenPage,
    AccountContactsPage,
    AccountActiveTariffPage,
    AccountBankCardsPage,
    AccountPaymentsHistoryPage,
    AccountNotificationsPage,
    AccountDevPage,

    // services
  ],
  imports: [CoreModule, RouterModule],

  exports: [],
  providers: [{ provide: "UserRefresher", useClass: UserService }],
})
export class UserModule {}
