<m-cover-conceptual-assistant-generation-menu
  *ngIf="!coverConceptualGeneration || coverConceptualGeneration.status === 'error'"
  [cover]="cover"
  (generateCoverConceptual)="generateCoverConceptualClick($event)"
  (closeConceptualAssistant)="closeConceptualAssistantClick()"
/>

<m-cover-conceptual-assistant-generation-result
  *ngIf="coverConceptualGeneration && coverConceptualGeneration.status !== 'error'"
  [cover]="cover"
  [coverConceptualGeneration]="coverConceptualGeneration"
  [stylesBasic]="stylesBasic"
  [stylesAdvanced]="stylesAdvanced"
  [fonts]="fonts"
  (generateImage)="onGenerateImageClick($event)"
  (applyFontsAndColors)="onApplyFontsAndColorsClick($event)"
  (back)="onBackClick()"
  (closeConceptualAssistant)="closeConceptualAssistantClick()"
/>
