import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private gtag: any;

  constructor() {
    this.gtag = (window as any).gtag;
  }

  event(eventName: string) {
    if (this.gtag) {
      this.gtag('event', eventName, {});
    }
  }
}
