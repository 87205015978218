import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { PromocodeCreateData } from "../../models/promocode";
import { Tariff } from "../../models/tariff";
import { AdminPromocodesService } from "../../services/promocodes/promocodes.service";
import { AdminTariffsService } from "../../services/tariffs/tariffs.service";
import { SelectValue } from "@metranpage/components";

type PromocodeType = "tokens" | "subscription" | "discount";

@Component({
  selector: "m-admin-add-promocode-modal",
  templateUrl: "./add-promocode.view.html",
  styleUrls: ["./add-promocode.view.scss"],
})
export class AddPromocodeView {
  @Input()
  tariffs: Tariff[] = [];

  @Output()
  onPromocodeAdded = new EventEmitter<PromocodeCreateData>();
  @Output()
  onCancelAddition = new EventEmitter<void>();

  protected promocodeTypes;
  protected promocodeType: PromocodeType = "tokens";
  protected form?: FormGroup;

  private sub: Subscription = new Subscription();

  constructor(
    private readonly adminTariffsService: AdminTariffsService,
    private readonly adminPromocodesService: AdminPromocodesService,
  ) {
    this.promocodeTypes = this.getPromocodeTypes();
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  createForm() {
    this.form = this.adminPromocodesService.createPromocodeForm();
  }

  createPromocode() {
    const formData = this.form?.getRawValue();

    if (formData.createdDate) {
      formData.createdDate = new Date(formData.createdDate);
    } else {
      formData.createdDate = undefined;
    }
    if (formData.expireDate) {
      formData.expireDate = new Date(formData.expireDate);
    } else {
      formData.expireDate = undefined;
    }
    if (!formData.tariffId) {
      formData.tariffId = undefined;
    }

    formData.creditsCount = +formData.creditsCount;
    formData.goldCreditsCount = +formData.goldCreditsCount;
    formData.tariffId = +formData.tariffId;
    formData.subscriptionPeriod = +formData.subscriptionPeriod;
    formData.discontForSubscription = +formData.discontForSubscription;

    this.onPromocodeAdded.emit(formData);
  }

  onCancel() {
    this.onCancelAddition.emit();
  }

  protected getOptionsForTariffsSelect() {
    return this.adminTariffsService.getOptionsForTariffsSelect(this.tariffs);
  }

  protected onSelectPromocodeType(value: number | string) {
    this.promocodeType = value as PromocodeType;
  }

  protected getPromocodeTypes(): SelectValue[] {
    return [
      {
        id: "tokens",
        value: $localize`:@@admin.promocodes.promocode-type.tokens:`,
      },
      {
        id: "subscription",
        value: $localize`:@@admin.promocodes.promocode-type.subscription:`,
      },
      {
        id: "discount",
        value: $localize`:@@admin.promocodes.promocode-type.discount:`,
      },
    ];
  }
}
