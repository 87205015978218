import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, RoutesRecognized } from "@angular/router";
import { AdvertisementActionService, AuthService } from "@metranpage/auth";
import { CompanyService } from "@metranpage/company";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { LoadingService, LoadingState, RePlainService } from "@metranpage/core";
import { FormatService } from "@metranpage/format-data";
import { AVAILABLE_LANGUAGES, I18nService, LangSpec } from "@metranpage/i18n";
import { PricingService } from "@metranpage/pricing";
import { PromocodeEnterResult } from "@metranpage/pricing-data";
import { ThemeService } from "@metranpage/theme";
import { UserService } from "@metranpage/user";
import { User, UserApiService, UserBalance, UserStore } from "@metranpage/user-data";
import { Observable, Subject, filter, map, switchMap, timer } from "rxjs";
import { UserActionMessage, WelcomeModalService } from "./views/welcome-modal/welcome-modal.service";

@Component({
  selector: "m-root",
  templateUrl: "./app.page.html",
  styleUrls: ["./app.page.scss"],
  animations: [fadeInOutOnEnterLeave],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppPage implements OnInit {
  protected user?: User;
  protected userBalance?: UserBalance;
  protected shouldShowTopBar = false;

  protected isLoading = false;
  protected loadingDescription: string | undefined = "";

  protected promocodeEvent = new Subject<PromocodeEnterResult | undefined>();

  userActionMessage$!: Observable<UserActionMessage>;
  userActionMessageRead = false;

  constructor(
    private readonly api: UserApiService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly themeService: ThemeService,
    private readonly i18nService: I18nService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly rePlainService: RePlainService,
    private readonly pricingService: PricingService,
    private readonly advertisementActionService: AdvertisementActionService,
    @Inject(AVAILABLE_LANGUAGES) private readonly availableLanguages: LangSpec[],
    private readonly cdr: ChangeDetectorRef,
    readonly welcomeModalService: WelcomeModalService,
    readonly userStore: UserStore,
    readonly titleService: Title,
    readonly companyService: CompanyService,
    readonly formatService: FormatService,
  ) {
    titleService.setTitle($localize`:@@app-name:`);
    userService.refreshUser();

    userStore.getUserObservable().subscribe((user) => {
      if (!user) {
        return;
      }
      this.user = user;
    });
    userStore.getBalanceObservable().subscribe((balance) => {
      this.userBalance = balance;
    });

    this.promocodeEvent = this.pricingService.promocodeEvent$;

    companyService.refreshCompany();

    formatService.loadFormats();

    this.userActionMessage$ = welcomeModalService.userActionMessages$.pipe(
      filter((m) => m.length > 0),
      map((m) => m[0]),
    );
  }

  ngOnInit(): void {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        if (!this.user) {
          this.shouldShowTopBar = false;
          return;
        }

        const routeData = data.state.root.firstChild?.data;
        if (routeData && "showTopBar" in routeData) {
          this.shouldShowTopBar = routeData.showTopBar;
        } else {
          this.shouldShowTopBar = true;
        }
      }
    });

    this.rePlainService.replainStart();
    this.cdr.markForCheck();
  }

  async ngAfterContentInit() {
    this.loadingService.fullPageLoadingState$.pipe().subscribe((state) => {
      this.isLoading = state.isActive;
      this.loadingDescription = state.description;
      this.cdr.detectChanges();
    });
  }

  async onThemeToggle() {
    this.themeService.toggleTheme();

    const isDarkThemeEneble = this.themeService.getTheme() === "dark";

    await this.api.updateUser({
      darkTheme: isDarkThemeEneble,
    });
    this.cdr.markForCheck();
  }

  async onLanguageChange(lang: string) {
    await this.api.updateUser({
      language: lang,
    });

    this.i18nService.saveLocale(lang);
    window.location.reload();
  }

  onLogoutClick() {
    this.authService.logout();
    this.router.navigate(["/login"]);

    timer(250).subscribe(() => {
      window.location.reload();
    });
  }

  async onModalActionClick(message: UserActionMessage, isActionClicked: boolean) {
    await this.userService.updateUserAction({ name: message.name, status: "complete" });
    this.userActionMessageRead = true;
    if (isActionClicked && message.navigateTo) {
      this.router.navigate([message.navigateTo]);
    }
  }

  protected isLanguageSwitchAvailable() {
    return this.availableLanguages.length > 1;
  }

  protected async onPromocodeEnter(promocode: string) {
    const result = await this.pricingService.activatePromocode(promocode);
    this.promocodeEvent.next(result);
  }
}
